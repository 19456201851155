<template>
  <div>
    <!-- #region NAVBAR -->
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>
        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">
              Flight Board
            </div>
          </a>
        </li>
        <li>
          <a href="#">
            <div
              v-if="boardStyle === 'Board' && pages.length"
              class="flex justify-between fnt-sm"
            >
              <button class="btn-icon" @click="handlePageUp">
                <i class="fa-solid fa-chevrons-up text-white"></i>
              </button>
              <div class="text-white">
                {{ `${pageNumber + 1} of ${pages.length}` }}
              </div>
              <button class="btn-icon" @click="handlePageDown">
                <i class="fa-solid fa-chevrons-down text-white"></i>
              </button>
            </div>
          </a>
        </li>

        <li
          v-if="boardStyle !== 'List'"
          class="icon"
          @click="handleCaptureFlightBoard"
        >
          <a href="#">
            <span class="tooltip">Print Board</span>
            <span><i class="fa-solid fa-print text-white"></i></span>
          </a>
        </li>

        <li @click="zoomIn" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom In</span>
            <span><i class="fa-regular fa-magnifying-glass-plus"></i></span>
          </a>
        </li>
        <li @click="zoomOut" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom Out</span>
            <span><i class="fa-regular fa-magnifying-glass-minus"></i></span>
          </a>
        </li>
        <li @click="getData" class="nav-secondar icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <!-- <li @click="handleNavigateToPlanning" class="nav-secondar icon">
          <a href="#">
            <span class="tooltip">Planning</span>
            <span>
              <i class="fa-solid fa-calendar text-red-500"></i>
            </span>
          </a>
        </li> -->
      </ul>
    </nav>
    <!-- #endregion -->

    <!-- #region WARNINGS -->

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- #region LOADING -->

    <r-spinner v-if="loading"> </r-spinner>
    <!-- #endregion -->

    <!-- #region DIALOGS -->

    <!-- DATE FILTER DIALOG -->
    <r-modal v-if="showDateFilterDialog" @close="showDateFilterDialog = false">
      <div v-if="showDateFilterDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Date Filter</div>
          <button @click="showDateFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex">
          <div>
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Start Date</label
            >

            <input
              class="block w-32 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="startDate"
              type="date"
              id="date-filter"
            />
          </div>

          <div class="ml-3">
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Number of Days</label
            >

            <select
              class="block w-24 py-1 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="numberOfDays"
            >
              <option
                v-for="option in numberOfDaysOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleSaveDateFilter"
            class="btn bg-blue-500 text-white"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </r-modal>

    <!-- AIRPORT INFORMATION DIALOG -->
    <r-modal
      v-if="showAirportInformationDialog"
      @close="handleHideAirportInformationDialog"
    >
      <div
        v-if="airport"
        class="border-l-8 border-2 border-blue-500 text-base p-3"
      >
        <div class="flex justify-between mb-2 ml-2">
          <div class="text-3xl">
            {{ `${airport.iata} - ${airport.icao}` }}
          </div>
          <button @click="handleHideAirportInformationDialog" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div>Airport Name: {{ `  ${airport.name}` }}</div>
        <div>Country: {{ ` ${airport.country}` }}</div>
      </div>
    </r-modal>

    <!-- AIRCRAFT INFORMATION DIALOG -->
    <r-modal v-if="showAircraftDialog">
      <div
        v-if="myAircraft"
        class="border-l-8 border-2 border-blue-500 text-base p-3"
      >
        <div class="flex justify-between mb-5 ml-2">
          <div class="text-3xl">
            {{ ` ${myAircraft.registration} - ${myAircraft.model}` }}
          </div>
          <button
            @click="[(showAircraftDialog = false), (myAircraft = null)]"
            class="btn-icon"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="py-2">
          <div class="text-lg font-bold">Cabin Configuration</div>
          <div v-for="(item, index) in myAircraft.cabinConfig" :key="index">
            {{ `Class "${item.class}": ${item.seats} Seat(s)` }}
          </div>
        </div>

        <div v-if="canUpdateAircraft" class="flex justify-end mt-3">
          <button
            @click="handleEditAircraft(myAircraft)"
            class="btn bg-blue-500 text-white ml-1"
          >
            Edit Aircraft
          </button>
        </div>
      </div>
    </r-modal>

    <!-- #endregion -->

    <!-- #region AIRCRAFT EVENT DETAIL -->
    <r-modal
      v-if="showAircraftEventDialog"
      @close="showAircraftEventDialog = false"
    >
      <div
        v-if="aircraftEvent"
        class="pl-3 pt-3 pr-3 pb-1 bg-green-100 text-sm"
        :class="{
          'aircraft-operational': aircraftEvent.type !== 'Flight',
        }"
      >
        <div class="flex justify-between text-lg lg:text-xl cursor-pointer">
          <div @click="handleNavigateToFlightView">
            <span>
              {{ `${aircraftEvent.aircraft.registration} | ` }}
            </span>
            <span v-if="aircraftEvent.type === 'Flight'">{{
              `${aircraftEvent.flightNumber} | `
            }}</span>
            <span v-else>{{ `${aircraftEvent.type} | ` }}</span>
            <span>{{ `${aircraftEvent.customer.name}` }}</span>
            <span v-if="aircraftEvent.type === 'Flight'">
              {{ ` | ${aircraftEvent.flightRules}` }}
            </span>

            <span
              v-if="aircraftEvent.type === 'Flight'"
              class="ml-2 text-red-500"
            >
              {{ `${aircraftEvent.hidden ? "(Hidden)" : ""}` }}
            </span>
          </div>

          <button @click="showAircraftEventDialog = false" class="btn-icon">
            <i class="fa-solid fa-close"></i>
          </button>
        </div>

        <!-- ORIGIN DESTINATION -->

        <div class="flex justify-between border-b border-gray-500 pb-2 mb-2">
          <div @click="handleGetPreviousAircraftEvent">
            <i class="fa-solid fa-left"></i>
          </div>

          <!-- Origin -->
          <div class="mx-auto">
            <div
              @click="handleShowAirportInformationDialog(aircraftEvent.origin)"
              class="text-center text-3xl font-bold cursor-pointer"
            >
              {{ aircraftEvent.origin.iata }}
            </div>
            <div class="text-center">
              {{ formatDate(aircraftEvent._start) }}
            </div>
            <div class="text-center text-xl lg:text-3xl font-bold grey--text">
              {{ `${formatTime(aircraftEvent._start)} Z` }}
            </div>
          </div>

          <!-- Center -->
          <div v-if="aircraftEvent.type === 'Flight'">
            <div
              class="mx-auto my-auto cursor-pointer"
              @click="handleNavigateToFlightView"
            >
              <div
                v-if="aircraftEvent.unscheduledEvent !== 'None'"
                class="text-center text-2xl text-red-500"
              >
                {{ aircraftEvent.unscheduledEvent }}
              </div>
              <div v-else class="text-center text-2xl">
                {{ aircraftEvent.status }}
              </div>
              <!-- <div class="text-center text-xl font-bold">Block Time</div> -->
              <div
                class="text-center text-xl lg:text-3xl text-blue-800 font-bold grey--text"
              >
                {{ `${aircraftEvent.duration.text} ` }}
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="mx-auto my-auto cursor-pointer"
              @click="handleNavigateToAircraftOperationalView"
            >
              <div
                class="text-center text-xl lg:text-3xl text-blue-800 font-bold grey--text"
              >
                {{ `${aircraftEvent.duration.text} ` }}
              </div>
            </div>
          </div>

          <!-- Destination -->
          <div class="mx-auto">
            <div
              v-if="aircraftEvent.type === 'Flight'"
              @click="
                handleShowAirportInformationDialog(aircraftEvent.destination)
              "
              class="text-center text-3xl font-bold cursor-pointer"
            >
              {{ aircraftEvent.destination.iata }}
            </div>
            <div
              v-else
              @click="handleShowAirportInformationDialog(aircraftEvent.origin)"
              class="text-center text-3xl font-bold cursor-pointer"
            >
              {{ aircraftEvent.origin.iata }}
            </div>
            <div class="text-center">
              {{ formatDate(aircraftEvent._end) }}
            </div>
            <div class="text-center text-xl lg:text-3xl font-bold grey--text">
              {{ `${formatTime(aircraftEvent._end)} Z` }}
            </div>
          </div>

          <div @click="handleGetNextAircraftEvent">
            <i class="fa-solid fa-right"></i>
          </div>
        </div>

        <!-- #region CREWMEMBERS -->
        <div v-if="aircraftEvent.type === 'Flight'" class="mb-1">
          <div
            class="btn-small bg-blue-500 text-white cursor-pointer mb-1"
            @click="handleAssignCrew"
          >
            Crew
          </div>

          <!-- FLIGHT CREWMEMBERS -->
          <div class="min-h-24 p-2 border border-gray-500 rounded-md">
            <div
              v-for="crewmember in aircraftEvent.crewmembers"
              :key="crewmember._id"
            >
              <div v-if="crewmember.employee">
                <div class="flex gap-2">
                  <div>{{ crewmember.seat }}:</div>
                  <div class="text-blue-900">
                    {{
                      `${crewmember.employee.surname}, ${crewmember.employee.givenName}`
                    }},
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="crewmember.required" class="flex gap-2">
                  <div>{{ crewmember.seat }}:</div>
                  <div>Please assign.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- #endregion -->

        <div class="border border-gray-500 rounded-md px-2">
          <p
            v-if="aircraftEvent.remarks"
            class="user-select-text text-sm text-blue-600 mt-2"
          >
            {{ aircraftEvent.remarks }}
          </p>
          <p v-else></p>
        </div>

        <div class="flex justify-end mt-1">
          <div class="text-[10px] text-gray-500 selectable-text">
            {{ aircraftEvent._id }}
          </div>
        </div>
      </div>
    </r-modal>
    <!-- #endregion -->

    <!-- #region BOARD -->
    <div
      class="board"
      ref="board"
      :style="{
        top: `${boardY}px`,
      }"
    >
      <!-- LEFT PANEL -->
      <div v-if="boardStyle === 'Board'" class="left-panel">
        <div class="left-panel-header bg-gray-500-l4">
          <div
            class="flex justify-center px-2 text-xl font-bold cursor-pointer"
          >
            Aircraft
          </div>
        </div>
        <div v-if="page">
          <div
            @click="handleShowAircraftInformation(item.aircraft)"
            class="left-panel-row bg-gray-500-l4 cursor-pointer"
            v-for="item in page.items"
            :key="item.aircraft.registration"
            :style="{ height: `${rowHeight}px` }"
          >
            <div class="text-lg text-bold">
              {{ item.aircraft.registration }}
            </div>
            <div :style="getAircraftTypeStyle(item.aircraft.model)">
              {{ item.aircraft.model }}
            </div>
          </div>
        </div>
      </div>
      <!-- RIGHT PANEL -->
      <div v-if="boardStyle === 'Board'" class="right-panel-container">
        <div v-if="page">
          <div class="right-panel">
            <!-- Event Tooltip -->
            <div
              class="flight-tooltip text-[12px]"
              :style="tooltipStyle"
              v-if="tooltip"
            >
              <div class="border-b border-gray-500 pb-1 mb-1 text-base">
                {{ tooltip.customerName }}
              </div>

              <div>
                <span v-if="tooltip.type === 'Flight'">{{
                  `${tooltip.flightNumber} | `
                }}</span>
                <span v-else>{{ `${tooltip.type} | ` }}</span>
                <span>{{ `${tooltip.aircraft.registration} | ` }}</span>
                <span>{{ `${tooltip.origin.iata} | ` }}</span>
                <span v-if="tooltip.type === 'Flight'">{{
                  `${tooltip.destination.iata}`
                }}</span>
              </div>

              <div class="border-b border-gray-500 pb-1 mb-1">
                {{
                  `${formatDateTime(tooltip._start)} . ${formatTime(
                    tooltip._end
                  )}`
                }}
              </div>

              <div v-if="tooltip.type === 'Flight'">
                <div
                  v-for="crewmember in tooltip.crewmembers"
                  :key="crewmember._id"
                >
                  <div v-if="crewmember.employee">
                    <span>{{ crewmember.seat }}:</span
                    ><span class="ml-1">
                      {{
                        `${crewmember.employee.surname}, ${crewmember.employee.givenName}`
                      }}
                    </span>
                  </div>
                  <div v-else>
                    <div v-if="crewmember.required">
                      <span>{{ crewmember.seat }}:</span
                      ><span class="ml-1"> Please assign. </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Header -->
            <div
              class="right-panel-header bg-gray-500-l4 cursor-pointer"
              @click="handleOpenDateFilter(item)"
              v-for="(item, index) in dateFilter.days"
              :key="index"
              :style="{
                top: 0,
                left: `${(1440 / scale) * index}px`,
                width: `${dayWidth}px`,
              }"
            >
              {{ formatHeaderDate(item) }}
            </div>

            <!-- Day Lines -->
            <div
              class="day-line"
              v-for="(day, index) in dateFilter.days"
              :key="day"
              :style="{
                left: `${(1440 / scale) * index}px`,
                height: `${availableBoardHeight + 50}px`,
              }"
            ></div>

            <!-- Hour Boxes -->
            <div
              class="hour-box text-[10px]"
              :class="{ hidden: marker.value === '00' || scale > 4 }"
              v-for="(marker, index) in dateFilter.hourBoxes"
              :key="marker.key"
              :style="{ left: `${(60 / scale) * index}px` }"
            >
              {{ marker.value }}
            </div>

            <!-- Hour Lines -->
            <div
              class="hour-line"
              :class="{ hidden: marker.value === '00' || scale > 6 }"
              v-for="(marker, index) in dateFilter.hourLines"
              :key="marker.key"
              :style="{
                left: `${(60 / scale) * index}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>

            <!-- Now Box -->
            <div
              class="now-box"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
              }"
            >
              {{ formatTime(new Date()) }}
            </div>

            <!-- Now Line -->
            <div
              class="now-line"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>

            <!-- Aircraft Event -->
            <div
              class="right-panel-row"
              v-for="(item, index) in page.items"
              :key="item.aircraft.registration"
              :style="getRowStyle(index)"
            >
              <div
                v-for="_aircraftEvent in item.aircraftEvents"
                @click="handleBoardLabelClicked(_aircraftEvent)"
                @mouseover="
                  handleShowAircraftEventTooltip(_aircraftEvent, index)
                "
                @mouseleave="handleHideAircraftEventTooltip"
                :key="_aircraftEvent._id"
                class="board-label-frame"
                :style="{
                  width: `${_aircraftEvent.duration.totalMinutes / scale}px`,
                  left: `${getLocationLeft(
                    _aircraftEvent._start,
                    dateFilter.days[0],
                    scale
                  )}px`,
                }"
              >
                <FlightBoardLabel
                  :aircraftEvent="_aircraftEvent"
                ></FlightBoardLabel>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="right-panel">
            <div
              class="right-panel-header bg-gray-500-l4 cursor-pointer"
              @click="handleOpenDateFilter(item)"
              v-for="(item, index) in dateFilter.days"
              :key="index"
              :style="{
                top: 0,
                left: `${(1440 / scale) * index}px`,
                width: `${dayWidth}px`,
              }"
            >
              {{ formatHeaderDate(item) }}
            </div>

            <!-- Day Lines -->
            <div
              class="day-line"
              v-for="(day, index) in dateFilter.days"
              :key="day"
              :style="{
                left: `${(1440 / scale) * index}px`,
                height: `${availableBoardHeight + 50}px`,
              }"
            ></div>

            <!-- Hour Boxes -->
            <div
              class="hour-box text-[10px]"
              :class="{ hidden: marker.value === '00' || scale > 4 }"
              v-for="(marker, index) in dateFilter.hourBoxes"
              :key="marker.key"
              :style="{ left: `${(60 / scale) * index}px` }"
            >
              {{ marker.value }}
            </div>

            <!-- Hour Lines -->
            <div
              class="hour-line"
              :class="{ hidden: marker.value === '00' || scale > 6 }"
              v-for="(marker, index) in dateFilter.hourLines"
              :key="marker.key"
              :style="{
                left: `${(60 / scale) * index}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>

            <!-- Now Box -->
            <div
              class="now-box"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
              }"
            >
              {{ formatTime(new Date()) }}
            </div>

            <!-- Now Line -->
            <div
              class="now-line"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion -->
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import FlightBoardLabel from "../components/FlightBoardLabel.vue";

import domtoimage from "dom-to-image";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "flightBoard",
  components: {
    FlightBoardLabel,
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },
  data() {
    return {
      loading: false,

      showAircraftEventDialog: false,
      tooltip: null,
      tooltipStyle: {
        display: "none",
      },

      aircraftEvents: [],
      aircraftEvent: null,
      myAircraft: null,

      //-----------------------------------Paginate
      availableBoardHeight: 0,
      aircraftSchedule: null,
      page: null,
      itemsPerPage: 0,
      pages: [],
      rowHeight: 60,
      disableNextPageButton: true,
      disablePreviousPageButton: true,

      boardStyle: "Board",
      timer: null,

      scale: 3,

      //-----------------------Filters
      showDateFilterDialog: false,
      startDate: null,
      numberOfDays: 7,
      numberOfDaysOptions: [3, 5, 7, 14, 21, 31],

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //--------------------------------------Airport Info
      showAirportInformationDialog: false,
      airport: null,

      //-------------------------------------Aircraft Info
      showAircraftDialog: false,

      crewmember: null,
    };
  },
  mixins: [mixin],
  created() {
    this.getData();
  },

  mounted() {
    this.$nextTick(() => {
      const board = this.$refs.board;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );
    });

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    auth() {
      return this.$store.getters.auth;
    },

    showCurrentTime() {
      const now = new Date().getTime();

      return (
        now > new Date(this.dateFilter.start).getTime() &&
        now < new Date(this.dateFilter.end).getTime()
      );
    },

    flightBoardAirportFilter() {
      return this.$store.getters.flightBoardAirportFilter;
    },

    pageNumber() {
      return this.$store.getters.flightBoardPageNumber;
    },

    nowLineLeft() {
      const x = this.getLocationLeft(
        new Date().toISOString(),
        this.dateFilter.start,
        this.scale
      );

      if (x > 0 || x < this.boardWidth) {
        return x;
      } else {
        return 0;
      }
    },

    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale);
    },

    boardY() {
      return 50;
    },

    dayWidth() {
      return 1440 / this.scale;
    },

    // board() {
    //   return this.$store.getters.board;
    // },

    aircraft() {
      return this.$store.getters.aircraft;
    },

    airports() {
      return this.$store.getters.airports;
    },

    convertedWeight() {
      let w = 0;

      if (this.units === "Lbs") {
        if (this.rounded) {
          w = Math.ceil(this.weight / 2.20462);
        } else {
          w = (this.weight / 2.20462).toFixed(5);
        }
        return `${this.formatWeight(w)} Kgs`;
      } else {
        if (this.rounded) {
          w = Math.ceil(this.weight * 2.20462);
        } else {
          w = (this.weight * 2.20462).toFixed(5);
        }
        return `${this.formatWeight(w)} Lbs`;
      }
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    errors() {
      return this.$store.getters.errors;
    },
  },

  methods: {
    handleAssignCrew() {
      const assignmentData = {
        type: "flight",
        flight: this.aircraftEvent,
      };
      this.$store.commit("updateAssignmentData", assignmentData);
      this.$router.push({ name: "assignmentBoard" });
    },

    async handleNavigateToPlanning() {
      try {
        let res;

        //----------------------Get Customers, Aircraft, and Airports
        this.loading = true;

        res = await this.$store.dispatch("getResources");

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        const customers = res.data.customers;
        const aircraft = res.data.aircraft;
        const airports = res.data.airports;

        this.$store.commit("updateCustomers", customers);
        this.$store.commit("updateAircraft", aircraft);
        this.$store.commit("updateAirports", airports);

        this.$router.push({ name: "planning" });
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
      }
    },

    handleNavigateToFlightView() {
      //TODO handle different types
      switch (this.aircraftEvent.type) {
        case "Flight":
          this.$store.commit("updateFlight", { ...this.aircraftEvent });
          this.$router.push({ name: "flightView" });

          break;

        default:
          break;
      }
    },

    handleNavigateToAircraftOperationalView() {
      //TEMP

      this.snackbarColor = "orange";
      this.snackbarText = "Under Development";
      this.snackbarVisible = true;

      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },

    handleShowAircraftInformation(aircraft) {
      this.myAircraft = { ...aircraft };
      this.showAircraftDialog = true;
    },

    handleEditAircraft(aircraft) {
      this.showAircraftDialog = false;
      let selectedAircraft = { ...aircraft };

      if (selectedAircraft.cabinConfig) {
        // Cabin Configuration
        let cabin;

        cabin = selectedAircraft.cabinConfig[0];

        if (cabin) {
          selectedAircraft.cabinOne = {
            active: true,
            class: selectedAircraft.cabinConfig[0].class,
            seats: selectedAircraft.cabinConfig[0].seats,
          };
        } else {
          selectedAircraft.cabinOne = {
            active: false,
            class: "A",
            seats: 100,
          };
        }

        cabin = selectedAircraft.cabinConfig[1];

        if (cabin) {
          selectedAircraft.cabinTwo = {
            active: true,
            class: selectedAircraft.cabinConfig[1].class,
            seats: selectedAircraft.cabinConfig[1].seats,
          };
        } else {
          selectedAircraft.cabinTwo = {
            active: false,
            class: "B",
            seats: 100,
          };
        }

        cabin = selectedAircraft.cabinConfig[2];

        if (cabin) {
          selectedAircraft.cabinThree = {
            active: true,
            class: selectedAircraft.cabinConfig[2].class,
            seats: selectedAircraft.cabinConfig[2].seats,
          };
        } else {
          selectedAircraft.cabinThree = {
            active: false,
            class: "C",
            seats: 100,
          };
        }
      }

      if (selectedAircraft.swaCategories) {
        // SWA Categories
        let category;

        category = selectedAircraft.swaCategories[0];

        if (category) {
          selectedAircraft.swaCategoryOne = {
            active: true,
            name: selectedAircraft.swaCategories[0].name,
            value: selectedAircraft.swaCategories[0].value,
          };
        } else {
          selectedAircraft.swaCategoryOne = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[1];

        if (category) {
          selectedAircraft.swaCategoryTwo = {
            active: true,
            name: selectedAircraft.swaCategories[1].name,
            value: selectedAircraft.swaCategories[1].value,
          };
        } else {
          selectedAircraft.swaCategoryTwo = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[2];

        if (category) {
          selectedAircraft.swaCategoryThree = {
            active: true,
            name: selectedAircraft.swaCategories[2].name,
            value: selectedAircraft.swaCategories[2].value,
          };
        } else {
          selectedAircraft.swaCategoryThree = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[3];

        if (category) {
          selectedAircraft.swaCategoryFour = {
            active: true,
            name: selectedAircraft.swaCategories[3].name,
            value: selectedAircraft.swaCategories[3].value,
          };
        } else {
          selectedAircraft.swaCategoryFour = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[4];

        if (category) {
          selectedAircraft.swaCategoryFive = {
            active: true,
            name: selectedAircraft.swaCategories[4].name,
            value: selectedAircraft.swaCategories[4].value,
          };
        } else {
          selectedAircraft.swaCategoryFive = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[5];

        if (category) {
          selectedAircraft.swaCategorySix = {
            active: true,
            name: selectedAircraft.swaCategories[5].name,
            value: selectedAircraft.swaCategories[5].value,
          };
        } else {
          selectedAircraft.swaCategorySix = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[6];

        if (category) {
          selectedAircraft.swaCategorySeven = {
            active: true,
            name: selectedAircraft.swaCategories[6].name,
            value: selectedAircraft.swaCategories[6].value,
          };
        } else {
          selectedAircraft.swaCategorySeven = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[7];

        if (category) {
          selectedAircraft.swaCategoryEight = {
            active: true,
            name: selectedAircraft.swaCategories[7].name,
            value: selectedAircraft.swaCategories[7].value,
          };
        } else {
          selectedAircraft.swaCategoryEight = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[8];

        if (category) {
          selectedAircraft.swaCategoryNine = {
            active: true,
            name: selectedAircraft.swaCategories[8].name,
            value: selectedAircraft.swaCategories[8].value,
          };
        } else {
          selectedAircraft.swaCategoryNine = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[9];

        if (category) {
          selectedAircraft.swaCategoryTen = {
            active: true,
            name: selectedAircraft.swaCategories[9].name,
            value: selectedAircraft.swaCategories[9].value,
          };
        } else {
          selectedAircraft.swaCategoryTen = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[10];

        if (category) {
          selectedAircraft.swaCategoryEleven = {
            active: true,
            name: selectedAircraft.swaCategories[10].name,
            value: selectedAircraft.swaCategories[10].value,
          };
        } else {
          selectedAircraft.swaCategoryEleven = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[11];

        if (category) {
          selectedAircraft.swaCategoryTwelve = {
            active: true,
            name: selectedAircraft.swaCategories[11].name,
            value: selectedAircraft.swaCategories[11].value,
          };
        } else {
          selectedAircraft.swaCategoryTwelve = {
            active: false,
            class: "",
            value: 0,
          };
        }
      }

      this.$store.commit("updateSelectedAircraft", selectedAircraft);
      this.$router.push({ name: "selectedAircraft" });
    },

    getRowStyle(i) {
      let style = {
        top: `${i * this.rowHeight + 50}px`,
        width: `${this.boardWidth}px`,
        height: `${this.rowHeight}px`,
      };

      return style;
    },

    onResize() {
      const board = this.$refs.board;

      if (!board) return;

      //Notes
      //Reduce 100px for menus, 20px for scrolling bar,

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );

      this.paginate();
    },

    handleShowAircraftEventTooltip(aircraftEvent, index) {
      this.tooltip = { ...aircraftEvent };

      let style = {
        display: "block",
        left: `${
          this.getLocationLeft(
            aircraftEvent._start,
            this.dateFilter.days[0],
            this.scale
          ) + 30
        }px`,
      };

      if (this.pages.length > 1 && index >= this.page.items.length - 2) {
        style.top = `${index * this.rowHeight - 50}px`;
      } else {
        style.top = `${index * this.rowHeight + 50 + 45}px`;
      }

      this.tooltipStyle = style;
    },

    handleHideAircraftEventTooltip() {
      setTimeout(() => {
        this.tooltipStyle = {
          display: "none",
        };
      }, 150);
    },

    paginate() {
      if (!this.aircraftSchedule) {
        return;
      }

      this.pages = [];
      let _pages = [];

      let data = [...this.aircraftSchedule];

      if (data.length) {
        let chunk = Math.min(
          Math.floor(this.availableBoardHeight / this.rowHeight),
          data.length
        );

        //Show warning if not enough space to display
        if (chunk < 1) {
          this.snackbarColor = "red";
          this.snackbarText = "Not enough screen space to display data";
          this.snackbarVisible = true;
          return;
        }

        //Split data into pages
        for (let i = 0; i < data.length; i += chunk) {
          let tempArray;
          tempArray = data.slice(i, i + chunk);
          _pages.push({
            index: _pages.length,
            items: tempArray,
          });
        }

        this.pages = [..._pages];

        const i = this.pages.findIndex((page) => {
          return page.index === this.pageNumber;
        });

        if (i < 0) {
          this.$store.commit("updateFlightBoardPageNumber", 0);
        }

        this.page = this.pages[this.pageNumber];
      }
    },

    handlePageUp() {
      if (this.pageNumber > 0) {
        const val = this.pageNumber - 1;
        this.$store.commit("updateFlightBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handlePageDown() {
      if (this.pageNumber < this.pages.length - 1) {
        const val = this.pageNumber + 1;
        this.$store.commit("updateFlightBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    handleNavigateToHome() {
      this.$router.go({ name: "home" });
    },

    compareAircraftTypes(a, b) {
      if (a.type < b.type) {
        return -1;
      }
      if (b.type < a.type) {
        return 1;
      }
      return 0;
    },

    handleOpenDateFilter(item) {
      this.startDate = item.substring(0, 10);
      this.numberOfDays = this.dateFilter.days.length;

      this.showDateFilterDialog = true;
    },

    handleSaveDateFilter() {
      const filterStart = new Date(this.startDate).getTime();

      this.showDateFilterDialog = false;

      //Pass filterStart in miliseconds, numberOfDays as integer
      this.$store.commit("updateDateFilter", {
        filterStart,
        numberOfDays: this.numberOfDays,
      });

      this.getData();
    },

    handleToggleBoardStyle() {
      if (this.boardStyle === "Board") {
        let aircraftEvents = [];

        if (this.aircraftSchedule) {
          this.aircraftSchedule.forEach((item) => {
            item.aircraftEvents.forEach((_aircraftEvent) => {
              aircraftEvents.push(_aircraftEvent);
            });
          });
        }

        this.aircraftEvents = [...aircraftEvents];
        this.boardStyle = "List";
      } else {
        this.boardStyle = "Board";
      }
    },

    async handleGetPreviousAircraftEvent() {
      if (!this.page) {
        return;
      }
      const scheduleItem = this.page.items.find((item) => {
        return (
          item.aircraft.registration ===
          this.aircraftEvent.aircraft.registration
        );
      });

      if (!scheduleItem) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = "Unable to find data";
        this.snackbarVisible = true;
        return;
      }

      const aircraftEvents = scheduleItem.aircraftEvents;

      aircraftEvents.sort(function (a, b) {
        let dateA = new Date(a._start);
        let dateB = new Date(b._start);

        return dateA - dateB;
      });

      const i = aircraftEvents.findIndex((aircraftEvent) => {
        return aircraftEvent._id === this.aircraftEvent._id;
      });

      if (i > 0) {
        this.aircraftEvent = { ...aircraftEvents[i - 1] };
      } else {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = "No previous event found within the selected range";
        this.showAircraftEventDialog = false;
        this.snackbarVisible = true;
        return;
      }
    },

    async handleGetNextAircraftEvent() {
      if (!this.page) {
        return;
      }

      const scheduleItem = this.page.items.find((item) => {
        return (
          item.aircraft.registration ===
          this.aircraftEvent.aircraft.registration
        );
      });

      if (!scheduleItem) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = "Unable to find data";
        this.snackbarVisible = true;
        return;
      }

      const aircraftEvents = scheduleItem.aircraftEvents;

      aircraftEvents.sort(function (a, b) {
        let dateA = new Date(a._start);
        let dateB = new Date(b._start);

        return dateA - dateB;
      });

      const i = aircraftEvents.findIndex((aircraftEvent) => {
        return aircraftEvent._id === this.aircraftEvent._id;
      });

      if (i < aircraftEvents.length - 1) {
        this.aircraftEvent = { ...aircraftEvents[i + 1] };
      } else {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = "No next event found within the selected range";
        this.showAircraftEventDialog = false;
        this.snackbarVisible = true;
        return;
      }
    },

    zoomIn() {
      if (this.scale > 1) {
        this.scale -= 1;
      }
    },

    zoomOut() {
      if (this.scale < 8) {
        this.scale += 1;
      }
    },

    async handleCaptureFlightBoard() {
      this.loading = true;

      const board = this.$refs.board;

      try {
        // Convert the DOM element to a blob
        const blob = await domtoimage.toBlob(board);

        // Convert the blob to an array buffer
        const response = new Response(blob);
        const arrayBuffer = await response.arrayBuffer();

        const pdfDoc = await PDFDocument.create();
        const pngImage = await pdfDoc.embedPng(arrayBuffer);

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        const title = `Flight Times- Flight Board`;
        pdfDoc.setTitle(title);

        const pngDims = pngImage.scale(0.85);
        pdfDoc.addPage([pngDims.width + 100, pngDims.height + 100]);
        let pages = pdfDoc.getPages();
        let page = pages[0];
        let pdfOptions = {
          x: 50,
          y: 50,
          size: 16,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        };
        page.drawImage(pngImage, {
          x: pdfOptions.x,
          y: pdfOptions.y,
          width: pngDims.width,
          height: pngDims.height,
        });
        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
      }
    },

    handleBoardLabelClicked(aircraftEvent) {
      this.aircraftEvent = { ...aircraftEvent };
      this.showAircraftEventDialog = true;
    },

    async getData() {
      this.loading = true;

      this.aircraftSchedule = null;
      this.page = null;

      try {
        const token = this.auth.token;

        const res = await api.get("/aircraft-events/flight-ops", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            _start: this.dateFilter.start,
            _end: this.dateFilter.end,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.aircraftSchedule = res.data.schedule;

        this.paginate();
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
      }
    },

    async handleShowAirportInformationDialog(airport) {
      this.airport = { ...airport };
      this.showAircraftEventDialog = false;
      this.showAirportInformationDialog = true;
    },

    handleHideAirportInformationDialog() {
      this.airport = null;
      this.showAirportInformationDialog = false;
      this.showAircraftEventDialog = true;
    },

    hideAircraftEventDialog() {
      this.aircraftEvent = null;
      this.showAircraftEventDialog = false;
    },

    extractLastName(fullname) {
      if (fullname.length) {
        const res = fullname.split(" ");
        return res[0];
      } else {
        return "";
      }
    },

    formatDateShort(miliseconds) {
      const date = new Date(miliseconds);

      let options = {
        timeZone: "UTC",
        month: "short",
        day: "numeric",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    formatHeaderDate(string) {
      const date = new Date(string);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    getAircraftTypeStyle(model) {
      let style = {
        backgroundColor: "white",
        paddingLeft: "5px",
        border: "1px solid #bebebe",
        borderRadius: "5px",
        fontFamily: "Abel, Arial, Helvetica, sans-serif",
        fontSize: "12px",
        textAlign: "center",
        cursor: "pointer",
      };

      switch (model) {
        case "MD-80":
          style.backgroundColor = "#31abb2";
          break;
        case "MD-83":
          style.backgroundColor = "#03abdb";
          break;
        default:
          break;
      }

      return style;
    },
  },
};
</script>

<style scoped>
.flight-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  min-width: 250px;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(128, 128, 128, 0.9);
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  z-index: 700;
}

.right-panel-row {
  position: absolute;
  top: 50px;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 1px solid gray;
  background-color: #fff;
  overflow: hidden;
}

.right-panel-row:nth-child(odd) {
  background-color: #f4f9fd;
}

p {
  width: 100%;
  min-height: 100px;
}

.remove-icon:hover {
  color: red;
}

.fa-left {
  font-size: 2.3rem;
  color: #8e8e8e;
  cursor: pointer;
}

.fa-left:hover {
  color: #1d8de9;
}

.fa-right {
  font-size: 2.3rem;
  color: #8e8e8e;
  cursor: pointer;
}

.fa-right:hover {
  color: #1d8de9;
}

.fa-star {
  font-size: 1.1rem;
  color: #d4af37;
  padding: 0 1rem;
}

.fa-add:hover {
  color: blue;
}

.fa-close {
  font-size: 1.1rem;
  color: #8e8e8e;
  cursor: pointer;
  padding: 0 15px;
}

.fa-close:hover {
  color: red;
}

.fa-calculator {
  font-size: 2rem;
  color: #8e8e8e;
  cursor: pointer;
  padding: 0 15px;
}

.weight-converter {
  align-items: center;
  padding: 5px 10px;
}

.aircraft-operational {
  background-color: #f5d0a9;
}
</style>
