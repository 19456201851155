<template>
  <div class="outer-frame">
    <div class="top-label">
      {{ `${assignment.flightNumber} | ${assignment.seat}` }}
    </div>

    <div class="inner-frame" :style="getInnerFrameStyle()">
      <div class="text-[12px]">
        {{ `${assignment.origin.iata}-${assignment.destination.iata}` }}
      </div>
    </div>
    <div class="bottom-label">
      <div class="text-[10px]">
        {{ assignment._start.substring(11, 16) }}
      </div>
      <div class="text-[10px]">
        {{ assignment._end.substring(11, 16) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "flightAssignmentLabel",
  mixins: [mixin],
  data() {
    return {};
  },
  props: ["assignment"],

  methods: {
    getInnerFrameStyle() {
      const style = {
        padding: "5px 0",
        border: "1px solid #bebebe",
        backgroundColor: "#91c4c5",
        color: "#000",
      };

      return style;
    },
  },
};
</script>
<style scoped>
.outer-frame {
  position: absolute;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
}

.inner-frame {
  position: relative;
  justify-content: center;
  align-items: center;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #bebebe;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.top-label {
  font-size: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottom-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
