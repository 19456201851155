<template>
  <div>
    <nav>
      <ul class="nav-wrapper">
        <li class="icon" @click="handleGoBack">
          <a href="#">
            <span class="tooltip">Go Back</span>
            <span><i class="fa-solid fa-arrow-left text-white"> </i></span>
          </a>
        </li>

        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">
              {{
                `${airport.name}, ${airport.country} (${airport.iata} - ${airport.icao})`
              }}
            </div>
          </a>
        </li>

        <li class="icon" @click="handleInitPeriod('COVID-19')">
          <a href="#">
            <span class="tooltip">New COVID-19 Period</span>
            <span><i class="fa-solid fa-virus text-green-500"></i></span>
          </a>
        </li>

        <li class="icon" @click="handleInitPeriod('Hazard')">
          <a href="#">
            <span class="tooltip">New Hazard Period</span>
            <span><i class="fa-solid fa-biohazard text-red-500"></i></span>
          </a>
        </li>

        <li>
          <RouterLink to="/"></RouterLink>
        </li>
      </ul>
    </nav>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->
    <r-spinner v-if="loading"> </r-spinner>

    <!-- NEW PERIOD DIALOG-->
    <r-modal v-if="showNewPeriodDialog">
      <div class="p-5 -l2 text-sm">
        <div class="flex justify-between p-2">
          <div class="text-2xl">
            {{ newPeriod.category }}
          </div>

          <button @click="showNewPeriodDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div>
          <SwitchGroup as="div" class="flex items-center my-2">
            <Switch
              v-model="newPeriod.openEnded"
              :class="[
                newPeriod.openEnded ? 'bg-blue-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
              ]"
            >
              <span
                aria-hidden="true"
                :class="[
                  newPeriod.openEnded ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                ]"
              />
            </Switch>
            <SwitchLabel as="span" class="ml-3 text-sm">
              <span class="font-medium text-gray-900">Open-Ended</span>
              {{ " " }}
            </SwitchLabel>
          </SwitchGroup>

          <div class="flex">
            <div>
              <label
                for="startDate"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Start Date</label
              >
              <div class="mt-2">
                <input
                  v-model="newPeriod.startDate"
                  type="date"
                  name="startDate"
                  id="startDate"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div v-if="!newPeriod.openEnded">
              <div>
                <label
                  for="endDate"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >End Date</label
                >
                <div class="mt-2 ml-2">
                  <input
                    v-model="newPeriod.endDate"
                    type="date"
                    name="endDate"
                    id="endDate"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-end">
          <button
            @click="handleAddSpecialPeriod"
            class="btn bg-green-500 text-white"
          >
            Save
          </button>
        </div>
      </div>
    </r-modal>
    <!-- Data Table -->
    <div class="relative overflow-x-auto">
      <table
        v-if="airport.periods.length"
        class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
      >
        <thead
          class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">Special Period</th>
            <th scope="col" class="px-6 py-3">Period Start</th>
            <th scope="col" class="px-6 py-3">Period End</th>
            <th scope="col" class="px-6 py-3">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="period in airport.periods"
            :key="period._id"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
          >
            <td class="px-6 py-2">{{ period.category }}</td>
            <td class="px-6 py-2">{{ period.start }}</td>
            <td class="px-6 py-2">{{ formatEnd(period.end) }}</td>
            <td class="px-6 py-2" @click="handleDeletePeriod(period)">
              <i class="fa-solid fa-trash-can cursor-pointer"></i>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="p-5 text-2xl text-green-600">
        This airport has ho COVID-19 or Hazard Periods
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export default {
  name: "airports",
  mixins: [mixin],
  components: { RSnackbar, RSpinner, RModal, Switch, SwitchGroup, SwitchLabel },
  data() {
    return {
      loading: false,

      newPeriod: null,
      showNewPeriodDialog: false,

      selectedCovid19StartDate: new Date().toISOString().substring(0, 10),
      selectedCovid19EndDate: new Date(
        new Date().getTime() + 30 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .substring(0, 10),

      selectedHazardStartDate: new Date().toISOString().substring(0, 10),
      selectedHazardEndDate: new Date(
        new Date().getTime() + 30 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .substring(0, 10),

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      filteredAirports: [],

      isCovid19: false,
      isCovid19OpenEnded: false,
      isHazard: false,
      isHazardOpenEnded: false,
      targetDate: new Date().toISOString().substring(0, 10),
      filterDate: new Date().toISOString().substring(0, 10),
    };
  },

  computed: {
    airport() {
      return this.$store.getters.airport;
    },
  },

  methods: {
    handleGoBack() {
      this.$router.go(-1);
    },

    handleInitPeriod(category) {
      this.newPeriod = {
        category,
        startDate: new Date().toISOString().substring(0, 10),
        endDate: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
          .toISOString()
          .substring(0, 10),
        openEnded: false,
      };
      this.showNewPeriodDialog = true;
    },

    async handleAddSpecialPeriod() {
      const start = `${this.newPeriod.startDate}T00:00:00.000Z`;
      let end;

      if (this.newPeriod.openEnded) {
        end = "2999-01-01T23:59:59.999Z";
      } else {
        end = `${this.newPeriod.endDate}T23:59:59.999Z`;
        if (new Date(end).getTime() < new Date(start).getTime()) {
          this.snackbarColor = "red";
          this.snackbarText = "Please check dates";
          return (this.snackbarVisible = true);
        }
      }

      this.showNewPeriodDialog = false;
      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/airports/${this.airport._id}`,
          {
            action: "add",
            period: {
              category: this.newPeriod.category,
              start,
              end,
            },
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        const airport = res.data.airport;

        let periods = [];

        if (airport.covid19Periods.length) {
          airport.covid19Periods.forEach((period) => {
            periods.push({
              category: "COVID-19",
              start: this.formatDate(period.start),
              end: this.formatDate(period.end),
              _id: period._id,
            });
          });
        }

        if (airport.hazardPeriods.length) {
          airport.hazardPeriods.forEach((period) => {
            periods.push({
              category: "Hazard",
              start: this.formatDate(period.start),
              end: this.formatDate(period.end),
              _id: period._id,
            });
          });
        }

        this.$store.commit("updateAirport", { ...airport, periods });

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleDeletePeriod(period) {
      try {
        this.loading = true;

        const token = await aad.getIdTokenSilent();
        const res = await api.put(
          `/airports/${this.airport._id}`,
          {
            action: "remove",
            period,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        const airport = res.data.airport;

        let periods = [];

        if (airport.covid19Periods.length) {
          airport.covid19Periods.forEach((period) => {
            periods.push({
              category: "COVID-19",
              start: this.formatDate(period.start),
              end: this.formatDate(period.end),
              _id: period._id,
            });
          });
        }

        if (airport.hazardPeriods.length) {
          airport.hazardPeriods.forEach((period) => {
            periods.push({
              category: "Hazard",
              start: this.formatDate(period.start),
              end: this.formatDate(period.end),
              _id: period._id,
            });
          });
        }

        this.$store.commit("updateAirport", { ...airport, periods });

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    formatEnd(end) {
      const year = new Date(end).getFullYear();
      if (year < 2999) {
        return end;
      } else {
        return "Open-Ended";
      }
    },
  },
};
</script>

<style scoped></style>
