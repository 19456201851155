import { createStore } from "vuex";

import api from "../services/api";

const store = createStore({
  state: {
    auth: null,
    dateFilter: null,
    monthFilter: null,
    assignmentData: null,
    flight: null,
    employee: null,
    employees: [],
    customers: [],
    assignmentsNotAcknowledged: [],
    aircraft: [],
    selectedAircraft: null,
    aircraftSortedBy: "registration",
    airport: null,
    airports: [],
    assignmentWarnings: [],
    authorizedFlightRoles: [],
    assignmentBoardPageNumber: 0,
    flightBoardPageNumber: 0,
    bidlinesPageNumber: 0,
    securityGroup: null,
    securityGroups: [],
  },

  getters: {
    customers(state) {
      return state.customers;
    },

    aircraft(state) {
      return state.aircraft;
    },

    auth(state) {
      return state.auth;
    },

    assignmentData(state) {
      return state.assignmentData;
    },

    airport(state) {
      return state.airport;
    },

    airports(state) {
      return state.airports;
    },

    securityGroups(state) {
      return state.securityGroups;
    },

    securityGroup(state) {
      return state.securityGroup;
    },

    monthFilter(state) {
      return state.monthFilter;
    },

    loading(state) {
      return state.loading;
    },

    airports(state) {
      return state.airports;
    },

    aircraft(state) {
      return state.aircraft;
    },

    aircraftSortedBy(state) {
      return state.aircraftSortedBy;
    },

    selectedAircraft(state) {
      return state.selectedAircraft;
    },

    flight(state) {
      return state.flight;
    },

    employee(state) {
      return state.employee;
    },

    employees(state) {
      return state.employees;
    },

    dateFilter(state) {
      return state.dateFilter;
    },

    assignmentBoardPageNumber(state) {
      return state.assignmentBoardPageNumber;
    },

    flightBoardPageNumber(state) {
      return state.flightBoardPageNumber;
    },

    bidlinesPageNumber(state) {
      return state.bidlinesPageNumber;
    },
  },

  mutations: {
    updateCustomers(state, customers) {
      state.customers = customers;
    },

    updateAircraft(state, aircraft) {
      state.aircraft = aircraft;
    },

    updateAuth(state, auth) {
      state.auth = auth;
    },

    updateAssignmentData(state, assignmentData) {
      state.assignmentData = assignmentData;
    },

    updateSecurityGroups(state, securityGroups) {
      state.securityGroups = securityGroups;
    },

    updateAirport(state, airport) {
      state.airport = airport;
    },

    updateAirports(state, airports) {
      state.airports = airports;
    },

    updateSecurityGroup(state, securityGroup) {
      state.securityGroup = securityGroup;
    },

    updateAirports(state, airports) {
      state.airports = airports;
    },

    updateEmployee(state, employee) {
      state.employee = employee;
    },

    updateEmployees(state, employees) {
      state.employees = employees;
    },

    updateAircraft(state, aircraft) {
      state.aircraft = aircraft;
    },

    updateAircraftSortedBy(state, aircraftSortedBy) {
      state.aircraftSortedBy = aircraftSortedBy;
    },

    updateSelectedAircraft(state, selectedAircraft) {
      state.selectedAircraft = selectedAircraft;
    },

    updateFlight(state, flight) {
      state.flight = flight;
    },

    updateAuth(state, auth) {
      state.auth = auth;
    },

    updateDateFilter(state, { filterStart, numberOfDays }) {
      //Note filterStart comes in as miliseconds, numberOfDays as integer
      let days = [];
      let day;

      for (let i = 0; i < numberOfDays + 1; i++) {
        day = new Date(
          new Date(filterStart).getTime() + i * (24 * 60 * 60 * 1000)
        ).toISOString();
        days.push(day);
      }

      const start = days[0];
      const lastDay = days[days.length - 1];
      const end = new Date(new Date(lastDay).getTime() - 1).toISOString();

      // Remove the last day
      const filterDays = days.slice(0, numberOfDays);

      let labels = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];

      let hourLabels = [];
      let hourBoxes = [];
      let hourLines = [];

      for (let d = 0; d < filterDays.length; d++) {
        for (let i = 0; i < 24; i++) {
          hourLabels.push(`${labels[i]}`);
          hourBoxes.push({ key: `L${d}-${i}`, value: `${labels[i]}` });
          hourLines.push({ key: `B${d}-${i}`, value: `${labels[i]}` });
        }
      }

      const filter = {
        start,
        end,
        startDate: new Date(start).toISOString().substring(0, 10),
        endDate: new Date(end).toISOString().substring(0, 10),
        days: filterDays,
        hourLabels,
        hourBoxes,
        hourLines,
      };

      state.dateFilter = filter;
    },

    updateMonthFilter(state, { year, month, period }) {
      const filterStart = new Date(
        Date.UTC(year, month, 1, 0, 0, 0, 0)
      ).getTime();

      const numberOfDays = new Date(year, month + 1, 0).getDate();

      let days = [];
      let day;

      for (let i = 0; i < numberOfDays + 1; i++) {
        day = new Date(
          new Date(filterStart).getTime() + i * (24 * 60 * 60 * 1000)
        ).toISOString();
        days.push(day);
      }

      const start = days[0];
      const lastDay = days[days.length - 1];
      const end = new Date(new Date(lastDay).getTime() - 1).toISOString();

      // Remove the last day
      const filterDays = days.slice(0, numberOfDays);

      let labels = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];

      let hourLabels = [];
      let hourBoxes = [];
      let hourLines = [];

      for (let d = 0; d < filterDays.length; d++) {
        for (let i = 0; i < 24; i++) {
          hourLabels.push(`${labels[i]}`);
          hourBoxes.push({ key: `L${d}-${i}`, value: `${labels[i]}` });
          hourLines.push({ key: `B${d}-${i}`, value: `${labels[i]}` });
        }
      }

      const filter = {
        start,
        end,
        startDate: new Date(start).toISOString().substring(0, 10),
        endDate: new Date(end).toISOString().substring(0, 10),
        days: filterDays,
        hourLabels,
        hourBoxes,
        hourLines,
        year,
        month,
        period,
      };

      state.monthFilter = filter;
    },

    updateAssignmentBoardPageNumber(state, pageNumber) {
      state.assignmentBoardPageNumber = pageNumber;
    },

    updateFlightBoardPageNumber(state, pageNumber) {
      state.flightBoardPageNumber = pageNumber;
    },

    updateBidlinesPageNumber(state, pageNumber) {
      state.bidlinesPageNumber = pageNumber;
    },
  },

  actions: {
    async getResources({ state }) {
      try {
        const token = state.auth.token;

        const res = await api.get(`/generic/resources`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        return res;
      } catch (error) {
        return error;
      }
    },

    async precheckAircraftSchedule({ state }, aircraftSchedule) {
      try {
        const token = state.auth.token;

        const res = await api.post(
          `/aircraft-events/planning/precheck`,
          {
            aircraftSchedule,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        return res;
      } catch (error) {
        return error;
      }
    },

    async saveAircraftSchedule({ state }, aircraftEvents) {
      try {
        const token = state.auth.token;

        const res = await api.post(
          `/aircraft-events/planning`,
          {
            aircraftEvents,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        return res;
      } catch (error) {
        return error;
      }
    },

    async precheckAircraftEvent({ state }, aircraftEvent) {
      try {
        const token = state.auth.token;

        const res = await api.post(
          `/aircraft-events/flight-ops/precheck/${aircraftEvent._id}`,
          {
            aircraftEvent,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        return res;
      } catch (error) {
        return error;
      }
    },

    async saveAircraftEvent({ state }, aircraftEvent) {
      try {
        const token = state.auth.token;

        const res = await api.put(
          `/aircraft-events/flight-ops/${aircraftEvent._id}`,
          {
            aircraftEvent,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        return res;
      } catch (error) {
        return error;
      }
    },

    async getCustomers({ state }, status) {
      try {
        const token = state.auth.token;

        const res = await api.get("/customers", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            status,
          },
        });

        return res;
      } catch (error) {
        return error;
      }
    },

    async getEmployees({ state }, status) {
      try {
        const token = state.auth.token;
        const res = await api.get("/employees", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            status,
          },
        });

        return res;
      } catch (error) {
        return error;
      }
    },

    async getFlight(_, flightId) {
      try {
        const token = state.auth.token;

        const res = await api.get(`/flights/${flightId}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        return res;
      } catch (error) {
        return error;
      }
    },

    async getAircraft({ state }, status) {
      try {
        const token = state.auth.token;

        const res = await api.get(`/aircraft/${status}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        return res;
      } catch (error) {
        return error;
      }
    },

    async updateFlightRemarks(_, payload) {
      try {
        const { _id, remarks } = payload;
        const token = state.auth.token;

        const res = await api.put(
          `/flights/${_id}/remarks`,
          {
            remarks,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        return res;
      } catch (error) {
        return error;
      }
    },

    async deleteFlightAssignment(_, { flight_id, assignment_id }) {
      try {
        const token = state.auth.token;

        const res = await api.put(
          `/flights/${flight_id}/crewmembers/remove`,
          {
            assignment_id,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        return res;
      } catch (error) {
        return error;
      }
    },

    async removeEmployeeFromFlightAssignment(_, { flight_id, assignment_id }) {
      try {
        const token = state.auth.token;

        const res = await api.put(
          `/flights/${flight_id}/crewmembers/remove-employee`,
          {
            assignment_id,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        return res;
      } catch (error) {
        return error;
      }
    },

    async sendAssignmentNotification(_, { assignment, emailSubject }) {
      try {
        const token = state.auth.token;

        const res = await api.put(
          `/notifications/single`,
          {
            assignment,
            emailSubject,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        return res;
      } catch (error) {
        return error;
      }
    },

    async sendMultipleAssignmentNotifications(
      _,
      { assignments, emailSubject }
    ) {
      try {
        const token = state.auth.token;

        const res = await api.put(
          `/notifications/multi`,
          {
            assignments,
            emailSubject,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        return res;
      } catch (error) {
        return error;
      }
    },

    async saveFlightAssignmentRemarks(
      _,
      { flight_id, assignment_id, remarks }
    ) {
      try {
        const token = state.auth.token;

        const res = await api.put(
          `/flights/${flight_id}/crewmembers/update-remarks`,
          {
            assignment_id,
            remarks,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        return res;
      } catch (error) {
        return error;
      }
    },
  },
});

export default store;
