<template>
  <div>
    <!-- #region NAVBAR -->
    <nav>
      <ul class="nav-wrapper">
        <li class="icon" @click="handleGoBack">
          <a href="#">
            <span class="tooltip">Go Back</span>
            <span><i class="fa-solid fa-arrow-left text-white"> </i></span>
          </a>
        </li>

        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">Assignments</div>
          </a>
        </li>

        <li>
          <a href="#">
            <div
              v-if="boardStyle === 'Board' && pages.length"
              class="flex justify-between fnt-sm"
            >
              <button class="btn-icon" @click="handlePageUp">
                <i class="fa-solid fa-chevrons-up text-white"></i>
              </button>
              <div class="text-white">
                {{ `${pageNumber + 1} of ${pages.length}` }}
              </div>

              <button class="btn-icon" @click="handlePageDown">
                <i class="fa-solid fa-chevrons-down text-white"></i>
              </button>
            </div>
          </a>
        </li>

        <!-- <li>
          <a href="#">
            <input
              @click="[(search = ''), filterEmployeesSchedule()]"
              class="mx-3 text-align-center w-24 h-8 rounded-md border-gray-500"
              v-model="search"
              @input="filterEmployeesSchedule()"
              type="text"
              maxlength="6"
            />
          </a>
        </li> -->

        <li v-if="assignmentData" @click="handleRemoveTarget" class="icon">
          <a href="#">
            <span class="tooltip">Hide Assignment Shadow</span>
            <span><i class="fa-solid fa-square text-blue-500"></i></span>
          </a>
        </li>

        <!-- <li @click="handleToggleBoardStyle" class="icon">
          <a href="#">
            <span class="tooltip">Switch Board</span>
            <span><i class="fa-solid fa-bolt text-yellow-500"></i></span>
          </a>
        </li> -->

        <li @click="zoomIn" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom In</span>
            <span><i class="fa-regular fa-magnifying-glass-plus"></i></span>
          </a>
        </li>
        <li @click="zoomOut" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom Out</span>
            <span><i class="fa-regular fa-magnifying-glass-minus"></i></span>
          </a>
        </li>
        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>
      </ul>
    </nav>
    <!-- #endregion -->

    <!-- SNACKBAR -->
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- DATE FILTER DIALOG -->
    <r-modal v-if="showDateFilterDialog" @close="showDateFilterDialog = false">
      <div v-if="showDateFilterDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Date Filter</div>
          <button @click="showDateFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex">
          <div>
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Start Date</label
            >

            <input
              class="block w-32 rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="startDate"
              type="date"
              id="date-filter"
            />
          </div>

          <div class="ml-3">
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Number of Days</label
            >

            <select
              class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="numberOfDays"
            >
              <option
                v-for="option in numberOfDaysOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleSaveDateFilter"
            class="btn bg-blue-500 text-white"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </r-modal>

    <!-- FLIGHT ASSIGNMENT DIALOG -->
    <r-modal
      v-if="showFlightAssignmentDialog"
      @close="handleCloseFlightAssignmentDialog"
    >
      <div v-if="showFlightAssignmentDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">
            {{
              `${employee.surname}, ${employee.givenName} (${employee.companyId.number}) | ${employee.jobTitle} `
            }}
          </div>
          <button class="btn-icon" @click="handleCloseFlightAssignmentDialog">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="grid-row-six">
          <!-- Gray Line -->
          <div class="w-full h-px bg-gray-400 mb-4"></div>

          <div class="overflow-x-auto max-h-[50vh]">
            <div class="table-auto shadow-md sm:rounded-lg">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                <thead
                  class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400 sticky top-0"
                >
                  <tr>
                    <th scope="col" class="px-4 py-3 w-[20px]">Seat</th>
                    <th scope="col" class="px-6 py-3 w-[100px]">Duty</th>
                    <th scope="col" class="px-6 py-3">Employee</th>
                    <th scope="col" class="px-6 py-3 w-[50px]">Required</th>
                    <th scope="col" class="px-6 py-3 w-[50px]">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in assignmentData.flight.crewmembers"
                    :key="item.seat"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer"
                    :style="getRowStyle(item)"
                    @click="handleAssignEmployee(item)"
                  >
                    <td
                      scope="row"
                      class="px-3 py-1.5 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {{ item.seat }}
                    </td>
                    <td
                      scope="row"
                      class="px-3 py-1.5 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {{ item.duty }}
                    </td>
                    <td
                      scope="row"
                      class="px-3 py-1.5 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {{ formatCrewmember(item) }}
                    </td>
                    <td
                      scope="row"
                      class="px-3 py-1.5 font-medium text-gray-900 text-center"
                    >
                      {{ item.required ? "Yes" : "No" }}
                    </td>
                    <td v-if="item.employee" class="px-3 py-1.5 text-center">
                      <i
                        class="fa-solid fa-trash-can cursor-pointer"
                        @click="handleRemoveEmployee(item, $event)"
                      ></i>
                    </td>
                    <td v-else class="px-3 py-1.5 text-center"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- <div class="flex justify-end mt-1 px-2">
          <button
            class="btn bg-gray-500 text-white"
            @click="handleCloseFlightAssignmentDialog"
          >
            Cancel
          </button>
         
        </div> -->
      </div>
    </r-modal>

    <r-spinner v-if="loading"> </r-spinner>

    <div class="board" ref="board">
      <!-- LEFT PANEL -->
      <!-- Each page has a list of employees -->
      <div v-if="boardStyle === 'Board' && page" class="left-panel">
        <div class="left-panel-header bg-gray-500-l2">
          <div class="text-base flex justify-center employees-center w-[90px]">
            Employees
          </div>
        </div>
        <div
          class="left-panel-row bg-gray-500-l4"
          v-for="item in page.items"
          :key="item.employee._id"
          :style="{ height: `${rowHeight}px` }"
        >
          <div class="text-base text-align text-bold text-truncate">
            {{
              `${item.employee.surname}, ${item.employee.givenName.substring(
                0,
                1
              )}`
            }}
          </div>
          <div class="text-[10px] text-truncate">
            {{ `${item.employee.jobTitle} ` }}
          </div>
          <div class="text-xs text-truncate">
            {{ `${item.employee.companyId.number}` }}
          </div>
        </div>
      </div>
      <!-- RIGHT PANEL -->
      <div
        v-if="boardStyle === 'Board'"
        class="right-panel-container"
        ref="scrollContainer"
      >
        <div v-if="page" class="right-panel">
          <!-- Header -->
          <div
            class="right-panel-header bg-gray-500-l4 cursor-pointer"
            @click="handleOpenDateFilter(item)"
            v-for="(item, index) in dateFilter.days"
            :key="index"
            :style="{
              top: 0,
              left: `${(1440 / scale) * index}px`,
              width: `${dayWidth}px`,
            }"
          >
            {{ formatHeaderDate(item) }}
          </div>

          <!-- Day Lines -->
          <div
            class="day-line"
            v-for="(day, index) in dateFilter.days"
            :key="day"
            :style="{
              left: `${(1440 / scale) * index}px`,
              height: `${availableBoardHeight + 50}px`,
            }"
          ></div>

          <!-- Hour Boxes -->
          <div
            class="hour-box text-[10px]"
            :class="{ hidden: marker.value === '00' || scale > 4 }"
            v-for="(marker, index) in dateFilter.hourBoxes"
            :key="marker.key"
            :style="{ left: `${(60 / scale) * index}px` }"
          >
            {{ marker.value }}
          </div>

          <!-- Hour Lines -->
          <div
            class="hour-line"
            :class="{ hidden: marker.value === '00' || scale > 6 }"
            v-for="(marker, index) in dateFilter.hourLines"
            :key="marker.key"
            :style="{
              left: `${(60 / scale) * index}px`,
              height: `${availableBoardHeight + 7}px`,
            }"
          ></div>

          <!-- Now Box -->
          <div
            class="now-box"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
            }"
          >
            {{ formatTime(new Date()) }}
          </div>

          <!-- Now Line -->
          <div
            class="now-line"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
              height: `${availableBoardHeight + 7}px`,
            }"
          ></div>

          <!-- Employees -->
          <div
            class="right-panel-row"
            v-for="(item, index) in page.items"
            :key="item.employee._id"
            :style="{
              top: `${index * rowHeight + 50}px`,
              width: `${boardWidth}px`,
              height: `${rowHeight}px`,
            }"
          >
            <!-- Assignment Shadow  -->
            <div
              v-if="assignmentData"
              class="assignment-shadow"
              @click="handleShowFlightAssignmentDialog(item.employee)"
              :style="computedAssignmentShadow"
            >
              <div class="text-xs text-center">
                {{ shadowText }}
              </div>
            </div>
            <!--Assignments-->
            <div
              v-for="_assignment in item.employee.flightAssignments"
              :key="_assignment._id"
              class="board-label-frame"
              :style="{
                width: `${_assignment.duration.totalMinutes / scale}px`,
                left: `${getLocationLeft(
                  _assignment._start,
                  dateFilter.days[0],
                  scale
                )}px`,
              }"
            >
              <div>
                <FlightAssignmentLabel :assignment="_assignment" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import FlightAssignmentLabel from "../components/FlightAssignmentLabel.vue";

import EmployeeInformation from "../components/EmployeeInformation.vue";

import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Switch,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "assignmentBoard",
  components: {
    EmployeeInformation,
    FlightAssignmentLabel,

    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    Switch,
  },
  data() {
    return {
      loading: false,

      //---------------------------------------Dialogs
      showFlightAssignmentDialog: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      sortedAssignments: [],

      //#region-----------------------------------Paginate
      availableBoardHeight: 0,
      schedule: null,
      itemsPerPage: 0,
      pages: [],
      page: null,
      rowHeight: 60,
      disableNextPageButton: true,
      disablePreviousPageButton: true,
      //#endregion

      //----------------------------------------Board
      boardStyle: "Board",
      scale: 4,

      // assignmentsListDialog: false,
      originIata: "", //Used to sort station assignment flights
      paddingBefore: 30,
      paddingAfter: 30,

      //------------------------------------------Employee
      employeeDialog: false,
      showEmployeeInformationDialog: false,
      employee: null,
      workMonth: null,

      //------------------------------------------Filters and Settings

      search: "",

      showDateFilterDialog: false,
      startDate: null,
      numberOfDays: 7,
      numberOfDaysOptions: [3, 5, 7, 14, 21, 31],
    };
  },
  mixins: [mixin],
  created() {
    this.getData();
  },

  // mounted() {
  //   this.$nextTick(() => {
  //     const board = this.$refs.board;

  //     this.availableBoardHeight = board.clientHeight - 120;

  //     this.itemsPerPage = Math.floor(
  //       this.availableBoardHeight / this.rowHeight
  //     );
  //   });
  // },

  mounted() {
    this.$nextTick(() => {
      const board = this.$refs.board;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );
    });

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    assignmentData() {
      return this.$store.getters.assignmentData;
    },

    shadowText() {
      return `${this.assignmentData.flight.aircraft.registration} . ${this.assignmentData.flight.flightNumber} . ${this.assignmentData.flight.origin.iata} - ${this.assignmentData.flight.destination.iata}`;
    },

    pageNumber() {
      return this.$store.getters.assignmentBoardPageNumber;
    },

    showCurrentTime() {
      const now = new Date().getTime();

      return (
        now > new Date(this.dateFilter.start).getTime() &&
        now < new Date(this.dateFilter.end).getTime()
      );
    },

    nowLineLeft() {
      const x = this.getLocationLeft(
        new Date().toISOString(),
        this.dateFilter.start,
        this.scale
      );

      if (x > 0 || x < this.boardWidth) {
        return x;
      } else {
        return 0;
      }
    },

    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale) + 100;
    },

    dayWidth() {
      return 1440 / this.scale;
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    computedAssignmentShadow() {
      if (this.assignmentData) {
        const minutes =
          (new Date(this.assignmentData.flight._start).getTime() -
            new Date(this.dateFilter.days[0]).getTime()) /
          1000 /
          60;

        const width =
          (this.paddingBefore +
            this.assignmentData.flight.duration.totalMinutes +
            this.paddingAfter) /
          this.scale;
        const left = (minutes - this.paddingBefore) / this.scale;

        let style = {
          width: `${width}px`,
          left: `${left}px`,
          height: "60px",
          borderLeft: "1px dashed blue",
          borderRight: "1px dashed blue",
          backgroundColor: "#a8a8f5b2",
          visibility: "visible",
          opacity: 0.5,
          "z-index": 200,
        };

        return style;
      } else {
        return {
          width: "1px",
          left: "1px",
          height: "1px",
          visibility: "hidden",
        };
      }
    },

    auth() {
      return this.$store.getters.auth;
    },
  },
  methods: {
    async handleAssignEmployee(assignment) {
      //TODO warning if another employee is already assigned to this seat

      try {
        const token = this.auth.token;
        const _id = this.assignmentData.flight._id;

        const employee = {
          _id: this.employee._id,
          baseIata: this.employee.baseIata,
          companyId: this.employee.companyId,
          givenName: this.employee.givenName,
          surname: this.employee.surname,
          jobTitle: this.employee.jobTitle,
        };

        let res = await api.put(
          `/aircraft-events/${_id}/crewmembers/precheck/assign`,
          {
            assignment,
            employee,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        const conflicts = res.data.conflicts;

        if (conflicts.length === 0) {
          let res = await api.put(
            `/aircraft-events/${_id}/crewmembers/assign`,
            {
              assignment,
              employee,
            },
            {
              headers: { Authorization: "Bearer " + token },
            }
          );

          if (res.status !== 200) {
            this.snackbarColor = "red";
            this.snackbarText = res.message;
            this.snackbarVisible = true;

            setTimeout(() => {
              this.snackbarVisible = false;
            }, 3000);
          }

          let assignmentData = { ...this.assignmentData };

          assignmentData.flight.crewmembers = res.data.crewmembers;
          this.$store.commit("updateAssignmentData", assignmentData);
        } else {
          this.snackbarColor = "red";
          this.snackbarText = `Unable to assign due to conlicts.`;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.snackbarVisible = false;
          }, 3000);
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleRemoveEmployee(assignment, e) {
      e.stopPropagation();
      try {
        const token = this.auth.token;
        const _id = this.assignmentData.flight._id;

        const res = await api.put(
          `/aircraft-events/${_id}/crewmembers/remove`,
          {
            assignment,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.snackbarVisible = false;
          }, 3000);
        }

        let assignmentData = { ...this.assignmentData };

        assignmentData.flight.crewmembers = res.data.crewmembers;
        this.$store.commit("updateAssignmentData", assignmentData);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    getRowStyle(item) {
      let style = {
        backgroundColor: "#6EE7B7",
      };

      if (item.required && !item.employee) {
        style.backgroundColor = "#fca5a5";
      } else {
        if (!item.required) {
          style.backgroundColor = "#93C5FD";
        }
      }

      return style;
    },
    formatCrewmember(crewmember) {
      if (crewmember.employee) {
        return `${crewmember.employee.surname}, ${crewmember.employee.givenName}`;
      } else {
        if (crewmember.required) {
          return "Please Assign.";
        } else {
          return "Not Required";
        }
      }
    },

    async getData() {
      this.loading = true;

      this.schedule = null;
      this.pages = [];
      this.page = null;

      try {
        const token = this.auth.token;

        const res = await api.get("/assignments", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            _start: this.dateFilter.start,
            _end: this.dateFilter.end,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.schedule = res.data.schedule;
        this.paginate();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    paginate() {
      this.pages = [];
      let _pages = [];
      this.page = null;

      if (this.schedule) {
        let chunk = Math.min(
          Math.floor(this.availableBoardHeight / this.rowHeight),
          this.schedule.length
        );

        //Show warning if not enough space to display
        if (chunk < 1) {
          this.snackbarColor = "red";
          this.snackbarText = "Not enough screen space to display data";
          this.snackbarVisible = true;
          return;
        }

        //Split data into pages
        for (let i = 0; i < this.schedule.length; i += chunk) {
          let tempArray;
          tempArray = this.schedule.slice(i, i + chunk);
          _pages.push({
            index: _pages.length,
            items: tempArray,
          });
        }

        this.pages = [..._pages];

        const i = this.pages.findIndex((page) => {
          return page.index === this.pageNumber;
        });

        if (i < 0) {
          this.$store.commit("updateAssignmentBoardPageNumber", 0);
        }

        this.page = this.pages[this.pageNumber];
      }
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.sortedAssignments, key);

      if (sortedAsc) {
        this.sortedAssignments.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.sortedAssignments.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    onResize() {
      const board = this.$refs.board;

      if (!board) return;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );

      this.paginate();
    },

    handlePageUp() {
      if (this.pageNumber > 0) {
        const val = this.pageNumber - 1;
        this.$store.commit("updateAssignmentBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handlePageDown() {
      if (this.pageNumber < this.pages.length - 1) {
        const val = this.pageNumber + 1;
        this.$store.commit("updateAssignmentBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    handleNavigateToHome() {
      this.$router.go({ name: "home" });
    },

    handleShowFlightTooltip(assignment, index) {
      this.tooltip = { ...assignment };

      this.tooltipStyle = {
        display: "block",
        top: `${index * this.rowHeight + 50 + 45}px`,
        left: `${
          this.getLocationLeft(
            assignment.startTime,
            this.dateFilter.days[0],
            this.scale
          ) + 30
        }px`,
      };
    },

    handleHideFlightTooltip() {
      setTimeout(() => {
        this.tooltipStyle = {
          display: "none",
        };
      }, 150);
    },

    handleNavigateToEmployeeProfile() {
      this.$router.push({
        name: "employeeUpdate",
        params: { id: this.employee._id },
      });
    },

    zoomIn() {
      if (this.scale > 1) {
        this.scale -= 1;
      }
    },

    zoomOut() {
      if (this.scale < 8) {
        this.scale += 1;
      }
    },

    handleToggleBoardStyle() {
      if (this.boardStyle === "Board") {
        this.boardStyle = "List";
      } else {
        this.boardStyle = "Board";
      }
    },

    handleRemoveTarget() {
      this.$store.commit("updateAssignmentData", null);
    },

    //-------------------------------------Filters and Settings
    handleOpenDateFilter(item) {
      this.startDate = item.substring(0, 10);
      this.numberOfDays = this.dateFilter.days.length;

      this.showDateFilterDialog = true;
    },

    handleSaveDateFilter() {
      const filterStart = new Date(this.startDate).getTime();

      this.showDateFilterDialog = false;

      //Pass filterStart in miliseconds, numberOfDays as integer
      this.$store.commit("updateDateFilter", {
        filterStart,
        numberOfDays: this.numberOfDays,
      });

      this.getData();
    },

    //--------------------------Employee Information
    handleShowEmployeeInformationDialog(employee) {
      if (this.canReadEmployeeContactInformation) {
        this.employee = { ...employee };

        this.showEmployeeInformationDialog = true;
      }
    },

    handleShowFlightAssignmentDialog(item) {
      this.employee = { ...item };

      this.showFlightAssignmentDialog = true;
    },

    handleCloseFlightAssignmentDialog() {
      this.showFlightAssignmentDialog = false;
      this.employee = null;
      this.getData();
    },

    //------------------------Formatters and Stylers

    formatDate(miliseconds) {
      return new Date(miliseconds).toISOString().substring(0, 10);
    },

    formatDateShort(miliseconds) {
      const date = new Date(miliseconds);

      let options = {
        timeZone: "UTC",
        month: "short",
        day: "numeric",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    formatTime(miliseconds) {
      return new Date(miliseconds).toISOString().substring(11, 16);
    },

    formatDateTime(dateTimeString) {
      const date = new Date(dateTimeString);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    formatAssignment(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatDateTime(assignment.endTime);

      switch (assignment.type) {
        case "Flight":
          return `${assignment.role} . ${assignment.flightNumber} . ${assignment.aircraftRegistration} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z - ${timeIn} Z`;

        case "Travel":
          return `${assignment.type} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z - ${timeIn} Z`;
        case "Station":
          return `Station Assignment . ${assignment.originIata} . ${timeOut} Z - ${timeIn} Z`;

        default:
          return `${assignment.type} . ${assignment.originIata} . ${timeOut} Z - ${timeIn} Z`;
      }
    },

    formatHeaderDate(string) {
      const date = new Date(string);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    getBidDayStyle(bidDay) {
      //Default color "DO" - Day Off
      let backgroundColor = "#E74C3C";

      switch (bidDay.code) {
        case "RES":
          backgroundColor = "#2ECC71";
          break;
        case "VAC":
          backgroundColor = "#A569BD";
          break;
        default:
          break;
      }

      const x =
        (new Date(Date.UTC(bidDay.year, bidDay.month, bidDay.day)).getTime() -
          new Date(this.dateFilter.days[0]).getTime()) /
        1000 /
        60 /
        this.scale;

      const style = {
        top: 0,
        left: `${x}px`,
        height: `${this.rowHeight}px`,
        width: `${this.dayWidth}px`,
        backgroundColor,
      };

      return style;
    },
  },
};
</script>

<style scoped>
.flight-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  min-width: 250px;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(128, 128, 128, 0.9);
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  z-index: 700;
}

.right-panel-row {
  position: absolute;
  top: 50px;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 1px solid gray;
  background-color: white;
  overflow: hidden;
}

.right-panel-row:nth-child(odd) {
  background-color: rgb(236, 225, 225);
}

section {
  padding: 0 1rem;
  min-height: 10rem;
  max-height: 26rem;
  overflow-y: auto;
}

.warning-tile {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px 10px;
  color: black;
  margin-bottom: 3px;
}

.fa-user:hover {
  color: #3b82f6;
}
</style>
