<template>
  <div>
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">
              Administration
            </div>
          </a>
        </li>

        <li>
          <a href="#">
            <input
              @click="[(search = ''), handleFilterEmployees()]"
              class="mx-3 text-align-center w-24 h-8 rounded-md border-gray-500"
              v-model="search"
              @input="handleFilterEmployees()"
              type="text"
              maxlength="6"
              placeholder="Name"
            />
          </a>
        </li>

        <!-- <li class="icon" @click="showNewEmployeeDialog = true">
          <a href="#">
            <span class="tooltip">Add Employee</span>
            <span><i class="fa-solid fa-user-plus text-blue-500"></i></span>
          </a>
        </li> -->

        <li @click="handleRefresh" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate ml-2"></i></span>
          </a>
        </li>
      </ul>
    </nav>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>
    <div v-else>
      <!-- Data Table -->
      <div class="overflow-x-auto max-h-[90vh]">
        <table class="table-auto border-collapse border border-gray-300 w-full">
          <thead class="bg-gray-200 sticky top-0">
            <tr>
              <th
                class="border border-gray-300 px-4 py-2"
                @click="sortArrayByKey('surname')"
              >
                Last Name
              </th>
              <th
                class="border border-gray-300 px-4 py-2"
                @click="sortArrayByKey('givenName')"
              >
                First Name
              </th>
              <th
                class="border border-gray-300 px-4 py-2"
                @click="sortArrayByKey('jobTitle')"
              >
                Job Title
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="employee in employees"
              :key="employee._id"
              @click="handleSelectEmployee()"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <td class="border border-gray-300 px-4 py-2">
                {{ employee.surname }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ employee.givenName }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ employee.jobTitle }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Data Table -->
      <!-- <div class="relative overflow-x-auto">
        <table
          class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
        >
          <thead
            class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400 sticky top-0"
          >
            <tr>
              <th
                scope="col"
                class="px-6 py-2 cursor-pointer"
                @click="sortArrayByKey('surname')"
              >
                Last Name
              </th>
              <th
                scope="col"
                class="px-6 py-2 cursor-pointer"
                @click="sortArrayByKey('givenName')"
              >
                First Name
              </th>

              <th
                scope="col"
                class="px-6 py-2 cursor-pointer"
                @click="sortArrayByKey('jobTitle')"
              >
                Job Title
              </th>
              <th
                scope="col"
                class="px-6 py-2 cursor-pointer"
                @click="sortArrayByKey('securityGroup')"
              >
                Security Group
              </th>
              <th
                scope="col"
                class="px-6 py-2 cursor-pointer"
                @click="sortArrayByKey('baseIata')"
              >
                Base
              </th>
              <th
                scope="col"
                class="px-6 py-2 cursor-pointer"
                @click="sortArrayByKey('primaryPhone')"
              >
                Phone
              </th>
              <th
                scope="col"
                class="px-6 py-2 cursor-pointer"
                @click="sortArrayByKey('mail')"
              >
                Email
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="employee in employees"
              :key="employee._id"
              @click="handleSelectEmployee()"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <td
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
               
              </td>
              <td>{{ employee.givenName }}</td>
              <td class="px-6 py-2">{{ employee.jobTitle }}</td>
              <td class="px-6 py-2">{{ employee.securityGroup.name }}</td>
              <td class="px-6 py-2">{{ employee.baseIata }}</td>
              <td class="px-6 py-2">{{ employee.primaryPhone }}</td>
              <td class="px-6 py-2">{{ employee.mail }}</td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "administration",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },

  data() {
    return {
      loading: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      employees: [],
      search: "",
    };
  },

  created() {
    this.employees = [...this.allEmployees];
  },

  computed: {
    allEmployees() {
      return this.$store.getters.employees;
    },
  },

  methods: {
    handleSelectEmployee() {
      this.snackbarColor = "orange";
      this.snackbarText = "Under Development";
      this.snackbarVisible = true;

      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },

    handleFilterEmployees() {
      this.employees = this.allEmployees.filter((employee) => {
        return (
          employee.surname
            .toUpperCase()
            .startsWith(this.search.toUpperCase()) ||
          employee.givenName.toUpperCase().startsWith(this.search.toUpperCase())
        );
      });
    },

    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.employees, key);

      if (sortedAsc) {
        this.employees.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.employees.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    handleOpenFilters() {
      if (!this.selectedCompany) {
        this.selectedCompany = this.user.companyName.slice();
      }

      if (this.companyFilter.active) {
        this.filterByCompany = true;
        this.selectedCompany = this.companyFilter.name.slice();
      }

      if (this.user.jobTitlesFilter.active) {
        this.filterByJobTitle = true;
        this.selectedJobTitles = [...this.user.jobTitlesFilter.jobTitles];
      } else {
        this.filterByJobTitle = false;
        this.selectedJobTitles = [];
      }

      this.showFiltersDialog = true;
    },

    handleSaveFilters() {
      this.showFiltersDialog = false;

      //------------------------------------Airport Filter
      let assignmentsAirportFilter = {
        active: false,
        match: "",
        iataCode: "",
      };

      if (this.filterByAirport) {
        if (this.selectedIataCode?.length === 3) {
          const i = this.airports.findIndex((item) => {
            return item.iata === this.selectedIataCode.toUpperCase();
          });

          if (i >= 0) {
            assignmentsAirportFilter = {
              active: true,
              match: this.selectedEmployeeStatus.slice(),
              iataCode: this.selectedIataCode.slice().toUpperCase(),
            };
          } else {
            this.filterByAirport = false;
            this.selectedIataCode = null;
          }
        } else {
          this.filterByAirport = false;
        }
      }

      this.$store.commit(
        "updateAssignmentsAirportFilter",
        assignmentsAirportFilter
      );

      //-----------------------------Company Filter
      let companyFilter = {
        active: false,
        name: "",
      };

      if (this.filterByCompany && this.selectedCompany) {
        companyFilter = {
          active: true,
          name: this.selectedCompany.slice(),
        };
      }

      this.$store.commit("updateCompanyFilter", companyFilter);

      //------------------------------Job Titles Filter
      let jobTitlesFilter = {
        active: false,
        jobTitles: [],
      };

      if (this.filterByJobTitle && this.selectedJobTitles.length) {
        jobTitlesFilter = {
          active: true,
          jobTitles: [...this.selectedJobTitles],
        };
      }

      this.$store.commit("updateJobTitlesFilter", jobTitlesFilter);

      this.getData();
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    async handleRefresh() {
      try {
        this.loading = true;

        const res = await this.$store.dispatch("getEmployees", "Active");

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        const allEmployees = res.data.employees;
        this.employees = [...allEmployees];

        this.$store.commit("updateEmployees", allEmployees);
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
      }
    },

    formatDateShort(miliseconds) {
      const date = new Date(miliseconds);

      let options = {
        timeZone: "UTC",
        month: "short",
        day: "numeric",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
  },
};
</script>

<style scoped>
.fa-chevron-left {
  font-size: 1.3rem;
  color: white;
}
</style>
