<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="ml-3 text-2xl text-white">Manuals</div>
          </a>
        </li>

        <li>
          <a href="#"></a>
        </li>
      </ul>
    </nav>

    <div class="p-5">
      <!-- Basic -->

      <div
        class="border border-gray-500 rounded-md p-5 mb-2 cursor-pointer hover:bg-gray-100"
        :class="{ disabled: loading }"
        @click="getBasicManual"
      >
        <div>
          <i class="fa-solid fa-book text-green-500"></i>
          <span class="text-xl ml-3"> Basic User Manual </span>
        </div>
      </div>

      <!-- Advanced -->
      <div
        class="border border-gray-500 rounded-md p-5 mb-2 cursor-pointer hover:bg-gray-100"
        :class="{ disabled: loading }"
        @click="getAdminManual"
      >
        <div>
          <i class="fa-solid fa-book text-red-500"></i>
          <span class="text-xl ml-3"> Administrator Manual </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PDFDocument } from "pdf-lib";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";

export default {
  name: "about",
  mixins: [mixin],
  data() {
    return {
      loading: false,
      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
    };
  },

  components: { RSnackbar, RSpinner },
  methods: {
    handleGoBack() {
      this.$router.go(-1);
    },
    async getBasicManual() {
      this.loading = true;
      try {
        const url =
          "https://s3.amazonaws.com/flighttimes.waa.com.resources/manuals/user_manual.pdf";

        const existingPdfBytes = await fetch(url).then((res) =>
          res.arrayBuffer()
        );

        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        pdfDoc.setTitle("Flight Times User Manual");

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );

        this.loading = false;
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;

        this.snackbarColor = "red";
        this.snackbarText = error.message;
        this.snackbarVisible = true;
        return;
      }
    },

    async getAdminManual() {
      this.loading = true;
      try {
        const url =
          "https://s3.amazonaws.com/flighttimes.waa.com.resources/manuals/admin_manual.pdf";

        const existingPdfBytes = await fetch(url).then((res) =>
          res.arrayBuffer()
        );

        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        pdfDoc.setTitle("Flight Times Admin Manual");

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );

        this.loading = false;

        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error.message;
        this.snackbarVisible = true;
        return;
      }
    },
  },
};
</script>
