<template>
  <div>
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">Planning</div>
          </a>
        </li>

        <!-- <li @click="handleOpenFilters" class="icon">
          <a href="#">
            <span class="tooltip">Employee Filters</span>
            <span><i class="fa-solid fa-bars-filter text-yellow-500"></i></span>
          </a>
        </li> -->

        <!-- <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate ml-2"></i></span>
          </a>
        </li> -->
        <li>
          <a href="#"> </a>
        </li>
      </ul>
    </nav>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <div v-else>
      <div class="px-5 py-1.5 border-b" :class="{ 'edit-mode': tempId }">
        <div class="flex gap-3 flex-wrap">
          <div>
            <p class="ml-2 text-sm text-gray-500">Type</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedType"
            >
              <option
                v-for="(_type, index) in eventTypes"
                :key="index"
                :value="_type"
              >
                {{ _type }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Customer</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedCustomer"
            >
              <option
                v-for="item in customerOptions"
                :key="item._id"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Aircraft</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="selectedAircraft"
              :disabled="aircraftEvents.length"
            >
              <option
                v-for="item in aircraftOptions"
                :key="item._id"
                :value="item"
              >
                {{ item.registration }}
              </option>
            </select>
          </div>

          <div v-if="selectedType === 'Flight'">
            <p class="ml-2 text-sm text-gray-500">Flight Number</p>

            <input
              class="block rounded-md border-0 max-w-[100px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !isValidCallsign }"
              v-model="flightNumber"
              type="text"
              maxlength="10"
              name="flightNumber"
              id="flightNumber"
              placeholder="Flight No."
            />
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">From</p>

            <input
              class="block rounded-md border-0 max-w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !computedOrigin }"
              v-model="originIata"
              type="text"
              maxlength="3"
              name="originIata"
              id="originIata"
              placeholder="IATA"
            />
          </div>

          <div v-if="selectedType === 'Flight'">
            <p class="ml-2 text-sm text-gray-500">To</p>
            <input
              class="block rounded-md border-0 max-w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !computedDestination }"
              v-model="destinationIata"
              type="text"
              maxlength="3"
              name="destinationIata"
              id="destinationIata"
              placeholder="IATA"
            />
          </div>

          <div>
            <div class="flex">
              <div>
                <p class="ml-2 text-sm text-gray-500">Start Date</p>
                <input
                  class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
                  v-model="startDateString"
                  type="date"
                />
              </div>
              <div>
                <p class="ml-2 text-sm text-gray-500">Start Time</p>
                <input
                  class="ml-1 block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
                  v-model="startTimeString"
                  type="time"
                />
              </div>
            </div>
          </div>

          <div>
            <div class="flex gap-2">
              <div v-if="selectedType !== 'Flight'">
                <p class="ml-2 text-sm text-gray-500">Days</p>
                <input
                  class="block w-16 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  :class="{ 'is-invalid': !computedDuration.isValid }"
                  type="number"
                  step="1"
                  min="0"
                  max="180"
                  v-model="days"
                />
              </div>
              <div>
                <p class="ml-2 text-sm text-gray-500">Hours</p>
                <input
                  class="block w-16 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  :class="{ 'is-invalid': !computedDuration.isValid }"
                  type="number"
                  step="1"
                  min="0"
                  max="23"
                  v-model="hours"
                />
              </div>

              <div>
                <p class="ml-2 text-sm text-gray-500">Minutes</p>
                <input
                  class="block w-16 py-1.5 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  :class="{ 'is-invalid': !computedDuration.isValid }"
                  type="number"
                  step="1"
                  min="0"
                  max="59"
                  v-model="minutes"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <p class="ml-2 text-sm text-gray-500">Remarks</p>
          <div>
            <textarea
              v-model="remarks"
              rows="2"
              maxlength="400"
              siz
              name="remarks"
              id="remarks"
              class="block w-full rounded-md border-0 py-0.5 px-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
            />
          </div>
        </div>

        <div class="flex justify-between">
          <div v-if="selectedType === 'Flight'">
            <p class="ml-5 text-sm text-gray-500">Hidden</p>

            <div class="flex mb-2 items-center py-1">
              <SwitchGroup as="div" class="flex items-center ml-3">
                <Switch
                  v-model="hidden"
                  :class="[
                    hidden ? 'bg-red-500' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      hidden ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>

              <div class="text-sm text-gray-400 ml-3">
                Flight will not appear on public searches.
              </div>
            </div>
          </div>

          <button
            @click="handleAddAircraftEvent"
            class="ml-1 mt-7 btn-small bg-blue-500 text-white"
            :class="{ disabled: !isValid }"
          >
            Add
          </button>
        </div>
      </div>
      <div class="aircraft-events-table">
        <div v-if="aircraftEvents.length">
          <div
            class="grid-card"
            v-for="_aircraftEvent in aircraftEvents"
            :key="_aircraftEvent.tempId"
            :class="{
              'aircraft-operational': _aircraftEvent.type !== 'Flight',
            }"
            @click="handleEditAircraftEvent(_aircraftEvent)"
          >
            <div class="flex justify-between items-center flex-wrap">
              <div class="flex items-center">
                <div>
                  <div v-if="_aircraftEvent.type === 'Flight'">
                    <span>
                      {{
                        `${_aircraftEvent.aircraft.registration.toUpperCase()} | `
                      }}</span
                    >
                    <span>
                      {{
                        `${_aircraftEvent.flightNumber.toUpperCase()} | `
                      }}</span
                    >
                    <span>
                      {{ `${formatDate(_aircraftEvent._start)} | ` }}
                    </span>

                    <span>
                      {{
                        `${_aircraftEvent.customer.name.toUpperCase()}`
                      }}</span
                    >
                    <span class="ml-2 text-red-500">
                      {{ `${_aircraftEvent.hidden ? "(Hidden)" : ""}` }}
                    </span>
                  </div>
                  <div v-else>
                    <span>
                      {{
                        `${_aircraftEvent.aircraft.registration.toUpperCase()} | `
                      }}</span
                    >
                    <span> {{ `${_aircraftEvent.type} | ` }}</span>

                    <span>
                      {{
                        `${_aircraftEvent.customer.name.toUpperCase()} `
                      }}</span
                    >
                  </div>
                </div>
              </div>

              <div class="flex items-center">
                <div class="text-xl font-bold text-blue-700">
                  {{ `${_aircraftEvent.duration.text}` }}
                </div>

                <button
                  @click="
                    handleDeleteAircraftEvent(_aircraftEvent.tempId, $event)
                  "
                  class="btn-icon ml-3"
                >
                  <i class="fa-solid fa-trash-can"></i>
                </button>
              </div>
            </div>
            <div class="flex justify-between flex-wrap">
              <div>
                <span class="text-xl font-bold mr-3">
                  {{ _aircraftEvent.origin.iata }}
                </span>
                <span>
                  {{ `${formatDateShort(_aircraftEvent._start)} Z` }}
                </span>
              </div>

              <div>
                <span
                  class="text-xl font-bold mr-3"
                  v-if="_aircraftEvent.type === 'Flight'"
                >
                  {{ _aircraftEvent.destination.iata }}
                </span>
                <span> {{ `${formatDateShort(_aircraftEvent._end)} Z` }}</span>
              </div>
            </div>
            <div v-if="_aircraftEvent.remarks" class="text-sm text-gray-500">
              {{ _aircraftEvent.remarks }}
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="flex justify-end mx-4">
          <div class="flex justify-end">
            <div v-if="aircraftEvents.length" class="flex">
              <div class="flex items-center py-1 mr-5">
                <SwitchGroup as="div" class="flex items-center">
                  <Switch
                    v-model="goToFlightBoard"
                    :class="[
                      goToFlightBoard ? 'bg-blue-500' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                    ]"
                  >
                    <span
                      aria-hidden="true"
                      :class="[
                        goToFlightBoard ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                      ]"
                    />
                  </Switch>
                </SwitchGroup>

                <div class="text-sm text-gray-400 ml-3">
                  Open Flight Flight Board after saving.
                </div>
              </div>
              <button
                @click="handleSaveAircraftEvents"
                class="btn-small bg-green-500 text-white"
              >
                Save Schedule
              </button>

              <button
                @click="handleGoBack"
                class="ml-1 btn-small bg-gray-500 text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import { nanoid } from "nanoid";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Switch,
  SwitchGroup,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "planning",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    Switch,
    SwitchGroup,
  },

  data() {
    return {
      loading: false,
      goToFlightBoard: true,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //---------------------Events
      eventTypes: ["Flight", "Aircraft Operational"],

      //----------------------Event List
      aircraftEvents: [],

      //-----------------------Aircraft Event
      tempId: null,
      selectedType: null,
      selectedStatus: null,
      selectedCustomer: null,
      selectedAircraft: null,
      flightNumber: "",
      hidden: false,
      originIata: "",
      destinationIata: "",
      startDateString: "",
      startTimeString: "",
      startMillis: 0,
      endMillis: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      remarks: "",
      minimumDuration: 20,
      maximumDuration: 8 * 60, // 8 hours - Applies to Aircraft Operational
      previousEnd: null,
      previousDestinationIata: "",
    };
  },

  created() {
    this.selectedType = "Flight";

    let i = this.customerOptions.findIndex((c) => {
      return c.name === "WORLD ATLANTIC AIRLINES";
    });

    if (i >= 0) {
      this.selectedCustomer = { ...this.customerOptions[i] };
    } else {
      this.selectedCustomer = { ...this.customerOptions[0] };
    }

    this.flightNumber = `${this.selectedCustomer.callsign}-`;

    i = this.aircraftOptions.findIndex((a) => {
      return a.registration === "N801WA";
    });

    if (i >= 0) {
      this.selectedAircraft = { ...this.aircraftOptions[i] };
    } else {
      this.selectedAircraft = { ...this.aircraftOptions[0] };
    }

    //--------------------------------------Start Date and Time
    this.startMillis = new Date().getTime();

    this.startDateString = new Date(this.startMillis)
      .toISOString()
      .substring(0, 10);
    this.startTimeString = new Date(this.startMillis)
      .toISOString()
      .substring(11, 16);

    this.days = 0;
    this.hours = 3;
    this.minutes = 0;

    this.originIata = "";
    this.destinationIata = "";
    this.hidden = false;

    this.remarks = "";
  },

  computed: {
    auth() {
      return this.$store.getters.auth;
    },

    customerOptions() {
      return this.$store.getters.customers;
    },

    aircraftOptions() {
      return this.$store.getters.aircraft;
    },

    airports() {
      return this.$store.getters.airports;
    },

    computedOrigin() {
      if (this.originIata.length === 3) {
        const airport = this.airports.find((a) => {
          return a.iata === this.originIata.toUpperCase();
        });

        return airport;
      } else {
        return null;
      }
    },

    computedDestination() {
      if (this.selectedType === "Flight") {
        if (this.destinationIata.length === 3) {
          const airport = this.airports.find((a) => {
            return a.iata === this.destinationIata.toUpperCase();
          });

          return airport;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    isValidCallsign() {
      return this.flightNumber.length > 4;
    },

    computedDuration() {
      if (isNaN(this.days) || isNaN(this.hours) || isNaN(this.minutes)) {
        return {
          isValid: false,
        };
      }

      const totalMinutes = this.days * 24 * 60 + this.hours * 60 + this.minutes;

      const d = Math.floor(totalMinutes / (24 * 60)); // Calculate full days
      const h = Math.floor((totalMinutes % (24 * 60)) / 60); // Calculate remaining hours
      const m = totalMinutes % 60; // Calculate remaining minutes

      if (this.selectedType === "Flight") {
        if (
          totalMinutes > this.selectedAircraft.rangeInMinutes ||
          totalMinutes < this.minimumDuration
        ) {
          return {
            isValid: false,
          };
        }

        return {
          totalMinutes,
          days: d,
          hours: h,
          minutes: m,
          text: `${h.toString().padStart(2, "0")}+${m
            .toString()
            .padStart(2, "0")}`,
          isValid: true,
        };
      } else {
        if (
          totalMinutes > this.maximumDuration ||
          totalMinutes < this.minimumDuration
        ) {
          return {
            isValid: false,
          };
        }

        return {
          totalMinutes,
          days: d,
          hours: h,
          minutes: m,
          text: `${d.toString().padStart(2, "0")}D${h
            .toString()
            .padStart(2, "0")}H${m.toString().padStart(2, "0")}M`,
          isValid: true,
        };
      }
    },

    isValid() {
      let isValid =
        this.selectedCustomer &&
        this.selectedAircraft &&
        this.computedOrigin &&
        this.computedDuration.isValid;

      if (this.selectedType === "Flight") {
        isValid = isValid && this.isValidCallsign && this.computedDestination;
      }

      return isValid;
    },
  },

  methods: {
    async handleAddAircraftEvent() {
      if (!this.isValid) {
        return;
      }

      let aircraftEvent;

      const _start = new Date(
        `${this.startDateString}T${this.startTimeString}:00.000+00:00`
      ).getTime();

      const _end =
        _start +
        this.days * 24 * 60 * 60 * 1000 +
        this.hours * 60 * 60 * 1000 +
        this.minutes * 60 * 1000;

      this.previousEnd = _end;

      if (this.selectedType === "Flight") {
        aircraftEvent = {
          type: this.selectedType,
          aircraft: { ...this.selectedAircraft },
          customer: { ...this.selectedCustomer },
          flightNumber: this.flightNumber,
          origin: { ...this.computedOrigin },
          destination: { ...this.computedDestination },
          _start,
          _end,
          duration: { ...this.computedDuration },
          hidden: this.hidden ? true : false,
          remarks: this.remarks ? this.remarks : "",
        };

        this.previousDestinationIata = this.computedDestination.iata;
      } else {
        aircraftEvent = {
          type: this.selectedType,
          aircraft: { ...this.selectedAircraft },
          customer: { ...this.selectedCustomer },
          origin: { ...this.computedOrigin },
          _start,
          _end,
          duration: { ...this.computedDuration },
          remarks: this.remarks ? this.remarks : "",
        };

        this.previousDestinationIata = this.computedOrigin.iata;
      }

      this.aircraftEvents.push({
        ...aircraftEvent,
        tempId: nanoid(),
      });

      this.sortArrayByKey("_start");
      this.handleInitNextAircraftEvent();
    },

    handleInitNextAircraftEvent() {
      const groundTime = 2 * 60 * 60 * 1000;
      const start = this.previousEnd + groundTime;

      this.startDateString = new Date(start).toISOString().substring(0, 10);
      this.startTimeString = new Date(start).toISOString().substring(11, 16);

      if (this.selectedType === "Flight") {
        this.originIata = this.destinationIata;
      }

      this.remarks = "";
      this.destinationIata = "";
      this.tempId = null;
    },

    handleDeleteAircraftEvent(tempId, e) {
      e.stopPropagation();

      const i = this.aircraftEvents.findIndex((_aircraftEvent) => {
        return _aircraftEvent.tempId === tempId;
      });

      if (i >= 0) {
        this.aircraftEvents.splice(i, 1);
        this.tempId = null;
      }
    },

    handleEditAircraftEvent(aircraftEvent) {
      this.tempId = aircraftEvent.tempId;
      this.selectedType = aircraftEvent.type;
      this.selectedCustomer = { ...aircraftEvent.customer };

      this.selectedAircraft = { ...aircraftEvent.aircraft };

      this.startDateString = new Date(aircraftEvent._start)
        .toISOString()
        .substring(0, 10);
      this.startTimeString = new Date(aircraftEvent._start)
        .toISOString()
        .substring(11, 16);

      this.durationDays = aircraftEvent.duration.days;
      this.durationHours = aircraftEvent.duration.hours;
      this.durationMinutes = aircraftEvent.duration.minutes;

      this.originIata = aircraftEvent.origin.iata;

      this.remarks = aircraftEvent.remarks ? aircraftEvent.remarks : "";

      if (aircraftEvent.type === "Flight") {
        this.flightNumber = aircraftEvent.flightNumber;
        this.destinationIata = aircraftEvent.destination.iata;
        this.hidden = aircraftEvent.hidden ? true : false;
      }

      const i = this.aircraftEvents.findIndex((_aircraftEvent) => {
        return _aircraftEvent.tempId === this.tempId;
      });

      this.aircraftEvents.splice(i, 1);
    },

    formatAircraftEvents() {
      let aircraftEvents = [];

      //--------------Format Crewmembers

      const crewmembers = [
        {
          seat: "CAPT",
          duty: "Captain",
          required: true,
        },
        {
          seat: "FO",
          duty: "First Officer",
          required: true,
        },
        {
          seat: "ACM-1",
          duty: "Additional Crewmember",
          required: false,
        },
        {
          seat: "FA-A",
          duty: "Flight Attendant A",
          required: true,
        },
        {
          seat: "FA-B",
          duty: "Flight Attendant B",
          required: true,
        },
        {
          seat: "FA-C",
          duty: "Flight Attendant ",
          required: true,
        },
        {
          seat: "FA-D",
          duty: "Flight Attendant ",
          required: true,
        },
        {
          seat: "FA-E",
          duty: "Flight Attendant ",
          required: false,
        },
        {
          seat: "FA-F",
          duty: "Flight Attendant ",
          required: false,
        },
        {
          seat: "GSC",
          duty: "Ground Security Coordinator",
          required: false,
        },
        {
          seat: "MX",
          duty: "Flight Mechanic",
          required: false,
        },
        {
          seat: "DH-1",
          duty: "Deadhead",
          required: false,
        },
        {
          seat: "DH-2",
          duty: "Deadhead",
          required: false,
        },
        {
          seat: "DH-3",
          duty: "Deadhead",
          required: false,
        },
        {
          seat: "DH-4",
          duty: "Deadhead",
          required: false,
        },
        {
          seat: "DH-5",
          duty: "Deadhead",
          required: false,
        },
        {
          seat: "DH-6",
          duty: "Deadhead",
          required: false,
        },
        {
          seat: "ACM-2",
          duty: "Additional Crewmember",
          required: false,
        },
        {
          seat: "ACM-3",
          duty: "Additional Crewmember",
          required: false,
        },
        {
          seat: "ACM-4",
          duty: "Additional Crewmember",
          required: false,
        },
        {
          seat: "ACM-5",
          duty: "Additional Crewmember",
          required: false,
        },
        {
          seat: "ACM-6",
          duty: "Additional Crewmember",
          required: false,
        },
        {
          seat: "ACM-7",
          duty: "Additional Crewmember",
          required: false,
        },
        {
          seat: "ACM-8",
          duty: "Additional Crewmember",
          required: false,
        },
      ];

      //---------------Format each event for database
      this.aircraftEvents.forEach((_aircraftEvent) => {
        if (_aircraftEvent.type === "Flight") {
          aircraftEvents.push({
            type: _aircraftEvent.type,
            status: "SCHEDULED",
            flightRules: "Part 117",
            unscheduledEvent: "None",
            needsUsCrew: false,
            crewmembers,
            fuelTickets: [],
            departureFuel: 0,
            arrivalFuel: 0,
            delays: [],
            bookedPaxAdults: 0,
            bookedPaxChildren: 0,
            bookedPaxInfants: 0,
            actualPaxAdults: 0,
            actualPaxChildren: 0,
            actualPaxInfants: 0,
            noTakeoff: 0,
            deiced: 0,
            aircraftId: _aircraftEvent.aircraft._id,
            customerId: _aircraftEvent.customer._id,
            flightNumber: _aircraftEvent.flightNumber.toUpperCase(),
            hidden: _aircraftEvent.hidden ? true : false,
            originId: _aircraftEvent.origin._id,
            destinationId: _aircraftEvent.destination._id,
            plannedDestinationId: _aircraftEvent.destination._id,
            _std: new Date(_aircraftEvent._start),
            _sta: new Date(_aircraftEvent._end),
            _start: new Date(_aircraftEvent._start),
            _end: new Date(_aircraftEvent._end),
            parkingDeparture: "",
            parkingArrival: "",
            duration: _aircraftEvent.duration,
            remarks: _aircraftEvent.remarks ? _aircraftEvent.remarks : null,
            createdById: this.auth.employee._id,
            updatedById: this.auth.employee._id,
          });
        } else {
          aircraftEvents.push({
            type: _aircraftEvent.type,
            aircraftId: _aircraftEvent.aircraft._id,
            customerId: _aircraftEvent.customer._id,
            originId: _aircraftEvent.origin._id,
            _start: new Date(_aircraftEvent._start),
            _end: new Date(_aircraftEvent._end),
            parkingDeparture: "",
            parkingArrival: "",
            duration: _aircraftEvent.duration,
            remarks: _aircraftEvent.remarks ? _aircraftEvent.remarks : null,
            createdById: this.auth.employee._id,
            updatedById: this.auth.employee._id,
          });
        }
      });

      return aircraftEvents;
    },

    async handleSaveAircraftEvents() {
      const firstAircraftEvent = this.aircraftEvents[0];
      const lastAircraftEvent =
        this.aircraftEvents[this.aircraftEvents.length - 1];

      try {
        this.loading = true;

        let res = await this.$store.dispatch("precheckAircraftSchedule", {
          aircraftId: this.selectedAircraft._id,
          _start: firstAircraftEvent._start,
          _end: lastAircraftEvent._end,
        });

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        const conflicts = res.data.conflicts;

        if (conflicts.length === 0) {
          const formattedAircraftEvents = this.formatAircraftEvents();

          res = await this.$store.dispatch(
            "saveAircraftSchedule",
            formattedAircraftEvents
          );

          if (res.status !== 200) {
            this.snackbarColor = "red";
            this.snackbarText = res.message;
            this.snackbarVisible = true;
          }

          this.aircraftEvents = [];

          if (this.goToFlightBoard) {
            this.$router.push({ name: "flightBoard" });
          } else {
            this.snackbarColor = "#84cc16";
            this.snackbarText = "Aircraft Schedule Saved";
            this.snackbarVisible = true;

            setTimeout(() => {
              this.snackbarVisible = false;
            }, 3000);
          }
        } else {
          this.snackbarColor = "red";
          this.snackbarText = `Unable to save schedule due to conlicts.`;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.snackbarVisible = false;
          }, 3000);
        }
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
      }
    },

    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.aircraftEvents, key);

      if (sortedAsc) {
        this.aircraftEvents.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.aircraftEvents.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    formatDateShort(millis) {
      const date = new Date(millis);

      let options = {
        timeZone: "UTC",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      };

      const string = new Intl.DateTimeFormat("en-US", options).format(date);

      return string;
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}

.aircraft-events-table {
  color: #fff;
  font-size: 1.3rem;
  padding: 0 5px;
  grid-column: 1/13;
}

.grid-card {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 0 5px;
  margin-bottom: 1px;
  background-color: #cef6f5;
  color: black;
  cursor: pointer;
}

.footer {
  color: #fff;
  font-size: 1.3rem;
  margin-top: 5px;
  padding: 0 5px;
  grid-column: 1/13;
}

.warning {
  background-color: red;
  color: white;
}

.is-invalid {
  background-color: #fef08a;
}

.edit-mode {
  background-color: yellowgreen;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.aircraft-operational {
  background-color: #f5d0a9;
}
</style>
