<template>
  <div class="outer-frame">
    <div v-if="aircraftEvent.type === 'Flight'" class="top-label">
      {{ aircraftEvent.flightNumber }}
    </div>
    <div v-else class="top-label">
      {{ aircraftEvent.type }}
    </div>

    <div class="inner-frame" :style="getInnerFrameStyle()">
      {{ computedInnerFrameText }}
    </div>
    <div class="bottom-label">
      <div class="text-[10px]">
        {{ aircraftEvent._start.substring(11, 16) }}
      </div>
      <div class="text-[10px]">
        {{ aircraftEvent._end.substring(11, 16) }}
      </div>
    </div>
  </div>
</template>
<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "flightBoardLabel",
  mixins: [mixin],

  props: ["aircraftEvent"],

  computed: {
    computedInnerFrameText() {
      let txt;

      if (this.aircraftEvent.type === "Flight") {
        txt = `${this.aircraftEvent.origin.iata}-${this.aircraftEvent.destination.iata}`;
      } else {
        txt = `${this.aircraftEvent.origin.iata} | ${
          this.aircraftEvent.remarks ? this.aircraftEvent.remarks : "No remarks"
        }`;
      }

      return txt;
    },
  },

  methods: {
    getInnerFrameStyle() {
      let style;

      const delayFlag = "2px solid red";

      if (this.aircraftEvent.type === "Flight") {
        switch (this.aircraftEvent.status) {
          case "BLOCKED-IN":
            style = {
              padding: "5px 0",
              border: "1px solid #bebebe",
              backgroundColor: "#91c4c5",
              color: "#000",
            };
            break;
          case "BLOCKED-OUT":
            style = {
              padding: "5px 0",
              border: "1px solid #bebebe",
              backgroundColor: "#93c5fd",
              color: "#000",
            };
            break;
          case "AIRBORNE":
            style = {
              padding: "5px 0",
              border: "1px solid #bebebe",
              backgroundColor: "#93c5fd",
              color: "#000",
            };
            break;
          case "SCHEDULED":
            if (this.aircraftEvent.delays.length) {
              style = {
                padding: "5px 0",
                borderTop: "1px solid #bebebe",
                borderLeft: delayFlag,
                borderRight: "1px solid #bebebe",
                borderBottom: "1px solid #bebebe",
                backgroundColor: "#fdf5bf",
                color: "#000",
              };
            } else {
              style = {
                padding: "5px 0",
                borderTop: "1px solid #bebebe",
                borderLeft: "1px solid #bebebe",
                borderRight: "1px solid #bebebe",
                borderBottom: "1px solid #bebebe",
                backgroundColor: "#ffe4c4",
                color: "#000",
              };
            }
            break;
          default:
            break;
        }
      } else {
        style = {
          padding: "5px 0",
          border: "1px solid #bebebe",
          backgroundColor: "#f5d0a9",
          color: "#000",
        };
      }

      return style;
    },
  },
};
</script>
<style scoped>
.outer-frame {
  position: absolute;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
}

.inner-frame {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #bebebe;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.top-label {
  font-size: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottom-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
