<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <!-- COMPUTED TIMES ERRORS -->
    <r-modal
      v-if="showComputedTimesErrorsDialog"
      @close="showComputedTimesErrorsDialog = false"
    >
      <div v-if="showComputedTimesErrorsDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Fix the folowing errors</div>
          <button
            @click="showComputedTimesErrorsDialog = false"
            class="btn-icon"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div v-if="!computedOrigin">
          Enter a valid IATA code for the departure airport.
        </div>
        <div v-if="!computedDestination">
          Enter a valid IATA code for the arrival airport.
        </div>

        <div v-for="(error, index) in computedTimesErrors" :key="index">
          {{ error }}
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="showComputedTimesErrorsDialog = false"
            class="btn bg-gray-500 text-white"
          >
            Close
          </button>
        </div>
      </div>
    </r-modal>

    <!-- FUEL -->
    <r-modal v-if="showFuelDialog" @close="showFuelDialog = false">
      <div v-if="showFuelDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Fuel</div>
          <button @click="showFuelDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="mb-2">
          <p class="ml-2 text-sm text-gray-500">Vendor</p>

          <input
            class="block rounded-md border-0 w-full py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
            v-model="fuelTicketVendor"
            type="text"
            maxlength="40"
            name="fuelVendorName"
            id="fuelVendorName"
            placeholder="Fuel Vendor Name"
          />
        </div>

        <div class="mb-2">
          <p class="ml-2 text-sm text-gray-500">Invoice Number</p>

          <input
            class="block rounded-md border-0 w-full py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
            v-model="fuelTicketInvoiceNumber"
            type="text"
            maxlength="40"
            name="fuelTicketNumber"
            id="fuelTicketNumber"
            placeholder="Invoice Number"
          />
        </div>

        <div class="flex justify-end items-center mb-5">
          <div class="text-2xl text-gray-500 mr-3">US Gallons</div>
          <input
            class="block py-1.5 w-[150px] rounded-md border-0 px-3 text-center text-2xl text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600"
            type="number"
            step="100"
            min="0"
            max="100000"
            v-model="fuelTicketQuantity"
          />

          <button
            @click="handleAddFuelTicket"
            class="ml-2 btn bg-blue-500 text-white"
            :class="{ disabled: !isValidFuelTicket }"
          >
            Add
          </button>
        </div>

        <div v-if="fuelTicketList.length" class="border border-gray-600">
          <!-- Data Table -->

          <table
            class="table-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="px-6 w-1/2 py-2 cursor-pointer">
                  Vendor
                </th>
                <th scope="col" class="px-6 cursor-pointer">Invoice Number</th>
                <th scope="col" class="px-6 w-[150px] py-2 cursor-pointer">
                  US Gallons
                </th>
                <th scope="col" class="px-6 w-[10px] py-2 cursor-pointer">
                  Remove
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in fuelTicketList"
                :key="item.invoiceNumber"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td
                  scope="row"
                  class="px-6 py-1.5 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ item.vendor }}
                </td>
                <td>{{ item.invoiceNumber }}</td>
                <td class="px-6 py-1.5">
                  {{ `${formatQuantity(item.quantity)}` }}
                </td>
                <td
                  class="px-6 py-1.5"
                  @click="handleRemoveFuelTicket(item.invoiceNumber)"
                >
                  <i class="fa-solid fa-trash-can"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="bottom-frame text-base">
          <div class="p-3 text-xl text-gray-500">No fuel tickets.</div>
        </div>

        <div class="flex justify-end p-2">
          <button
            @click="showFuelDialog = false"
            class="btn bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            @click="handleUpdateFuel"
            class="ml-1 btn bg-green-500 text-white"
            :class="{ disabled: !fuelTicketList.length }"
          >
            Save
          </button>
        </div>
      </div>
    </r-modal>

    <!-- FLIGHT INFORMATION -->
    <r-modal
      v-if="showFlightInformationDialog"
      @close="showFlightInformationDialog = false"
    >
      <div v-if="showFlightInformationDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Flight Information</div>
          <button @click="showFlightInformationDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div>
          <span>Sched. Departure:</span
          ><span class="font-bold ml-1 text-blue-700">{{
            `${formatDateTime(this._std)}`
          }}</span>
        </div>
        <div>
          <span>Sched. Arrival:</span
          ><span class="font-bold ml-1 text-blue-700">{{
            `${formatDateTime(this._sta)}`
          }}</span>
        </div>
        <div>
          <span>Last update:</span
          ><span class="font-bold ml-1 text-blue-700">{{
            `${formatDateTime(this.updatedAt)} Z`
          }}</span>
        </div>

        <div class="flex justify-end p-2">
          <button
            @click="showFlightInformationDialog = false"
            class="btn bg-gray-500 text-white"
          >
            Close
          </button>
        </div>
      </div>
    </r-modal>

    <!-- PAXS -->
    <r-modal v-if="showPaxsDialog" @close="showPaxsDialog = false">
      <div v-if="showPaxsDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Passengers</div>
          <button @click="showPaxsDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex justify-between px-10 mb-5">
          <!-- BOOKED -->
          <div class="flex gap-5">
            <div>
              <p class="ml-2 text-gray-500">Booked Adults</p>
              <input
                class="block py-1.5 w-[150px] rounded-md border-0 px-3 text-center text-2xl text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600"
                type="number"
                step="1"
                min="0"
                max="200"
                v-model="bookedPaxAdultsInput"
                :class="{
                  'is-invalid': !isValidBookedPaxCount,
                }"
              />
            </div>

            <div>
              <p class="ml-2 text-gray-500">Booked Infants</p>
              <input
                class="block py-1.5 w-[150px] rounded-md border-0 px-3 text-center text-2xl text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600"
                type="number"
                step="1"
                min="0"
                max="200"
                v-model="bookedPaxInfantsInput"
              />
            </div>
          </div>

          <!-- ACTUAL -->
          <div class="flex gap-5">
            <div>
              <p class="ml-2 text-gray-500">Actual Adults</p>
              <input
                class="block py-1.5 w-[150px] rounded-md border-0 px-3 text-center text-2xl text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600"
                type="number"
                step="1"
                min="0"
                max="200"
                v-model="actualPaxAdultsInput"
                :class="{
                  'is-invalid': !isValidActualPaxCount,
                }"
              />
            </div>

            <div>
              <p class="ml-2 text-gray-500">Actual Infants</p>
              <input
                class="block py-1.5 w-[150px] rounded-md border-0 px-3 text-center text-2xl text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600"
                type="number"
                step="1"
                min="0"
                max="200"
                v-model="actualPaxInfantsInput"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-end p-2">
          <button
            @click="showPaxsDialog = false"
            class="btn bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            @click="handleUpdatePaxs"
            class="ml-1 btn bg-green-500 text-white"
            :class="{
              disabled: !isValidBookedPaxCount || !isValidActualPaxCount,
            }"
          >
            Save
          </button>
        </div>
      </div>
    </r-modal>

    <!-- DELAYS -->
    <r-modal v-if="showDelaysDialog" @close="showDelaysDialog = false">
      <div v-if="showDelaysDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Delays</div>
          <button @click="showDelaysDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex items-center">
          <p class="mr-2 text-sm text-gray-500">Select</p>
          <select
            class="block py-1.5 w-full rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
            v-model="selectedDelay"
          >
            <option v-for="item in delayOptions" :key="item.code" :value="item">
              {{ item.descriptionShort }}
            </option>
          </select>

          <button
            @click="handleAddDelay"
            class="ml-1 btn bg-blue-500 text-white"
          >
            Add
          </button>
        </div>

        <div v-if="delayList.length" class="border border-gray-600 my-3">
          <!-- Data Table -->

          <table
            class="table-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="px-6 w-[50px] py-2 cursor-pointer">
                  Code
                </th>
                <th scope="col" class="px-6 w-[200px] cursor-pointer">
                  Description
                </th>
                <th scope="col" class="px-6 py-2 cursor-pointer">Details</th>
                <th scope="col" class="px-6 w-[10px] py-2 cursor-pointer">
                  Remove
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in delayList"
                :key="item.code"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td
                  scope="row"
                  class="px-6 py-1.5 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ item.code }}
                </td>
                <td>{{ item.descriptionShort }}</td>
                <td class="px-6 py-1.5">
                  {{ item.descriptionLong }}
                </td>
                <td class="px-6 py-1.5" @click="handleRemoveDelay(item.code)">
                  <i class="fa-solid fa-trash-can"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="bottom-frame text-base">
          <div class="p-3 text-xl text-gray-500">No delays.</div>
        </div>

        <div class="flex justify-end p-2">
          <button
            @click="showDelaysDialog = false"
            class="btn bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            @click="handleUpdateDelays"
            class="ml-1 btn bg-green-500 text-white"
          >
            Save
          </button>
        </div>
      </div>
    </r-modal>

    <div v-else>
      <div class="main-grid">
        <div class="grid-row-one flex gap-2 flex-wrap">
          <div>
            <p class="ml-2 text-sm text-gray-500">Customer</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedCustomer"
            >
              <option
                v-for="item in customerOptions"
                :key="item._id"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Aircraft</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedAircraft"
            >
              <option
                v-for="item in aircraftOptions"
                :key="item._id"
                :value="item"
              >
                {{ item.registration }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Status</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedStatus"
              @change="handleUpdateStatus"
            >
              <option
                v-for="(item, index) in statusOptions"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Flight Rules</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedFlightRules"
            >
              <option
                v-for="item in flightRulesOptions"
                :key="item._id"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Log Page #</p>

            <input
              class="block rounded-md border-0 max-w-[120px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              v-model="logpage"
              type="text"
              maxlength="10"
              name="logpage"
              id="logpage"
              placeholder="Log Page #"
            />
          </div>

          <div
            class="ml-5 cursor-pointer"
            @click="showFlightInformationDialog = true"
          >
            <i class="fa-solid fa-circle-info text-blue-500"></i>
          </div>
        </div>

        <div class="grid-row-two flex gap-2 flex-wrap">
          <div>
            <p class="ml-2 text-sm text-gray-500">Date</p>
            <input
              class="block rounded-md border-0 max-w-[150px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              v-model="outDateString"
              type="date"
            />
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Flight Number</p>

            <input
              class="block rounded-md border-0 max-w-[120px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !isValidCallsign }"
              v-model="flightNumber"
              type="text"
              maxlength="10"
              name="flightNumber"
              id="flightNumber"
              placeholder="Flight No."
            />
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">From</p>

            <input
              class="block rounded-md border-0 max-w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !computedOrigin }"
              disabled="true"
              v-model="originIata"
              type="text"
              maxlength="3"
              name="originIata"
              id="originIata"
              placeholder="IATA"
            />
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">To</p>
            <input
              class="block rounded-md border-0 max-w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !computedDestination }"
              v-model="destinationIata"
              type="text"
              maxlength="3"
              name="destinationIata"
              id="destinationIata"
              placeholder="IATA"
            />
          </div>

          <div>
            <p class="ml-5 text-sm text-gray-500">{{ outDynamicLabel }}</p>
            <input
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
              v-model="outTimeString"
              type="time"
            />
          </div>

          <div>
            <p class="ml-5 text-sm text-gray-500">{{ offDynamicLabel }}</p>
            <input
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
              v-model="offTimeString"
              :disabled="!enableOffTime"
              type="time"
            />
          </div>

          <div>
            <p class="ml-5 text-sm text-gray-500">{{ onDynamicLabel }}</p>
            <input
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
              v-model="onTimeString"
              :disabled="!enableOnTime"
              type="time"
            />
          </div>

          <div>
            <p class="ml-5 text-sm text-gray-500">{{ inDynamicLabel }}</p>
            <input
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
              v-model="inTimeString"
              type="time"
            />
          </div>

          <div>
            <p class="ml-5 text-sm text-gray-500">Flight</p>
            <div
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 uppercase"
            >
              <div v-if="computedTimes.isValid">
                {{ computedTimes.flight.text }}
              </div>
              <div v-else class="text-red-500">INVALID</div>
            </div>
          </div>

          <!-- Block Time -->
          <div>
            <p class="ml-5 text-sm text-gray-500">Block</p>
            <div
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 uppercase"
            >
              <div v-if="computedTimes.isValid">
                {{ computedTimes.block.text }}
              </div>
              <div v-else class="text-red-500">INVALID</div>
            </div>
          </div>

          <!-- Departure Fuel -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Dep. Fuel</p>
            <input
              class="block py-1.5 w-[100px] rounded-md border-0 text-center text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ 'is-invalid': !isValidDepartureFuel }"
              type="number"
              step="1000"
              min="0"
              max="100000"
              v-model="departureFuel"
            />
          </div>

          <!-- Arrival Fuel -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Arr. Fuel</p>
            <input
              class="block py-1.5 w-[100px] rounded-md border-0 text-center text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ 'is-invalid': !isValidArrivalFuel }"
              :disabled="!enableFuelRemaining"
              type="number"
              step="1000"
              min="0"
              max="100000"
              v-model="arrivalFuel"
            />
          </div>

          <!-- Uplift -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Uplift (Gals)</p>
            <div
              class="block rounded-md border-0 w-[100px] py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 uppercase cursor-pointer"
              :class="{ disabled: !enableFuelUplift }"
              @click="handleShowFuelDialog"
            >
              <div>
                {{ formatQuantity(fuelUplift) }}
              </div>
            </div>
          </div>

          <!-- Pax. Booked -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Pax. Booked</p>
            <div
              class="block rounded-md border-0 w-[100px] py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 uppercase cursor-pointer"
              @click="handleShowPaxsDialog"
            >
              <div>
                {{ formatPaxsBooked() }}
              </div>
            </div>
          </div>

          <!-- Pax. Actual -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Pax. Actual</p>
            <div
              class="block rounded-md border-0 w-[100px] py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 uppercase cursor-pointer"
              @click="handleShowPaxsDialog"
            >
              <div>
                {{ formatPaxsActual() }}
              </div>
            </div>
          </div>
        </div>

        <div class="grid-row-three flex gap-5 flex-wrap items-center">
          <!-- Delay Codes -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Delay Code(s)</p>
            <div
              class="block rounded-md border-0 w-[300px] py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              @click="handleShowDelaysDialog"
            >
              <div>
                {{ formatDelayCodes() }}
              </div>
            </div>
          </div>

          <!-- Parking Departure -->
          <div class="mb-2">
            <p class="ml-2 text-sm text-gray-500">Gate Dep.</p>

            <input
              class="block rounded-md border-0 w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              v-model="parkingDeparture"
              type="text"
              maxlength="40"
              name="parkingDeparture"
              id="parkingDeparture"
              placeholder="Gate"
            />
          </div>

          <!-- Parking Arrival -->
          <div class="mb-2">
            <p class="ml-2 text-sm text-gray-500">Gate Arr.</p>

            <input
              class="block rounded-md border-0 w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              v-model="parkingArrival"
              type="text"
              maxlength="40"
              name="parkingArrival"
              id="parkingArrival"
              placeholder="Gate"
            />
          </div>
        </div>

        <div class="grid-row-four">
          <p class="ml-2 text-sm text-gray-500">Remarks</p>
          <div>
            <textarea
              v-model="remarks"
              rows="2"
              maxlength="400"
              siz
              name="remarks"
              id="remarks"
              class="block w-full rounded-md border-0 py-0.5 px-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
            />
          </div>
        </div>

        <div class="grid-row-five flex gap-5 flex-wrap items-center">
          <div v-if="selectedStatus === 'BLOCKED-IN'">
            <div class="flex my-2 items-center py-1">
              <SwitchGroup as="div" class="flex items-center">
                <Switch
                  v-model="noTakeoff"
                  :class="[
                    noTakeoff ? 'bg-red-500' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      noTakeoff ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>

              <div class="text-sm text-gray-400 ml-1">No Takeoff</div>
            </div>
          </div>

          <div>
            <div class="flex my-2 items-center py-1">
              <SwitchGroup as="div" class="flex items-center">
                <Switch
                  v-model="deiced"
                  :class="[
                    deiced ? 'bg-blue-500' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      deiced ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>

              <div class="text-sm text-gray-400 ml-1">De-Iced</div>
            </div>
          </div>

          <div>
            <div class="flex my-2 items-center py-1">
              <SwitchGroup as="div" class="flex items-center">
                <Switch
                  v-model="needsUsCrew"
                  :class="[
                    needsUsCrew ? 'bg-blue-500' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      needsUsCrew ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>

              <div class="text-sm text-gray-400 ml-1">US Crew</div>
            </div>
          </div>

          <div>
            <div class="flex my-2 items-center py-1">
              <SwitchGroup as="div" class="flex items-center">
                <Switch
                  v-model="hidden"
                  :class="[
                    hidden ? 'bg-red-500' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      hidden ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>

              <div class="text-sm text-gray-400 ml-1">
                Hide on public searches.
              </div>
            </div>
          </div>
        </div>

        <div class="grid-row-six">
          <!-- Gray Line -->
          <div class="w-full h-px bg-gray-400 mb-4"></div>

          <div class="overflow-x-auto max-h-[50vh]">
            <div class="table-auto shadow-md sm:rounded-lg">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                <thead
                  class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400 sticky top-0"
                >
                  <tr>
                    <th scope="col" class="px-4 py-3 w-[20px]">Seat</th>
                    <th scope="col" class="px-6 py-3 w-[100px]">Duty</th>
                    <th scope="col" class="px-6 py-3">Employee</th>
                    <th scope="col" class="px-6 py-3 w-[50px]">Required</th>
                    <th scope="col" class="px-6 py-3 w-[10px]">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in crewmembers"
                    :key="item.seat"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    :style="getRowStyle(item)"
                    @click="handleToggleRequired(item)"
                  >
                    <td
                      scope="row"
                      class="px-3 py-1.5 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {{ item.seat }}
                    </td>
                    <td
                      scope="row"
                      class="px-3 py-1.5 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {{ item.duty }}
                    </td>
                    <td
                      scope="row"
                      class="px-3 py-1.5 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {{ formatCrewmember(item) }}
                    </td>
                    <td
                      scope="row"
                      class="px-3 py-1.5 font-medium text-gray-900 text-center"
                    >
                      {{ item.required ? "Yes" : "No" }}
                    </td>

                    <td v-if="item.employee" class="px-3 py-1.5 text-center">
                      <i
                        class="fa-solid fa-trash-can cursor-pointer"
                        @click="handleRemoveEmployee(item, $event)"
                      ></i>
                    </td>
                    <td v-else class="px-3 py-1.5 text-center"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="grid-footer flex justify-between">
          <div class="flex">
            <button
              @click="handleAssignCrew"
              class="mt-7 btn-small bg-blue-500 text-white"
            >
              Assign Crew
            </button>
          </div>
          <div class="flex px-1">
            <button
              @click="handleGoBack"
              class="mt-7 btn-small bg-gray-500 text-white"
            >
              Cancel
            </button>
            <button
              @click="handleSave"
              class="ml-1 mt-7 btn-small bg-green-500 text-white"
              :class="{ disabled: !isValid }"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Switch,
  SwitchGroup,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "administration",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    Switch,
    SwitchGroup,
  },

  data() {
    return {
      loading: false,

      selectedDelay: null,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //------------------------Dynamic Labels
      outDynamicLabel: "OUT",
      offDynamicLabel: "OFF",
      onDynamicLabel: "ON",
      inDynamicLabel: "IN",

      //-------------------------Dialogs
      showComputedTimesErrorsDialog: false,
      showFuelDialog: false,
      showFlightInformationDialog: false,
      showPaxsDialog: false,
      showDelaysDialog: false,

      //----------------------Options
      statusOptions: ["SCHEDULED", "BLOCKED-OUT", "AIRBORNE", "BLOCKED-IN"],
      flightRulesOptions: [
        "Part 117",
        "Part 121",
        "Part 91",
        "Ferry",
        "MX Ferry",
      ],

      //----------------------Enable/Disable
      enableOffTime: false,
      enableOnTime: false,
      enableFuelRemaining: false,
      enableFuelUplift: false,

      //--------------------Fuel Tickets
      fuelTicketList: [],
      fuelTicketVendor: "",
      fuelTicketInvoiceNumber: "",
      fuelTicketQuantity: "",

      //----------------------Errors
      computedTimesErrors: [],

      //---------------------------------------------Flight
      selectedStatus: null,
      selectedCustomer: null,
      selectedAircraft: null,
      flightNumber: "",
      logpage: "",
      hidden: false,
      originIata: "",
      destinationIata: "",
      plannedDestinationIata: "",

      selectedFlightRules: "",

      cabinSeats: 0,

      parkingDeparture: "",
      parkingArrival: "",

      crewmembers: [],
      originalCrewmembers: [],
      updatedById: null,

      //----------------Delays
      delays: [],
      delayList: [],

      //------------------Fuel
      fuelTickets: [],
      fuelUplift: 0,
      departureFuel: 0,
      arrivalFuel: 0,

      //------------------Times
      _start: null,
      _end: null,
      outDateString: new Date().toISOString().substring(0, 10),
      outTimeString: new Date().toISOString().substring(11, 16),
      offTimeString: new Date().toISOString().substring(11, 16),
      onTimeString: new Date().toISOString().substring(11, 16),
      inTimeString: new Date().toISOString().substring(11, 16),

      //-------------------Paxs
      actualPaxAdults: 0,
      actualPaxInfants: 0,
      bookedPaxAdults: 0,
      bookedPaxInfants: 0,

      actualPaxAdultsInput: 0,
      actualPaxInfantsInput: 0,
      bookedPaxAdultsInput: 0,
      bookedPaxInfantsInput: 0,

      deiced: false,
      needsUsCrew: false,
      remarks: "",
      unscheduledEvent: "",
      minimumBlock: 20,
      minimumFlight: 10,
      noTakeoff: false,
      updatedAt: null,
    };
  },

  created() {
    const flight = { ...this.flight };

    const {
      _id,
      actualPaxAdults,
      //actualPaxChildren,
      actualPaxInfants,
      aircraft,
      bookedPaxAdults,
      //bookedPaxChildren,
      bookedPaxInfants,
      crewmembers,
      customer,
      deiced,
      needsUsCrew,
      delays,
      destination,
      flightNumber,
      flightRules,
      fuelTickets,
      departureFuel,
      arrivalFuel,
      hidden,
      logpage,
      noTakeoff,
      origin,
      plannedDestination,
      parkingDeparture,
      parkingArrival,
      remarks,
      status,
      _sta,
      _std,
      _start,
      _off,
      _on,
      _end,
      updatedAt,
      updatedById,
    } = flight;

    let i = this.customerOptions.findIndex((c) => {
      return c._id === customer._id;
    });

    if (i >= 0) {
      this.selectedCustomer = { ...this.customerOptions[i] };
    } else {
      this.selectedCustomer = { ...this.customerOptions[0] };
    }

    i = this.aircraftOptions.findIndex((a) => {
      return a._id === aircraft._id;
    });

    if (i >= 0) {
      this.selectedAircraft = { ...this.aircraftOptions[i] };
    } else {
      this.selectedAircraft = { ...this.aircraftOptions[0] };
    }

    this._id = _id;
    this.flightNumber = flightNumber;
    this.selectedStatus = status;
    this.selectedFlightRules = flightRules;
    this.logpage = logpage ? logpage : "";
    this.noTakeoff = noTakeoff ? true : false;
    this.deiced = deiced ? true : false;
    this.needsUsCrew = needsUsCrew ? true : false;

    this._start = _start;
    this._std = _std;
    this._sta = _sta;
    this.updatedAt = updatedAt;

    this.delays = delays;
    this.crewmembers = crewmembers;
    this.originalCrewmembers = [...crewmembers];
    this.updatedById = updatedById;

    this.parkingDeparture = parkingDeparture;
    this.parkingArrival = parkingArrival;

    //------------------------------------------Passengers
    this.actualPaxAdults = actualPaxAdults;
    //this.actualPaxChildren = actualPaxChildren;
    this.actualPaxInfants = actualPaxInfants;

    this.bookedPaxAdults = bookedPaxAdults;
    //this.bookedPaxChildren = bookedPaxChildren;
    this.bookedPaxInfants = bookedPaxInfants;

    let seats = 0;

    aircraft.cabinConfig.forEach((item) => {
      seats += item.seats;
    });

    this.cabinSeats = seats;

    //--------------------------------------------Fuel
    this.fuelTickets = fuelTickets;
    this.departureFuel = departureFuel;
    this.arrivalFuel = arrivalFuel;

    let uplift = 0;
    if (fuelTickets.length) {
      fuelTickets.forEach((item) => {
        uplift += item.quantity;
      });
    }

    this.fuelUplift = uplift;

    //--------------------------------------------OUT Time
    this.outDateString = new Date(_start).toISOString().substring(0, 10);
    this.outTimeString = new Date(_start).toISOString().substring(11, 16);

    //--------------------------------------------IN Time
    this.inTimeString = new Date(_end).toISOString().substring(11, 16);

    switch (status) {
      case "BLOCKED-OUT":
        this.offTimeString = null;
        this.onTimeString = null;

        this.enableOffTime = false;
        this.enableOnTime = false;

        this.outDynamicLabel = "OUT";
        this.offDynamicLabel = "OFF";
        this.onDynamicLabel = "ON";
        this.inDynamicLabel = "ETA";

        this.enableFuelRemaining = false;
        this.enableFuelUplift = false;
        break;

      case "AIRBORNE":
        this.offTimeString = new Date(_off).toISOString().substring(11, 16);
        this.onTimeString = "";

        this.onTimeString = null;

        this.enableOffTime = true;
        this.enableOnTime = false;

        this.outDynamicLabel = "OUT";
        this.offDynamicLabel = "OFF";
        this.onDynamicLabel = "ON";
        this.inDynamicLabel = "ETA";

        this.enableFuelRemaining = false;
        this.enableFuelUplift = false;
        break;

      case "BLOCKED-IN":
        if (!noTakeoff) {
          this.offTimeString = new Date(_off).toISOString().substring(11, 16);
          this.onTimeString = new Date(_on).toISOString().substring(11, 16);
        }

        this.enableOffTime = true;
        this.enableOnTime = true;

        this.outDynamicLabel = "OUT";
        this.offDynamicLabel = "OFF";
        this.onDynamicLabel = "ON";
        this.inDynamicLabel = "IN";

        this.enableFuelRemaining = true;
        this.enableFuelUplift = false;
        break;

      default: //"SCHEDULED"
        this.offTimeString = null;
        this.onTimeString = null;

        this.enableOffTime = false;
        this.enableOnTime = false;

        this.outDynamicLabel = "ETD";
        this.offDynamicLabel = "OFF";
        this.onDynamicLabel = "ON";
        this.inDynamicLabel = "ETA";

        this.enableFuelRemaining = false;
        this.enableFuelUplift = true;
        break;
    }

    this.originIata = origin.iata;
    this.destinationIata = destination.iata;
    this.plannedDestination = plannedDestination;

    this.hidden = hidden ? true : false;
    this.remarks = remarks ? remarks : "";
  },

  computed: {
    auth() {
      return this.$store.getters.auth;
    },

    flight() {
      return this.$store.getters.flight;
    },

    customerOptions() {
      return this.$store.getters.customers;
    },

    aircraftOptions() {
      return this.$store.getters.aircraft;
    },

    airports() {
      return this.$store.getters.airports;
    },

    computedOrigin() {
      if (this.originIata.length === 3) {
        const airport = this.airports.find((a) => {
          return a.iata === this.originIata.toUpperCase();
        });

        return airport;
      } else {
        return null;
      }
    },

    computedDestination() {
      if (this.destinationIata.length === 3) {
        const airport = this.airports.find((a) => {
          return a.iata === this.destinationIata.toUpperCase();
        });

        return airport;
      } else {
        return null;
      }
    },

    isValidBookedPaxCount() {
      return this.bookedPaxAdultsInput <= this.cabinSeats;
    },

    isValidActualPaxCount() {
      return this.actualPaxAdultsInput <= this.cabinSeats;
    },

    isValidDepartureFuel() {
      let isValid = false;

      switch (this.selectedStatus) {
        case "AIRBORNE":
          isValid = this.departureFuel > 0;
          break;

        case "BLOCKED-IN":
          isValid =
            this.departureFuel > 0 && this.arrivalFuel < this.departureFuel;
          break;

        case "BLOCKED-OUT":
          isValid = this.departureFuel > 0;
          break;

        default: //"SCHEDULED"
          if (this.departureFuel === 0) {
            isValid = this.arrivalFuel === 0;
          } else {
            isValid = this.arrivalFuel < this.departureFuel;
          }

          break;
      }

      return isValid;
    },

    isValidArrivalFuel() {
      if (this.selectedStatus === "BLOCKED-IN") {
        return this.arrivalFuel < this.departureFuel;
      } else {
        return this.arrivalFuel <= this.departureFuel;
      }
    },

    isValidFuelTicket() {
      if (
        this.fuelTicketVendor.length &&
        this.fuelTicketInvoiceNumber.length &&
        this.fuelTicketQuantity > 0
      ) {
        return true;
      } else {
        return false;
      }
    },

    isValidCallsign() {
      return this.flightNumber.length > 3;
    },

    computedTimes() {
      this.computedTimesErrors = [];

      if (!this.outDateString) {
        this.computedTimesErrors.push("Date is invalid");
        return {
          isValid: false,
        };
      }

      if (!this.outTimeString) {
        this.computedTimesErrors.push("OUT time is invalid");
        return {
          isValid: false,
        };
      }

      if (!this.inTimeString) {
        this.computedTimesErrors.push("IN TIME is invalid");
        return {
          isValid: false,
        };
      }

      if (this.noTakeoff) {
        this.offTimeString = null;
        this.onTimeString = null;
      }

      let millis,
        diff,
        _out,
        _off,
        dateString,
        _on,
        _in,
        totalMinutes,
        flight,
        block;

      //----------------------------------------------------------  OUT
      // OUT time must always be calculated
      millis = new Date(
        `${this.outDateString}T${this.outTimeString}:00.000+00:00`
      ).getTime();

      diff = Math.abs(new Date(this._std).getTime() - millis);

      // Invalid if difference between OUT and STD is more than 12 hours
      if (diff > 12 * 60 * 60 * 1000) {
        this.computedTimesErrors.push(
          "Difference between OUT and STD is more than 12 hours"
        );
        return {
          isValid: false,
        };
      }

      _out = millis;

      dateString = this.outDateString;

      //---------------------------------------------------------- SCHEDULED or BLOCKED-OUT
      if (
        this.selectedStatus === "SCHEDULED" ||
        this.selectedStatus === "BLOCKED-OUT"
      ) {
        //Calculate the IN time
        millis = new Date(
          `${dateString}T${this.inTimeString}:00.000+00:00`
        ).getTime();

        diff = millis - _out;

        if (diff <= 0) {
          // Use next day
          millis += 24 * 60 * 60 * 1000;
        }

        _in = millis;

        dateString = new Date(millis).toISOString().substring(0, 10);
      }

      //---------------------------------------------------------- AIRBORNE
      else if (this.selectedStatus === "AIRBORNE") {
        // Minimum values to calculate OFF
        if (!this.offTimeString) {
          this.computedTimesErrors.push("OFF time is invalid");
          return {
            isValid: false,
          };
        }

        //Calculate the OFF time
        millis = new Date(
          `${dateString}T${this.offTimeString}:00.000+00:00`
        ).getTime();

        diff = millis - _out;

        if (diff <= 0) {
          // Use next day
          millis += 24 * 60 * 60 * 1000;
        }

        _off = millis;

        dateString = new Date(millis).toISOString().substring(0, 10);

        //Calculate the IN time
        millis = new Date(
          `${dateString}T${this.inTimeString}:00.000+00:00`
        ).getTime();

        diff = millis - _off;

        if (diff <= 0) {
          // Use next day
          millis += 24 * 60 * 60 * 1000;
        }

        _in = millis;

        dateString = new Date(millis).toISOString().substring(0, 10);
      }

      //---------------------------------------------------------- BLOCKED-IN
      if (this.selectedStatus === "BLOCKED-IN") {
        // Minimum values to calculate OFF
        if (!this.noTakeoff) {
          if (!this.offTimeString) {
            this.computedTimesErrors.push("OFF time is invalid");
            return {
              isValid: false,
            };
          }

          if (!this.onTimeString) {
            this.computedTimesErrors.push("ON time is invalid");
            return {
              isValid: false,
            };
          }

          //Calculate the OFF time
          millis = new Date(
            `${dateString}T${this.offTimeString}:00.000+00:00`
          ).getTime();

          diff = millis - _out;

          if (diff <= 0) {
            // Use next day
            millis += 24 * 60 * 60 * 1000;
          }

          _off = millis;

          dateString = new Date(millis).toISOString().substring(0, 10);

          //Calculate the ON time
          millis = new Date(
            `${dateString}T${this.onTimeString}:00.000+00:00`
          ).getTime();

          diff = millis - _out;

          if (diff <= 0) {
            // Use next day
            millis += 24 * 60 * 60 * 1000;
          }

          _on = millis;

          dateString = new Date(millis).toISOString().substring(0, 10);

          //Calculate the IN time
          millis = new Date(
            `${dateString}T${this.inTimeString}:00.000+00:00`
          ).getTime();

          diff = millis - _on;

          if (diff <= 0) {
            // Use next day
            millis += 24 * 60 * 60 * 1000;
          }

          _in = millis;
        } else {
          //Calculate the IN time
          millis = new Date(
            `${dateString}T${this.inTimeString}:00.000+00:00`
          ).getTime();

          diff = millis - _out;

          if (diff <= 0) {
            // Use next day
            millis += 24 * 60 * 60 * 1000;
          }

          _in = millis;
        }
      }

      let h, m;

      //------------------------------------------------------------- Block Time
      totalMinutes = (_in - _out) / (60 * 1000);

      if (
        totalMinutes < this.minimumBlock ||
        totalMinutes > this.selectedAircraft.rangeInMinutes
      ) {
        return {
          isValid: false,
        };
      }

      h = Math.floor((totalMinutes % (24 * 60)) / 60); // Calculate remaining hours
      m = totalMinutes % 60; // Calculate remaining minutes

      block = {
        totalMinutes,
        hours: h,
        minutes: m,
        text: `${h.toString().padStart(2, "0")}+${m
          .toString()
          .padStart(2, "0")}`,
      };

      //------------------------------------------------------------- Flight Time

      if (_off && _on) {
        totalMinutes = (_on - _off) / (60 * 1000);

        if (
          totalMinutes < this.minimumFlight ||
          totalMinutes > this.selectedAircraft.rangeInMinutes
        ) {
          return {
            isValid: false,
          };
        }

        h = Math.floor((totalMinutes % (24 * 60)) / 60); // Calculate remaining hours
        m = totalMinutes % 60; // Calculate remaining minutes

        flight = {
          totalMinutes,
          hours: h,
          minutes: m,
          text: `${h.toString().padStart(2, "0")}+${m
            .toString()
            .padStart(2, "0")}`,
        };
      } else {
        flight = {
          totalMinutes: 0,
          text: "00+00",
        };
      }

      return {
        _out,
        _off,
        _on,
        _in,
        flight,
        block,
        isValid: true,
      };
    },

    isValid() {
      return (
        this.computedTimes.isValid &&
        this.computedOrigin &&
        this.computedDestination &&
        this.isValidDepartureFuel &&
        this.isValidArrivalFuel
      );
    },
  },

  methods: {
    handleAssignCrew() {
      const assignmentData = {
        type: "flight",
        flight: this.flight,
      };
      this.$store.commit("updateAssignmentData", assignmentData);
      this.$router.push({ name: "assignmentBoard" });
    },

    getRowStyle(item) {
      let style = {
        backgroundColor: "#6EE7B7",
      };

      if (item.required && !item.employee) {
        style.backgroundColor = "#fca5a5";
      } else {
        if (!item.required) {
          style.backgroundColor = "#93C5FD";
        }
      }

      return style;
    },
    formatCrewmember(crewmember) {
      if (crewmember.employee) {
        return `${crewmember.employee.surname}, ${crewmember.employee.givenName}`;
      } else {
        if (crewmember.required) {
          return "Please Assign.";
        } else {
          return "Not Required";
        }
      }
    },

    formatPaxsBooked() {
      return `${this.bookedPaxAdults} + ${this.bookedPaxInfants}`;
    },

    formatPaxsActual() {
      return `${this.actualPaxAdults} + ${this.actualPaxInfants}`;
    },

    formatDelayCodes() {
      if (this.delays.length) {
        let codes = [];

        this.delays.forEach((item) => {
          codes.push(item.code);
        });

        return codes.join();
      } else {
        return "No delay codes";
      }
    },

    findDuty(seat) {
      switch (seat) {
        case "CAPT":
          return "Captain";
        case "FO":
          return "First Officer";
        case "ACM-1":
          return "Additional Crewmember";

        case "FA-A":
          return "Flight Attendant A";

        case "FA-B":
          return "Flight Attendant B";

        case "FA-C":
          return "Flight Attendant ";

        case "FA-D":
          return "Flight Attendant ";

        case "FA-E":
          return "Flight Attendant ";

        case "FA-F":
          return "Flight Attendant ";

        case "GSC":
          return "Ground Security Coordinator";

        case "MX":
          return "Flight Mechanic";

        case "DH-1":
          return "Deadhead";

        case "DH-2":
          return "Deadhead";

        case "DH-3":
          return "Deadhead";

        case "DH-4":
          return "Deadhead";

        case "DH-5":
          return "Deadhead";

        case "DH-6":
          return "Deadhead";

        case "ACM-2":
          return "Additional Crewmember";

        case "ACM-3":
          return "Additional Crewmember";

        case "ACM-4":
          return "Additional Crewmember";

        case "ACM-5":
          return "Additional Crewmember";

        case "ACM-6":
          return "Additional Crewmember";

        case "ACM-7":
          return "Additional Crewmember";

        case "ACM-8":
          return "Additional Crewmember";

        default:
          return "Undefined";
      }
    },

    handleResetCrewmembers() {
      this.crewmembers = [];
      this.crewmembers = [...originalCrewmembers];
    },

    handleRemoveEmployee(crewmember, e) {
      e.stopPropagation();

      const i = this.crewmembers.findIndex((item) => {
        return crewmember.seat === item.seat;
      });

      let cm = {
        seat: crewmember.seat,
        duty: crewmember.duty,
        required: crewmember.required ? true : false,
      };

      if (i >= 0) {
        this.crewmembers.splice(i, 1, cm);
      }
    },

    handleToggleRequired(crewmember) {
      const i = this.crewmembers.findIndex((item) => {
        return crewmember.seat === item.seat;
      });

      let cm = {
        seat: crewmember.seat,
        duty: crewmember.duty,
        required: !crewmember.required,
      };

      if (i >= 0) {
        this.crewmembers.splice(i, 1, cm);
      }
    },

    handleAddDelay() {
      if (this.delayList.length >= 5) {
        this.snackbarColor = "red";
        this.snackbarText = "The maximum number of delays is 5";
        this.snackbarVisible = true;
        return;
      }

      const i = this.delayList.findIndex((item) => {
        return item.code === this.selectedDelay.code;
      });

      if (i >= 0) {
        this.delayList.splice(i, 1, { ...this.selectedDelay });
      } else {
        this.delayList.push({ ...this.selectedDelay });
      }
    },

    handleRemoveDelay(code) {
      const i = this.delayList.findIndex((item) => {
        return item.code === code;
      });

      if (i >= 0) {
        this.delayList.splice(i, 1);
      }
    },

    handleShowDelaysDialog() {
      this.delayList = [...this.delays];
      this.selectedDelay = { ...this.delayOptions[0] };
      this.showDelaysDialog = true;
    },

    handleUpdateDelays() {
      this.delays = [...this.delayList];
      this.showDelaysDialog = false;
    },

    handleShowPaxsDialog() {
      this.actualPaxAdultsInput = this.actualPaxAdults;
      this.actualPaxInfantsInput = this.actualPaxInfants;
      this.bookedPaxAdultsInput = this.bookedPaxAdults;
      this.bookedPaxInfantsInput = this.bookedPaxInfants;

      this.showPaxsDialog = true;
    },

    handleUpdatePaxs() {
      if (!this.isValidBookedPaxCount || !this.isValidActualPaxCount) {
        return;
      }
      this.actualPaxAdults = this.actualPaxAdultsInput;
      this.actualPaxInfants = this.actualPaxInfantsInput;
      this.bookedPaxAdults = this.bookedPaxAdultsInput;
      this.bookedPaxInfants = this.bookedPaxInfantsInput;

      this.showPaxsDialog = false;
    },

    handleShowFuelDialog() {
      if (!this.enableFuelUplift) {
        return;
      }
      this.fuelTicketList = [...this.fuelTickets];

      this.fuelTicketVendor = "";
      this.fuelTicketInvoiceNumber = "";
      this.fuelTicketQuantity = "";

      this.showFuelDialog = true;
    },

    handleAddFuelTicket() {
      if (this.fuelTicketList.length >= 20) {
        this.snackbarColor = "red";
        this.snackbarText = "The maximum number of fuel invoices is 20";
        this.snackbarVisible = true;
        return;
      }

      if (!this.isValidFuelTicket) {
        return;
      }

      const i = this.fuelTicketList.findIndex((item) => {
        return item.invoiceNumber === this.fuelTicketInvoiceNumber;
      });

      const fuelTicket = {
        vendor: this.fuelTicketVendor.trim(),
        invoiceNumber: this.fuelTicketInvoiceNumber.trim(),
        quantity: this.fuelTicketQuantity,
      };

      if (i >= 0) {
        this.fuelTicketList.splice(i, 1, fuelTicket);
      } else {
        this.fuelTicketList.push(fuelTicket);
      }

      this.fuelTicketInvoiceNumber = "";
      this.fuelTicketQuantity = 0;
    },

    handleRemoveFuelTicket(invoiceNumber) {
      const i = this.fuelTicketList.findIndex((item) => {
        return item.invoiceNumber === invoiceNumber;
      });

      if (i >= 0) {
        this.fuelTicketList.splice(i, 1);
        this.fuelTicketVendor = "";
        this.fuelTicketInvoiceNumber = "";
        this.fuelTicketQuantity = "";
      }
    },

    handleUpdateFuel() {
      if (!this.fuelTicketList.length) {
        return;
      }

      let uplift = 0;

      this.fuelTicketList.forEach((item) => {
        uplift += item.quantity;
      });

      this.fuelTickets = [...this.fuelTicketList];

      this.fuelUplift = uplift;

      this.showFuelDialog = false;
    },

    handleShowComputedTimesErrors() {
      this.showComputedTimesErrorsDialog = true;
    },

    handleUpdateStatus() {
      switch (this.selectedStatus) {
        case "AIRBORNE":
          this.onTimeString = null;

          this.enableOffTime = true;
          this.enableOnTime = false;
          this.enableFuelRemaining = false;
          this.enableFuelUplift = false;

          this.outDynamicLabel = "OUT";
          this.offDynamicLabel = "OFF";
          this.onDynamicLabel = "ON";
          this.inDynamicLabel = "ETA";

          this.arrivalFuel = 0;
          break;

        case "BLOCKED-IN":
          this.enableOffTime = true;
          this.enableOnTime = true;
          this.enableFuelRemaining = true;
          this.enableFuelUplift = false;

          this.outDynamicLabel = "OUT";
          this.offDynamicLabel = "OFF";
          this.onDynamicLabel = "ON";
          this.inDynamicLabel = "IN";
          break;

        case "BLOCKED-OUT":
          this.offTimeString = null;
          this.onTimeString = null;

          this.enableOffTime = false;
          this.enableOnTime = false;
          this.enableFuelRemaining = false;
          this.enableFuelUplift = false;

          this.outDynamicLabel = "OUT";
          this.offDynamicLabel = "OFF";
          this.onDynamicLabel = "ON";
          this.inDynamicLabel = "ETA";

          this.arrivalFuel = 0;
          break;

        default: //"SCHEDULED"
          this.offTimeString = null;
          this.onTimeString = null;

          this.enableOffTime = false;
          this.enableOnTime = false;
          this.enableFuelRemaining = false;
          this.enableFuelUplift = true;

          this.outDynamicLabel = "ETD";
          this.offDynamicLabel = "OFF";
          this.onDynamicLabel = "ON";
          this.inDynamicLabel = "ETA";

          this.arrivalFuel = 0;
          break;
      }
    },

    async handleSave() {
      if (!this.isValid) {
        return;
      }

      try {
        this.loading = true;

        let res = await this.$store.dispatch("precheckAircraftEvent", {
          _id: this._id,
          aircraftId: this.selectedAircraft._id,
          _start: this.computedTimes._out,
          _end: this.computedTimes._in,
        });

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        const conflicts = res.data.conflicts;

        if (conflicts.length === 0) {
          let unscheduledEvent;
          let destinationId;

          // unscheduledEventOptions
          //   [
          //     "None",
          //     "Air Turn Back (Airborne)",
          //     "Air Turn Back (Blocked-In)",
          //     "Block Turn Back",
          //     "Diversion (Airborne)",
          //     "Diversion (Blocked-In)",
          //   ]

          //unscheduledEvent = ""

          if (this.selectedStatus === "BLOCKED-IN") {
            if (this.noTakeoff) {
              destinationId = this.computedOrigin._id;
              unscheduledEvent = "Block Turn Back";
            } else {
              if (
                this.computedDestination._id !== this.plannedDestination._id
              ) {
                if (this.computedDestination._id === this.computedOrigin._id) {
                  unscheduledEvent = "Air Turn Back (Blocked-In)";
                } else {
                  unscheduledEvent = "Diversion (Blocked-In)";
                }
                destinationId = this.computedDestination._id;
              } else {
                destinationId = this.computedDestination._id;
                unscheduledEvent = "None";
              }
            }
          } else if (this.selectedStatus === "AIRBORNE") {
            if (this.computedDestination._id !== this.plannedDestination._id) {
              if (this.computedDestination._id === this.computedOrigin._id) {
                unscheduledEvent = "Air Turn Back (Airborne)";
              } else {
                unscheduledEvent = "Diversion (Airborne)";
              }
              destinationId = this.computedDestination._id;
            } else {
              destinationId = this.computedDestination._id;
              unscheduledEvent = "None";
            }
          } else {
            destinationId = this.computedDestination._id;
            unscheduledEvent = "None";
          }

          const aircraftEvent = {
            _id: this._id,
            type: "Flight",
            status: this.selectedStatus,
            flightRules: this.selectedFlightRules,
            unscheduledEvent,
            logpage: this.logpage,
            aircraftId: this.selectedAircraft._id,
            customerId: this.selectedCustomer._id,
            flightNumber: this.flightNumber.toUpperCase(),
            actualPaxAdults: this.actualPaxAdults,
            actualPaxInfants: this.actualPaxInfants,
            bookedPaxAdults: this.bookedPaxAdults,
            bookedPaxInfants: this.bookedPaxInfants,
            fuelTickets: this.fuelTickets,
            departureFuel: this.departureFuel,
            arrivalFuel: this.arrivalFuel,
            parkingDeparture: this.parkingDeparture
              ? this.parkingDeparture.toUpperCase()
              : "",
            parkingArrival: this.parkingArrival
              ? this.parkingArrival.toUpperCase()
              : "",
            hidden: this.hidden ? true : false,
            noTakeoff: this.noTakeoff ? true : false,
            deiced: this.deiced ? true : false,
            needsUsCrew: this.needsUsCrew ? true : false,
            originId: this.computedOrigin._id,
            destinationId,
            _start: this.computedTimes._out,
            _end: this.computedTimes._in,
            _off: this.computedTimes._off,
            _on: this.computedTimes._on,
            crewmembers: this.crewmembers,
            delays: this.delays,
            duration: this.computedTimes.block,
            remarks: this.remarks ? this.remarks : null,
            updatedById: this.auth.employee._id,
          };

          res = await this.$store.dispatch("saveAircraftEvent", aircraftEvent);

          if (res.status !== 200) {
            this.snackbarColor = "red";
            this.snackbarText = res.message;
            this.snackbarVisible = true;
            return;
          }

          this.handleGoBack();
        } else {
          this.snackbarColor = "red";
          this.snackbarText = `Unable to save schedule due to conlicts.`;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.snackbarVisible = false;
          }, 3000);
        }
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    formatDateShort(miliseconds) {
      const date = new Date(miliseconds);

      let options = {
        timeZone: "UTC",
        month: "short",
        day: "numeric",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
  },
};
</script>

<style scoped>
.main-grid {
  display: grid;
  padding: 5px 20px;
  grid-template-columns: minmax(100px, 100px);
  column-gap: 15px;
  row-gap: 5px;
  align-items: center;
  margin: 0;
}

.grid-row-one,
.grid-row-two,
.grid-row-three,
.grid-row-four,
.grid-row-five,
.grid-row-six,
.grid-footer {
  grid-column: 1/13;
}

/* @media (min-width: 768px) {
  .grid-customer {
    grid-column: 1/4;
    grid-row: 1;
  }

  .grid-aircraft {
    grid-column: 4/6;
    grid-row: 1;
  }

  .grid-status {
    grid-column: 6/8;
    grid-row: 1;
  }

  .grid-flight-rules {
    grid-column: 8/10;
    grid-row: 1;
  }

  .grid-logpage {
    grid-column: 10/12;
    grid-row: 1;
  }

  .grid-date {
    grid-column: 1/3;
    grid-row: 2;
  }

  .grid-flight-number {
    grid-column: 3/4;
    grid-row: 2;
  }

  .grid-origin {
    grid-column: 4/5;
    grid-row: 2;
  }

  .grid-destination {
    grid-column: 5/6;
    grid-row: 2;
  }

  .grid-out {
    grid-column: 6/7;
    grid-row: 2;
  }

  .grid-off {
    grid-column: 7/8;
    grid-row: 2;
  }

  .grid-on {
    grid-column: 8/9;
    grid-row: 2;
  }

  .grid-in {
    grid-column: 9/10;
    grid-row: 2;
  }

  .grid-flight {
    grid-column: 10/11;
    grid-row: 2;
  }

  .grid-block {
    grid-column: 11/12;
    grid-row: 2;
  }

  .grid-toggles {
    grid-column: 1/13;
    grid-row: 3;
  }

  .grid-remarks {
    grid-column: 1/13;
    grid-row: 19;
  }

  .grid-actions {
    grid-column: 12/13;
    grid-row: 20;
  }
} */

textarea {
  resize: none;
}

.aircraft-events-table {
  color: #fff;
  font-size: 1.3rem;
  padding: 0 5px;
  grid-column: 1/13;
}

.grid-card {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 0 5px;
  margin-bottom: 1px;
  background-color: #cef6f5;
  color: black;
  cursor: pointer;
}

.footer {
  color: #fff;
  font-size: 1.3rem;
  margin-top: 5px;
  padding: 0 5px;
  grid-column: 1/13;
}

.warning {
  background-color: red;
  color: white;
}

.edit-mode {
  background-color: yellowgreen;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.aircraft-operational {
  background-color: #f5d0a9;
}

.is-invalid {
  background-color: #fef08a;
}

.fuel-ticket {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 0 5px;
  margin-bottom: 1px;
  background-color: #cef6f5;
  color: black;
  cursor: pointer;
}
</style>
