export const mixin = {
  data() {
    return {
      jan2100: 4102444800000, //UNIX Epoch Jan 1st, 2100 @ 00:00 UTC
      aircraftTypes: [
        "737",
        "738",
        "744",
        "747",
        "748",
        "763",
        "74Y",
        "76F",
        "77F",
        "LCF",
        "PAX",
      ],
      loadmasterRoles: ["Loadmaster", "Check Loadmaster", "Senior Loadmaster"],
      psrRoles: ["PSR", "Check PSR", "Lead PSR", "Senior PSR"],
      cargoJobTitles: [
        "Check Loadmaster",
        "Senior Loadmaster",
        "Loadmaster",
        "Loadmaster Training",
        "Contract Loadmaster",
        "Loadmaster Trainee",
        "Loadmaster/PSR",
        "Special Loads/CLC",
        "Mechanic",
        "Jumpseat",
        "Station Rep.",
        "Station Representative (Not W&B Qualified)",
        "Coordinator (Vendors)",
      ],
      passengerJobTitles: [
        "PSR",
        "Lead PSR",
        "Senior PSR",
        "Check PSR",
        "PSR Training",
        "PAA",
        "PAA Training",
        "Loadmaster/PSR",
        "PSR/PAA Trainee",
        "POC",
        "GSC",
        "Mechanic",
        "Jumpseat",
        "Station Rep.",
        "Station Representative (Not W&B Qualified)",
        "Coordinator (Vendors)",
      ],
      delayOptions: [
        {
          code: 0,
          category: "Airline Internal Code",
          alphaCode: "W0",
          descriptionShort: "00 - AIRCRAFT CHANGE",
          descriptionLong: "Aircraft Change",
        },
        {
          code: 1,
          category: "Airline Internal Code",
          alphaCode: "W1",
          descriptionShort: "01 - DELAY DUE TO TSA",
          descriptionLong: "Delay due to TSA",
        },
        {
          code: 2,
          category: "Airline Internal Code",
          alphaCode: "W2",
          descriptionShort: "02 - WORLD ATLANTIC SPECIFIC 3",
          descriptionLong: "Need description",
        },
        {
          code: 3,
          category: "Airline Internal Code",
          alphaCode: "W3",
          descriptionShort: "03 - WORLD ATLANTIC SPECIFIC 4",
          descriptionLong: "Need description",
        },
        {
          code: 4,
          category: "Airline Internal Code",
          alphaCode: "W4",
          descriptionShort: "04 - WORLD ATLANTIC SPECIFIC 5",
          descriptionLong: "Need description",
        },
        {
          code: 5,
          category: "Airline Internal Code",
          alphaCode: "W5",
          descriptionShort: "05 - WORLD ATLANTIC SPECIFIC 6",
          descriptionLong: "Need description",
        },
        {
          code: 6,
          category: "Other",
          alphaCode: "OA",
          descriptionShort: "06 - NO GATE/STAND AVAILABILITY",
          descriptionLong: "Due to own airline activity",
        },
        {
          code: 9,
          category: "Other",
          alphaCode: "SG",
          descriptionShort: "09 -SCHEDULED GROUND TIME",
          descriptionLong: "Less than declared minimum ground time",
        },
        {
          code: 11,
          category: "Passenger and Baggage",
          alphaCode: "PD",
          descriptionShort: "11- LATE CHECK-IN",
          descriptionLong: "Acceptance of passengers after deadline",
        },
        {
          code: 12,
          category: "Passenger and Baggage",
          alphaCode: "PL",
          descriptionShort: "12 - LATE CHECK-IN",
          descriptionLong: "Congestion in check-in area",
        },
        {
          code: 13,
          category: "Passenger and Baggage",
          alphaCode: "PE",
          descriptionShort: "13 - CHECK-IN ERROR",
          descriptionLong: "Error with passenger or baggage details",
        },
        {
          code: 14,
          category: "Passenger and Baggage",
          alphaCode: "PO",
          descriptionShort: "14 - OVERSALES",
          descriptionLong: "Booking errors",
        },
        {
          code: 15,
          category: "Passenger and Baggage",
          alphaCode: "PH",
          descriptionShort: "15 - BOARDING",
          descriptionLong:
            "Discrepancies and paging, missing checked-in passenger",
        },
        {
          code: 16,
          category: "Passenger and Baggage",
          alphaCode: "PS",
          descriptionShort: "16 - COMMERCIAL PUBLICITY/ PASSENGER CONVENIENCE",
          descriptionLong:
            "VIP, press, ground meals and missing personal items",
        },
        {
          code: 17,
          category: "Passenger and Baggage",
          alphaCode: "PC",
          descriptionShort: "17 - CATERING ORDER",
          descriptionLong: "Late or incorrect order given to supplier",
        },
        {
          code: 18,
          category: "Passenger and Baggage",
          alphaCode: "PB",
          descriptionShort: "18 - BAGGAGE PROCESSING",
          descriptionLong: "Late or incorrectly sorted baggage",
        },
        {
          code: 21,
          category: "Cargo and Mail",
          alphaCode: "CD",
          descriptionShort: "21 -DOCUMENTATION",
          descriptionLong: "Late or incorrect documentation for booked cargo",
        },
        {
          code: 22,
          category: "Cargo and Mail",
          alphaCode: "CP",
          descriptionShort: "22 - LATE POSITIONING",
          descriptionLong: "Late delivery of booked cargo to airport/aircraft",
        },
        {
          code: 23,
          category: "Cargo and Mail",
          alphaCode: "CC",
          descriptionShort: "23 - LATE ACCEPTANCE",
          descriptionLong: "Acceptance of cargo after deadline",
        },
        {
          code: 24,
          category: "Cargo and Mail",
          alphaCode: "CI",
          descriptionShort: "24 - INADEQUATE PACKING",
          descriptionLong: "Repackaging and / or re-labeling of booked cargo",
        },
        {
          code: 25,
          category: "Cargo and Mail",
          alphaCode: "CO",
          descriptionShort: "25 - OVERSALES",
          descriptionLong:
            "Booked load in excess of saleable load capacity (weight or volume), resulting in reloading or off-load",
        },
        {
          code: 26,
          category: "Cargo and Mail",
          alphaCode: "CU",
          descriptionShort: "26 - LATE PREPARATION",
          descriptionLong: "Late preparation in warehouse",
        },
        {
          code: 27,
          category: "Mail Only",
          alphaCode: "CE",
          descriptionShort: "27 - DOCUMENTATION, PACKING",
          descriptionLong: "Incomplete and / or inaccurate mail documentation",
        },
        {
          code: 28,
          category: "Mail Only",
          alphaCode: "CL",
          descriptionShort: "28 - LATE POSITIONING",
          descriptionLong: "Late delivery of mail to airport / aircraft",
        },
        {
          code: 29,
          category: "Mail Only",
          alphaCode: "CA",
          descriptionShort: "29 - LATE ACCEPTANCE",
          descriptionLong: "Acceptance of mail after deadline",
        },
        {
          code: 31,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GD",
          descriptionShort: "31 - LATE / INACCURATE AIRCRAFT DOCUMENTATION",
          descriptionLong:
            "Late or inaccurate mass and balance documentation, general declaration, passenger manifest",
        },
        {
          code: 32,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GL",
          descriptionShort: "32 - LOADING / UNLOADING",
          descriptionLong: "Bulky items, special load, lack loading staff",
        },
        {
          code: 33,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GE",
          descriptionShort: "33 - LOADING EQUIPMENT",
          descriptionLong:
            "Lack of and / or breakdown; lack of operating staff",
        },
        {
          code: 34,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GS",
          descriptionShort: "34 - SERVICING EQUIPMENT",
          descriptionLong:
            "Lack of and / or breakdown; lack of operating staff",
        },
        {
          code: 35,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GC",
          descriptionShort: "35 - AIRCRAFT CLEANING",
          descriptionLong: "Late completion of aircraft cleaning",
        },
        {
          code: 36,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GF",
          descriptionShort: "36 - FUELING / DEFUELING",
          descriptionLong: "Late delivery of fuel; excludes late request",
        },
        {
          code: 37,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GB",
          descriptionShort: "37 - CATERING",
          descriptionLong: "Late and / or incomplete delivery; late loading",
        },
        {
          code: 38,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GU",
          descriptionShort: "38 - ULD",
          descriptionLong: "Lack of and / or unserviceable ULD's or pallets",
        },
        {
          code: 39,
          category: "Aircraft and Ramp Handling",
          alphaCode: "GT",
          descriptionShort: "39 - TECHNICAL EQUIPMENT",
          descriptionLong:
            "Lack and / or breakdown; lack of operating staff; includes GPU, air start, pushback tug, de-icing",
        },
        {
          code: 41,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TD",
          descriptionShort: "41 - TECHNICAL DEFECTS",
          descriptionLong: "Aircraft defects including items covered by MEL",
        },
        {
          code: 42,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TM",
          descriptionShort: "42 - SCHEDULED MAINTENANCE",
          descriptionLong: "Late release from maintenance",
        },
        {
          code: 43,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TN",
          descriptionShort: "43 - NON-SCHEDULED MAINTENANCE",
          descriptionLong:
            "Special checks and / or additional works beyond normal maintenance schedule",
        },
        {
          code: 44,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TS",
          descriptionShort: "44 - SPARES AND MAINTENANCE",
          descriptionLong:
            "Lack of spares, lack of and / or breakdown of specialist equipment required for defect rectification",
        },
        {
          code: 45,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TA",
          descriptionShort: "45 - AOG SPARES",
          descriptionLong:
            "Awaiting AOG spare(s) to be carried to another station",
        },
        {
          code: 46,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TC",
          descriptionShort: "46 - AIRCRAFT CHANGE",
          descriptionLong:
            "For technical reasons, e.g. a prolonged technical delay",
        },
        {
          code: 47,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TL",
          descriptionShort: "47 - STANDBY AIRCRAFT",
          descriptionLong: "Standby aircraft unavailable for technical reasons",
        },
        {
          code: 48,
          category: "Technical and Aircraft Equipment",
          alphaCode: "TV",
          descriptionShort: "48 - CABIN CONFIGURATION",
          descriptionLong:
            "Scheduled cabin configuration and version adjustments",
        },
        {
          code: 51,
          category: "Damage to Aircraft",
          alphaCode: "DF",
          descriptionShort: "51 - DAMAGE DURING FLIGHT OPERATIONS",
          descriptionLong:
            "Bird or lightning strike, turbulence, heavy or overweight landing, collisions during taxiing",
        },
        {
          code: 52,
          category: "Damage to Aircraft",
          alphaCode: "DG",
          descriptionShort: "52 - DAMAGE DURING GROUND OPERATIONS",
          descriptionLong:
            "Collisions (other than taxiing), loading / offloading damage, towing, contamination, extreme weather conditions",
        },
        {
          code: 55,
          category: "EDP / Automated Equipment Failure",
          alphaCode: "ED",
          descriptionShort: "55 - DEPARTURE CONTROL",
          descriptionLong:
            "Failure of automated systems, including check-in; load control systems producing mass and balance",
        },
        {
          code: 56,
          category: "EDP / Automated Equipment Failure",
          alphaCode: "EC",
          descriptionShort: "56 - CARGO PREPARATION DOCUMENTATION",
          descriptionLong:
            "Failure of documentation and / or load control systems covering cargo",
        },
        {
          code: 57,
          category: "EDP / Automated Equipment Failure",
          alphaCode: "EF",
          descriptionShort: "57 - FLIGHT PLANS",
          descriptionLong: "Failure of automated flight plan systems",
        },
        {
          code: 58,
          category: "EDP / Automated Equipment Failure",
          alphaCode: "EO",
          descriptionShort: "58 - OTHER AUTOMATED SYSTEM",
          descriptionLong: "Other automated system",
        },
        {
          code: 61,
          category: "Flight Operations and Crewing",
          alphaCode: "FP",
          descriptionShort: "61 - FLIGHT PLAN",
          descriptionLong: "Late completion of or change to flight plan",
        },
        {
          code: 62,
          category: "Flight Operations and Crewing",
          alphaCode: "FF",
          descriptionShort: "62 - OPERATIONAL REQUIREMENT",
          descriptionLong: "Late alteration to fuel or payload",
        },
        {
          code: 63,
          category: "Flight Operations and Crewing",
          alphaCode: "FT",
          descriptionShort: "63 - LATE CREW BOARDING OR DEPARTURE PROCEDURES",
          descriptionLong:
            "Late flight deck, or entire crew, other than standby; late completion of flight deck crew checks",
        },
        {
          code: 64,
          category: "Flight Operations and Crewing",
          alphaCode: "FS",
          descriptionShort: "64 - FLIGHT DECK CREW SHORTAGE",
          descriptionLong:
            "Sickness, awaiting standby, flight time limitations, valid visa, health documents, etc.",
        },
        {
          code: 65,
          category: "Flight Operations and Crewing",
          alphaCode: "FR",
          descriptionShort: "65 - FLIGHT DECK CREW SPECIAL REQUEST",
          descriptionLong: "Requests not within operational requirements",
        },
        {
          code: 66,
          category: "Flight Operations and Crewing",
          alphaCode: "FL",
          descriptionShort:
            "66 - LATE CABIN CREW BOARDING OR DEPARTURE PROCEDURES",
          descriptionLong:
            "Late cabin crew other than standby; late completion of cabin crew checks",
        },
        {
          code: 67,
          category: "Flight Operations and Crewing",
          alphaCode: "FC",
          descriptionShort: "67 - CABIN CREW SHORTAGE",
          descriptionLong:
            "Sickness, awaiting standby, flight time limitations, valid visa, health documents",
        },
        {
          code: 68,
          category: "Flight Operations and Crewing",
          alphaCode: "FA",
          descriptionShort: "68 - CABIN CREW ERROR OR SPECIAL REQUEST",
          descriptionLong: "Requests not within operational requirements",
        },
        {
          code: 69,
          category: "Flight Operations and Crewing",
          alphaCode: "FB",
          descriptionShort: "69 - CAPTAIN REQUEST FOR SECURITY CHECK",
          descriptionLong:
            "Extraordinary requests outside mandatory requirements",
        },
        {
          code: 71,
          category: "Weather",
          alphaCode: "WO",
          descriptionShort: "71 - DEPARTURE STATION",
          descriptionLong: "Below operating limits",
        },
        {
          code: 72,
          category: "Weather",
          alphaCode: "WT",
          descriptionShort: "72 - DESTINATION STATION",
          descriptionLong: "Below operating limits",
        },
        {
          code: 73,
          category: "Weather",
          alphaCode: "WR",
          descriptionShort: "73 - EN-ROUTE OR ALTERNATE",
          descriptionLong: "Below operating limits",
        },
        {
          code: 75,
          category: "Weather",
          alphaCode: "WI",
          descriptionShort: "75 - DE-ICING OF AIRCRAFT",
          descriptionLong:
            "Removal of ice and / or snow; excludes equipment  lack of or breakdown",
        },
        {
          code: 76,
          category: "Weather",
          alphaCode: "WS",
          descriptionShort:
            "76 - REMOVAL OF SNOW, ICE, WATER, AND SAND FROM AIRPORT",
          descriptionLong: "Runway, taxiway conditions",
        },
        {
          code: 77,
          category: "Weather",
          alphaCode: "WG",
          descriptionShort:
            "77 - GROUND HANDLING IMPAIRED BY ADVERSE WEATHER CONDITIONS",
          descriptionLong: "High winds, heavy rain, blizzards, monsoons etc.",
        },
        {
          code: 81,
          category: "Air Traffic Flow Management Restrictions",
          alphaCode: "AT",
          descriptionShort: "81 - ATFM DUE TO ATC EN-ROUTE DEMAND / CAPACITY",
          descriptionLong: "Standard demand / capacity problems",
        },
        {
          code: 82,
          category: "Air Traffic Flow Management Restrictions",
          alphaCode: "AX",
          descriptionShort: "82 - ATFM DUE TO ATC STAFF / EQUIPMENT ENROUTE",
          descriptionLong:
            "Reduced capacity caused by industrial action or staff shortage, equipment failure, military exercise or extraordinary demand due to capacity reduction in neighbouring area",
        },
        {
          code: 83,
          category: "Air Traffic Flow Management Restrictions",
          alphaCode: "AE",
          descriptionShort:
            "83 - ATFM DUE TO RESTRICTION AT DESTINATION AIRPORT",
          descriptionLong:
            "Airport and / or runway closed due to obstruction, industrial action, staff shortage, political unrest, noise abatement, night curfew, special flights",
        },
        {
          code: 84,
          category: "Air Traffic Flow Management Restrictions",
          alphaCode: "AW",
          descriptionShort: "84 - ATFM DUE TO WEATHER AT DESTINATION",
          descriptionLong:
            "Airport and / or runway closed due to weather at destination",
        },
        {
          code: 85,
          category: "Airport and Governmental Authorities",
          alphaCode: "AS",
          descriptionShort: "85 - MANDATORY SECURITY",
          descriptionLong: "Passengers, baggage, crew, etc.",
        },
        {
          code: 86,
          category: "Airport and Governmental Authorities",
          alphaCode: "AG",
          descriptionShort: "86 - IMMIGRATION, CUSTOMS, HEALTH",
          descriptionLong: "Passengers, crew",
        },
        {
          code: 87,
          category: "Airport and Governmental Authorities",
          alphaCode: "AF",
          descriptionShort: "87 - AIRPORT FACILITIES",
          descriptionLong:
            "Parking stands, ramp congestion, lighting, buildings, gate limitations etc.",
        },
        {
          code: 88,
          category: "Airport and Governmental Authorities",
          alphaCode: "AD",
          descriptionShort: "88 - RESTRICTIONS AT DESTINATION AIRPORT",
          descriptionLong:
            "Airport and / or runway closed due to obstruction industrial action, staff shortage, political unrest, noise abatement, night curfew, special flights",
        },
        {
          code: 89,
          category: "Airport and Governmental Authorities",
          alphaCode: "AM",
          descriptionShort: "89 - RESTRICTIONS AT AIRPORT OF DEPARTURE",
          descriptionLong:
            "Including air traffic services, start-up and pushback, airport and / or runway closed due to obstruction or weather (restriction due to weather in case of ATFM only) industrial action, staff shortage, political unrest, noise abatement, night curfew, special flights",
        },
        {
          code: 91,
          category: "Reactionary",
          alphaCode: "RL",
          descriptionShort: "91 - LOAD CONNECTION",
          descriptionLong: "Awaiting load from another flight",
        },
        {
          code: 92,
          category: "Reactionary",
          alphaCode: "RT",
          descriptionShort: "92 - THROUGH CHECK-IN ERROR",
          descriptionLong:
            "Passenger or baggage check-in error at originating station",
        },
        {
          code: 93,
          category: "Reactionary",
          alphaCode: "RA",
          descriptionShort: "93 - AIRCRAFT ROTATION",
          descriptionLong:
            "Late arrival of aircraft from another flight or previous sector",
        },
        {
          code: 94,
          category: "Reactionary",
          alphaCode: "RS",
          descriptionShort: "94 - CABIN CREW ROTATION",
          descriptionLong: "Awaiting cabin crew from another flight",
        },
        {
          code: 95,
          category: "Reactionary",
          alphaCode: "RC",
          descriptionShort: "95 - CREW ROTATION",
          descriptionLong:
            "Awaiting flight deck, or entire crew, from another flight",
        },
        {
          code: 96,
          category: "Reactionary",
          alphaCode: "RO",
          descriptionShort: "96 - OPERATIONS CONTROL",
          descriptionLong:
            "Re-routing, diversion, consolidation, aircraft change for reasons other than technical",
        },
        {
          code: 97,
          category: "Miscellaneous",
          alphaCode: "MI",
          descriptionShort: "97 - INDUSTRIAL ACTION WITHIN OWN AIRLINE",
          descriptionLong: "Industrial action within own airline",
        },
        {
          code: 98,
          category: "Miscellaneous",
          alphaCode: "MO",
          descriptionShort: "98 - INDUSTRIAL ACTION OUTSIDE OWN AIRLINE",
          descriptionLong:
            "Industrial action (except Air Traffic Control Services)",
        },
        {
          code: 99,
          category: "Miscellaneous",
          alphaCode: "MX",
          descriptionShort: "99 - MISCELLANEOUS",
          descriptionLong: "No suitable code; explain reason(s) in plain text",
        },
      ],
      countries: [
        "United States",
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo (the Democratic Republic of the)",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (the Democratic People's Republic of)",
        "Korea (the Republic of)",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States Minor Outlying Islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Aland Islands",
      ],
      states: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      groundAssignmentTypes: [
        "Layover",
        "Travel",
        "Do Not Use",
        "Stand By",
        "Sick",
        "Special Assignment",
        "Floating Holiday",
        "Holiday",
        "Leave - Bereavement",
        "Leave - FMLA",
        "Leave - Medical",
        "Leave - Military",
        "Leave - Non-FMLA",
        "Leave - Parental",
        "Leave - Personal",
        "LOA - Jury Duty",
        "LOA - Long Term Disability",
        "LOA - Short Term Disability",
        "LOA - Workers' Compensation",
        "Quarantine - Day OFF",
        "Quarantine",
        "Training - Ground School",
        "Training - SIM",
        "Vacation",
      ],
      version: "2.0.0", //Deploy
      build: "25.04.06.1936", //Deploy
      environment: "DEV", //Deploy
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    employeeProfileImagesBucketUrl() {
      switch (this.environment) {
        case "DEV":
          return "https://dev-flighttimes-employee-profile-images.s3.amazonaws.com/";
        default:
          return "https://flighttimes-employee-profile-images.s3.amazonaws.com/";
      }
    },

    currentTimeX() {
      const ct = Date.now();
      const minutes = Math.ceil(
        (ct - new Date(this.dateFilter.start).getTime()) / 1000 / 60
      );

      if (minutes > 0) {
        return minutes / this.scale;
      } else {
        return 0;
      }
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },
  },

  methods: {
    //---------------------------------Training Warnings
    getTrainingWarnings(
      certificates,
      role,
      assignmentEnd,
      aircraftTypes,
      companyCodes
    ) {
      let warnings = [];
      let daysRemaining = 0;

      let sortedCertificates;

      let redCertificates = [];
      let amberCertificates = [];
      let blackCertificates = [];

      //  Get red, amber, and black required certificate lists
      switch (role) {
        case "Loadmaster":
          //Generic certificates, required for any aircraft type
          redCertificates = ["FACAOSSP", "SORT 1"];
          amberCertificates = ["DG"];
          blackCertificates = ["SMS", "DEICE"];

          //Aircraft specific certificates
          if (aircraftTypes.includes("76F")) {
            redCertificates.push("B767WB");
          }

          if (aircraftTypes.includes("77F")) {
            redCertificates.push("B777_dif");
          }

          if (aircraftTypes.includes("LCF")) {
            redCertificates.push("LCFWB");
            redCertificates.push("LCFGEN");
            redCertificates.push("LCFLCL");
            redCertificates.push("LCFMTS");
            redCertificates.push("gmlf 1.1");
          }

          if (aircraftTypes.includes("737") || aircraftTypes.includes("738")) {
            blackCertificates.push("737SDT");
          }

          break;
        case "PSR":
        case "POC":
        case "GSC":
          // Note: Aircraft types have no effect on required certificates
          redCertificates = ["B747 PAXS", "TSAAOSSP"];
          amberCertificates = ["DG"];
          blackCertificates = ["EAPRP", "SMS", "DEICE"];

          break;
        case "PAA":
          // Note: Aircraft types have no effect on required certificates
          redCertificates = [];
          amberCertificates = ["DG", "SORT", "OSSP"];
          blackCertificates = ["DEICE"];

          break;

        case "Mechanic":
          //Aircraft Types
          // 744
          // 74Y
          // 748
          // 763
          // 76F
          // 744
          // 74Y
          // 748
          // 763
          // 76F
          // 738
          // 77F

          // AMBER certificate definitions
          // 5Y-76X-REC   =  5Y REC L1 AUTH || 5Y REC L2 AUTH
          // 5Y-76X-ARA   =  5Y 767-300 ARA AUTH  || 5Y767-3 NO ETOPS ARA AUTH
          //

          //----------------------------------World Atlantic
          if (companyCodes.includes("A")) {
            if (
              // aircraftTypes.includes("744") ||
              // aircraftTypes.includes("74Y") ||
              // aircraftTypes.includes("747")
              aircraftTypes.includes("744") ||
              aircraftTypes.includes("74Y") ||
              aircraftTypes.includes("747") ||
              aircraftTypes.includes("PAX") ||
              aircraftTypes.includes("LCF")
            ) {
              redCertificates.push("5Y RVSM AUTH");
              redCertificates.push("5Y 400 ARA AUTH");
              redCertificates.push("5Y 400 CAT AUTH");
              redCertificates.push("5Y 400 RII AUTH");
              amberCertificates.push("5Y-744-REC");
            }

            // NOTE
            // "5Y-744-REC" Includes the following certificates
            //  5Y REC L1 AUTH
            //  5Y REC L2 AUTH

            //These aircraft types were move to the 744 Group above
            // if (aircraftTypes.includes("PAX") || aircraftTypes.includes("LCF")) {
            //   redCertificates.push("5Y REC L1 AUTH");
            //   redCertificates.push("5Y REC L2 AUTH");
            //   redCertificates.push("5Y RVSM AUTH");
            //   redCertificates.push("5Y 400 ARA AUTH");
            //   redCertificates.push("5Y 400 CAT AUTH");
            //   redCertificates.push("5Y 400 RII AUTH");
            // }

            if (aircraftTypes.includes("748")) {
              redCertificates.push("5Y RVSM AUTH");
              redCertificates.push("5Y 747-8 ARA AUTH");
              redCertificates.push("5Y 747-8 CAT AUTH");
              redCertificates.push("5Y 747-8 RII AUTH");
              amberCertificates.push("5Y-748-REC");
            }

            if (aircraftTypes.includes("77F")) {
              redCertificates.push("5Y RVSM AUTH");
              redCertificates.push("777-ARA-AUTH");
              redCertificates.push("777-LLM-AUTH");
              redCertificates.push("777-RII-AUTH");
              redCertificates.push("777-ETOPS-AUTH");
              amberCertificates.push("5Y-777-REC");
            }

            if (
              aircraftTypes.includes("763") ||
              aircraftTypes.includes("76F")
            ) {
              redCertificates.push("5Y RVSM AUTH");
              redCertificates.push("5Y 767 CAT AUTH");
              redCertificates.push("5Y 767 ETOPS AUTH");
              amberCertificates.push("5Y-76X-REC");
              amberCertificates.push("5Y-76X-ARA");
              amberCertificates.push("5Y-76X-RII");
            }

            // NOTES
            // 5Y-76X-ARA Includes the following certificates
            // 5Y 767-300 ARA AUTH
            // 5Y767-3 NO ETOPS ARA AUTH

            //=============================
            // 5Y-76X-RII Includes the following certificates
            // 5Y 767-300 RII AUTH
            // 5Y767-3 NO ETOPS RII AUTH

            if (
              aircraftTypes.includes("737") ||
              aircraftTypes.includes("738")
            ) {
              redCertificates.push("5Y RVSM AUTH");
              redCertificates.push("737NG-ARA-AUTH");
              redCertificates.push("737NG-LLM-AUTH");
              redCertificates.push("737NG-RII-AUTH");
              amberCertificates.push("5Y-73X-REC");
            }
          }

          //----------------------------------Polar
          if (companyCodes.includes("P")) {
            if (
              aircraftTypes.includes("744") ||
              aircraftTypes.includes("74Y")
            ) {
              redCertificates.push("PO RVSM AUTH");
              redCertificates.push("PO 400 ARA AUTH");
              redCertificates.push("PO 400 CAT AUTH");
              redCertificates.push("PO 400 RII AUTH");
              amberCertificates.push("PO-7XX-REC");
            }

            if (aircraftTypes.includes("748")) {
              redCertificates.push("PO RVSM AUTH");
              redCertificates.push("PO 747-8 ARA AUTH");
              redCertificates.push("PO 747-8 CAT AUTH");
              redCertificates.push("PO 747-8 RII AUTH");
              amberCertificates.push("PO-7XX-REC");
            }

            if (aircraftTypes.includes("77F")) {
              redCertificates.push("PO RVSM AUTH");
              redCertificates.push("PO-777-ARA-AUTH");
              redCertificates.push("PO-777-LLM-AUTH");
              redCertificates.push("PO-777-RII-AUTH");
              redCertificates.push("PO-777-ETOPS-AUTH");
              amberCertificates.push("PO-7XX-REC");
            }
          }

          break;

        default:
          break;
      }

      // Check each required certificate list againts the employee's list of certificates

      // RED Certificates. Generate a HARD warning if missing or expired
      if (redCertificates.length) {
        redCertificates.forEach((code) => {
          // Look for this certificate code in the employee's list of certificates
          const certificate = certificates.find((item) => {
            return item.code === code;
          });

          if (certificate) {
            // Check expiration date
            if (certificate.expires) {
              daysRemaining = this.getDaysRemaining(
                new Date(certificate.graceExpirationDate).getTime(),
                assignmentEnd
              );

              if (daysRemaining < 15) {
                warnings.push({
                  level: "Hard",
                  type: "Training Expiration (less than 15 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 30) {
                warnings.push({
                  level: "Soft",
                  type: "Training Expiration (less than 30 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 60) {
                warnings.push({
                  level: "Soft",
                  type: "Training Expiration (less than 60 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              }
            }
          } else {
            // Certificates was not found, add a hard warning for this certificate code
            warnings.push({
              level: "Hard",
              type: "Missing Training",
              message: `Missing certificate:  ${code}`,
            });
          }
        });
      }

      // AMBER Certificates. Look for certificate and alternate certificate. Generate a HARD warning if both missing or expired
      // AMBER Certificates are group by a common name. Certificates that share a common name are interchangable

      if (amberCertificates.length) {
        let employeeCertificates;
        let bestCertificate;

        amberCertificates.forEach((code) => {
          employeeCertificates = [];
          bestCertificate = null;

          switch (code) {
            case "DG":
              employeeCertificates = certificates.filter((item) => {
                return item.code === "DG 1-6" || item.code === "DG 10R";
              });

              break;

            case "SORT":
              employeeCertificates = certificates.filter((item) => {
                return (
                  item.code === "SORT 3" ||
                  item.code === "LVP_1" ||
                  item.code === "B747 PAXS"
                );
              });

              break;
            case "5Y-744-REC":
            case "5Y-748-REC":
            case "5Y-777-REC":
            case "5Y-76X-REC":
            case "5Y-73X-REC":
            case "PO-7XX-REC":
              //case "PO-76X-REC": //---Removed DE4895 - 17APRIL2024 - Jerry SADA---//
              employeeCertificates = certificates.filter((item) => {
                return (
                  item.code === "5Y REC L1 AUTH" ||
                  item.code === "5Y REC L2 AUTH"
                );
              });

              break;

            case "5Y-76X-ARA":
              //case "PO-76X-ARA": //---Removed DE4895 - 17APRIL2024 - Jerry SADA---//
              employeeCertificates = certificates.filter((item) => {
                return (
                  item.code === "5Y 767-300 ARA AUTH" ||
                  item.code === "5Y767-3 NO ETOPS ARA AUTH"
                );
              });

              break;

            case "5Y-76X-RII":
              employeeCertificates = certificates.filter((item) => {
                return (
                  item.code === "5Y 767-300 RII AUTH" ||
                  item.code === "5Y767-3 NO ETOPS RII AUTH"
                );
              });

              break;

            default:
              employeeCertificates = certificates.filter((item) => {
                return item.code === "TSAAOSSP" || item.code === "FACAOSSP";
              });

              break;
          }

          if (employeeCertificates.length) {
            sortedCertificates = employeeCertificates.sort(
              this.compareExpirationDates
            );
            bestCertificate = sortedCertificates[sortedCertificates.length - 1];
          }

          if (bestCertificate) {
            // Check the expiration date of the best certificate found
            daysRemaining = this.getDaysRemaining(
              new Date(bestCertificate.graceExpirationDate).getTime(),
              assignmentEnd
            );

            if (daysRemaining < 15) {
              warnings.push({
                level: "Hard",
                type: "Training Expiration (less than 15 days)",
                message: `Training ${code} grace expiration date: ${this.formatDate(
                  bestCertificate.graceExpirationDate
                )}`,
              });
            } else if (daysRemaining < 30) {
              warnings.push({
                level: "Soft",
                type: "Training Expiration (less than 30 days)",
                message: `Training ${code} grace expiration date: ${this.formatDate(
                  bestCertificate.graceExpirationDate
                )}`,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                level: "Soft",
                type: "Training Expiration (less than 60 days)",
                message: `Training ${code} grace expiration date: ${this.formatDate(
                  bestCertificate.graceExpirationDate
                )}`,
              });
            }
          } else {
            // Certificates was not found, add a hard warning for this certificate code
            warnings.push({
              level: "Hard",
              type: "Missing Training",
              message: `Missing certificate:  ${code}`,
            });
          }
        });
      }

      // BLACK Certificates. Generate a SOFT warning if missing or expired
      if (blackCertificates.length) {
        blackCertificates.forEach((code) => {
          // Look for this certificate code in the employee's list of certificates
          const certificate = certificates.find((item) => {
            return item.code === code;
          });

          if (certificate) {
            // Check expiration date
            if (certificate.expires) {
              certificate.graceExpirationDate =
                new Date(certificate.expires).getTime() +
                30 * 24 * 60 * 60 * 1000;

              daysRemaining = this.getDaysRemaining(
                new Date(certificate.graceExpirationDate).getTime(),
                assignmentEnd
              );

              if (daysRemaining < 15) {
                warnings.push({
                  level: "Soft",
                  type: "Training Expiration (less than 15 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 30) {
                warnings.push({
                  level: "Soft",
                  type: "Training Expiration (less than 30 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 60) {
                warnings.push({
                  level: "Soft",
                  type: "Training Expiration (less than 60 days)",
                  message: `Training ${code} grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              }
            }
          } else {
            // Certificates was not found, add a soft warning for this certificate code
            warnings.push({
              level: "Soft",
              type: "Missing Training",
              message: `Missing certificate:  ${code}`,
            });
          }
        });
      }

      return warnings;
    },

    compareExpirationDates(a, b) {
      if (
        new Date(a.expirationDate).getTime() <
        new Date(b.expirationDate).getTime()
      ) {
        return -1;
      }
      if (
        new Date(b.expirationDate).getTime() <
        new Date(a.expirationDate).getTime()
      ) {
        return 1;
      }
      return 0;
    },

    getDaysRemaining(expirationDate, latest) {
      //Note: param 'expirationDate' must be in milliseconds

      const daysRemaining = (expirationDate - latest) / (24 * 60 * 60 * 1000);

      //Returns whole days only

      if (daysRemaining > 0) {
        return Math.ceil(daysRemaining);
      } else {
        return 0;
      }
    },

    getGeneralWarnings(employees) {
      let warnings = [];
      let daysRemaining = 0;

      const assignmentEnd = new Date().getTime() + 72 * 60 * 60 * 1000; // Check next 72 hours

      let employee;

      for (let i = 0; i < employees.length; i++) {
        employee = employees[i];
        const employeeName = `${employee.surname}, ${employee.givenName}`;

        //--------------------Training Certificates
        if (employee.trainingRecords?.certificates.length) {
          // NOTE The training records are not checked against an assignment.
          // This function just checks for the expiration of any certificate
          // that the employee has
          employee.trainingRecords.certificates.forEach((certificate) => {
            // Check expiration date
            if (certificate.expires) {
              daysRemaining = this.getDaysRemaining(
                new Date(certificate.graceExpirationDate).getTime(),
                assignmentEnd
              );

              if (daysRemaining < 15) {
                warnings.push({
                  employeeName,
                  level: "Soft",
                  type: "Training Expiration (less than 15 days)",
                  message: `Training ${
                    certificate.title
                  } grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 30) {
                warnings.push({
                  employeeName,
                  level: "Soft",
                  type: "Training Expiration (less than 30 days)",
                  message: `Training ${
                    certificate.title
                  } grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              } else if (daysRemaining < 60) {
                warnings.push({
                  employeeName,
                  level: "Soft",
                  type: "Training Expiration (less than 60 days)",
                  message: `Training ${
                    certificate.title
                  } grace expiration date: ${this.formatDate(
                    certificate.graceExpirationDate
                  )}`,
                });
              }
            }
          });
        }
        //--------------------Documents
        const passports = employee.documents.filter((item) => {
          return item.type === "Passport";
        });

        if (passports.length) {
          passports.forEach((passport) => {
            daysRemaining = this.getDaysRemaining(
              new Date(passport.expirationDate).getTime(),
              assignmentEnd
            );

            if (daysRemaining < 30) {
              warnings.push({
                employeeName,
                level: "Hard",
                type: "Required Document",
                message: `Passport (${passport.country}-${
                  passport.number
                } ) expiration date: ${this.formatDate(
                  passport.expirationDate
                )} is 30 days or less from assignment date.`,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                employeeName,
                level: "Soft",
                type: "Required Document",
                message: `Passport (${passport.country}-${
                  passport.number
                } ) expiration date: ${this.formatDate(
                  passport.expirationDate
                )} is 60 days or less from assignment date.`,
              });
            }
          });
        } else {
          warnings.push({
            employeeName,
            level: "Hard",
            type: "Documents",
            message: `No Passport`,
          });
        }

        //Visas
        if (employee.visas?.length) {
          employee.visas.forEach((visa) => {
            daysRemaining = this.getDaysRemaining(
              new Date(visa.expirationDate).getTime(),
              assignmentEnd
            );

            if (daysRemaining < 15) {
              warnings.push({
                employeeName,
                level: "Hard",
                type: "Required Visa",
                message: `${
                  visa.country
                } visa expiration date: ${this.formatDate(
                  visa.expirationDate
                )} is 15 days or less from assignment date.`,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                employeeName,
                level: "Soft",
                type: "Required Visa",
                message: `${
                  visa.country
                } visa expiration date: ${this.formatDate(
                  visa.expirationDate
                )} is 60 days or less from assignment date.`,
              });
            }
          });
        }
      }

      return warnings;
    },

    getGroundAssignmentWarnings(employee, assignment) {
      let warnings = [];
      let daysRemaining = 0;
      const countriesThatRequireVisa = ["CHINA"];

      const assignmentStart = new Date(assignment.startTime).getTime();
      const assignmentEnd = new Date(assignment.endTime).getTime();

      // ------------------- Get previous assignments
      //Find previous assignments from 1 hour before this assignment start to 7 days before
      const previousAssignments = employee.assignments.filter((item) => {
        return new Date(item.endTime).getTime() < assignmentStart;
      });

      if (previousAssignments.length) {
        const lastAssignment =
          previousAssignments[previousAssignments.length - 1];

        if (lastAssignment.destinationIata !== assignment.originIata) {
          warnings.push({
            level: "Soft",
            type: "Route Discontinuity",
            message: `This assignment starts in ${assignment.originIata}, last assignment ended in ${lastAssignment.destinationIata}`,
          });
        }
      }

      //-----------------------Check Documents (Only applies to Travel)
      if (assignment.type === "Travel") {
        const passports = employee.documents.filter((item) => {
          return item.type === "Passport";
        });

        if (assignment.originCountry !== assignment.destinationCountry) {
          if (passports && passports.length) {
            const sortedPassports = passports.sort(this.compareExpirationDates);
            const bestPassport = sortedPassports[sortedPassports.length - 1];

            daysRemaining = this.getDaysRemaining(
              new Date(bestPassport.expirationDate).getTime(),
              assignmentEnd
            );

            if (daysRemaining < 30) {
              warnings.push({
                level: "Hard",
                type: "Passport Expiration",
                message: `Expiration: ${this.formatDate(
                  bestPassport.expirationDate
                )} `,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                level: "Soft",
                type: "Passport Expiration",
                message: `Expiration: ${this.formatDate(
                  bestPassport.expirationDate
                )} `,
              });
            }

            //Visas
            if (
              countriesThatRequireVisa.includes(
                assignment.destinationCountry.toUpperCase()
              )
            ) {
              if (employee.visas?.length) {
                const visa = employee.visas.find((v) => {
                  return (
                    v.country.toUpperCase() ===
                    assignment.destinationCountry.toUpperCase()
                  );
                });
                if (visa) {
                  daysRemaining = this.getDaysRemaining(
                    new Date(visa.expirationDate).getTime(),
                    assignmentEnd
                  );

                  if (daysRemaining < 15) {
                    warnings.push({
                      level: "Hard",
                      type: "Required Visa",
                      message: `${
                        visa.country
                      } visa expiration date: ${this.formatDate(
                        visa.expirationDate
                      )} is 15 days or less from assignment date.`,
                    });
                  } else if (daysRemaining < 60) {
                    warnings.push({
                      level: "Soft",
                      type: "Required Visa",
                      message: `${
                        visa.country
                      } visa expiration date: ${this.formatDate(
                        visa.expirationDate
                      )} is 60 days or less from assignment date.`,
                    });
                  }
                } else {
                  warnings.push({
                    level: "Hard",
                    type: "Required Visa",
                    message: `Employee does not have the required visa for ${assignment.destinationCountry}`,
                  });
                }
              } else {
                warnings.push({
                  level: "Hard",
                  type: "Required Visa",
                  message: `Employee does not have the required visa for ${assignment.destinationCountry}`,
                });
              }
            }
          } else {
            warnings.push({
              level: "Hard",
              type: "Document Missing",
              message: `Employee has no Passport`,
            });
          }
        }
      }

      //----------------------Bidline Warnings
      if (employee.biddays.length) {
        const daysOff = employee.biddays.filter((item) => {
          return item.code !== "RES";
        });

        let biddayConflicts = [];
        let dayStart;
        let dayEnd;

        daysOff.forEach((item) => {
          dayStart = new Date(
            Date.UTC(item.year, item.month, item.day)
          ).getTime();
          dayEnd =
            dayStart + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;

          if (assignmentStart <= dayEnd && assignmentEnd >= dayStart) {
            biddayConflicts.push(item);
          }
        });

        //Add conflict warning
        if (biddayConflicts.length) {
          biddayConflicts.forEach((item) => {
            warnings.push({
              level: "Soft",
              type: "Bidline Conflict",
              message: `This assignment conflicts with "${
                item.description
              }" on ${this.formatDate(
                new Date(Date.UTC(item.year, item.month, item.day))
              )}`,
            });
          });
        }
      }

      return warnings;
    },

    getFlightAssignmentWarnings(employee, flight, role) {
      let warnings = [];
      let daysRemaining = 0;
      const countriesThatRequireVisa = ["CHINA"];
      const customerCodesThatRequireUsPassport = ["AMC", "MC"];
      const companyNamesThatRequireUsPassport = [
        "World Atlantic",
        "Flightline",
      ];

      const assignmentStart = new Date(flight.effectiveTimeOut).getTime();
      const assignmentEnd = new Date(flight.effectiveTimeIn).getTime();
      const aircraftTypes = [flight.aircraft.type];
      const companyCodes = [flight.company]; // Used for the requirements of mechanics

      // ------------------- Get previous assignments
      //Find previous assignments from 1 hour before this assignment start to 7 days before
      const previousAssignments = employee.assignments.filter((item) => {
        return new Date(item.endTime).getTime() < assignmentStart;
      });

      if (previousAssignments.length) {
        const lastAssignment =
          previousAssignments[previousAssignments.length - 1];

        if (lastAssignment.destinationIata !== flight.originIata) {
          warnings.push({
            level: "Soft",
            type: "Route Discontinuity",
            message: `This assignment starts in ${flight.originIata}, last assignment ended in ${lastAssignment.destinationIata}`,
          });
        }
      }

      //--------------------Training Certificates
      if (employee.trainingRecords?.certificates.length) {
        const trainingWarnings = this.getTrainingWarnings(
          employee.trainingRecords.certificates,
          role,
          assignmentEnd,
          aircraftTypes,
          companyCodes
        );

        if (trainingWarnings.length) {
          trainingWarnings.forEach((item) => {
            warnings.push(item);
          });
        }
      } else {
        if (role === "Jumpseat") {
          warnings.push({
            level: "Soft",
            type: "Training Records",
            message: "No Training Records found",
          });
        } else {
          warnings.push({
            level: "Hard",
            type: "Training Records",
            message: "No Training Records found",
          });
        }
      }

      //--------------------Documents
      if (flight.originCountry !== flight.destinationCountry) {
        const passports = employee.documents.filter((item) => {
          return item.type === "Passport";
        });

        if (passports.length) {
          const sortedPassports = passports.sort(this.compareExpirationDates);
          const bestPassport = sortedPassports[sortedPassports.length - 1];

          daysRemaining = this.getDaysRemaining(
            new Date(bestPassport.expirationDate).getTime(),
            assignmentEnd
          );

          if (daysRemaining < 30) {
            warnings.push({
              level: "Hard",
              type: "Required Document",
              message: `Passport (${bestPassport.country}-${
                bestPassport.number
              } ) expiration date: ${this.formatDate(
                bestPassport.expirationDate
              )} is 30 days or less from assignment date.`,
            });
          } else if (daysRemaining < 60) {
            warnings.push({
              level: "Soft",
              type: "Required Document",
              message: `Passport (${bestPassport.country}-${
                bestPassport.number
              } ) expiration date: ${this.formatDate(
                bestPassport.expirationDate
              )} is 60 days or less from assignment date.`,
            });
          }
        } else {
          warnings.push({
            level: "Hard",
            type: "Required Document",
            message: `Employee has no Passport`,
          });
        }
      }

      //Visas
      if (
        countriesThatRequireVisa.includes(
          flight.destinationCountry.toUpperCase()
        )
      ) {
        if (employee.visas?.length) {
          const visa = employee.visas.find((v) => {
            return (
              v.country.toUpperCase() ===
              flight.destinationCountry.toUpperCase()
            );
          });
          if (visa) {
            daysRemaining = this.getDaysRemaining(
              new Date(visa.expirationDate).getTime(),
              assignmentEnd
            );

            if (daysRemaining < 15) {
              warnings.push({
                level: "Hard",
                type: "Required Visa",
                message: `${
                  flight.destinationCountry
                } visa expiration date: ${this.formatDate(
                  visa.expirationDate
                )} is 15 days or less from assignment date.`,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                level: "Soft",
                type: "Required Visa",
                message: `${
                  flight.destinationCountry
                } visa expiration date: ${this.formatDate(
                  visa.expirationDate
                )} is 60 days or less from assignment date.`,
              });
            }
          } else {
            warnings.push({
              level: "Hard",
              type: "Required Visa",
              message: `Employee does not have the required visa for ${flight.destinationCountry}`,
            });
          }
        } else {
          warnings.push({
            level: "Hard",
            type: "Required Visa",
            message: `Employee does not have the required visa for ${flight.destinationCountry}`,
          });
        }
      }

      //Flight Customer require US Passport
      if (
        companyNamesThatRequireUsPassport.includes(employee.companyName) &&
        customerCodesThatRequireUsPassport.includes(flight.customerCode)
      ) {
        let usPassports;
        let sortedUsPassports;
        let bestUsPassport;

        const passports = employee.documents.filter((item) => {
          return item.type === "Passport";
        });

        if (passports.length) {
          //Find US passports
          usPassports = passports.filter((item) => {
            return item.country === "United States";
          });

          if (usPassports.length) {
            //Sort US Passports
            sortedUsPassports = usPassports.sort(this.compareExpirationDates);
            bestUsPassport = sortedUsPassports[sortedUsPassports.length - 1];

            daysRemaining = this.getDaysRemaining(
              new Date(bestUsPassport.expirationDate).getTime(),
              assignmentEnd
            );

            //Check best passport is valid until the end of the flight
            if (daysRemaining < 30) {
              warnings.push({
                level: "Hard",
                type: "Required Document",
                message: `Passport (${bestUsPassport.country}-${
                  bestUsPassport.number
                } ) expiration date: ${this.formatDate(
                  bestUsPassport.expirationDate
                )} is 30 days or less from assignment date.`,
              });
            } else if (daysRemaining < 60) {
              warnings.push({
                level: "Soft",
                type: "Required Document",
                message: `Passport (${bestUsPassport.country}-${
                  bestUsPassport.number
                } ) expiration date: ${this.formatDate(
                  bestUsPassport.expirationDate
                )} is 60 days or less from assignment date.`,
              });
            }
          } else {
            warnings.push({
              level: "Hard",
              type: "U.S. Passport - Required Document",
              message: `Employee does not hold a valid U.S. Passport`,
            });
          }
        } else {
          warnings.push({
            level: "Hard",
            type: "Required Document",
            message: `Employee does not hold a valid U.S. Passport`,
          });
        }
      }

      //----------------------Bidline Warnings
      if (employee.biddays.length) {
        const daysOff = employee.biddays.filter((item) => {
          return item.code !== "RES";
        });

        let biddayConflicts = [];
        let dayStart;
        let dayEnd;

        daysOff.forEach((item) => {
          dayStart = new Date(
            Date.UTC(item.year, item.month, item.day)
          ).getTime();
          dayEnd =
            dayStart + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;

          if (assignmentStart <= dayEnd && assignmentEnd >= dayStart) {
            biddayConflicts.push(item);
          }
        });

        //Add conflict warning
        if (biddayConflicts.length) {
          biddayConflicts.forEach((item) => {
            warnings.push({
              level: "Soft",
              type: "Bidline Conflict",
              message: `This assignment conflicts with "${
                item.description
              }" on ${this.formatDate(
                new Date(Date.UTC(item.year, item.month, item.day))
              )}`,
            });
          });
        }
      }

      return warnings;
    },

    getStationAssignmentWarnings(employee, flights, assignment) {
      let warnings = [];

      const assignmentStart = new Date(assignment.startTime).getTime();
      const assignmentEnd = new Date(assignment.endTime).getTime();

      let aircraftTypes = [];
      let companyCodes = []; // Used for the requirements of mechanics
      flights.forEach((item) => {
        if (!aircraftTypes.includes(item.aircraft.type)) {
          aircraftTypes.push(item.aircraft.type);
        }

        if (!companyCodes.includes(item.company)) {
          companyCodes.push(item.company);
        }
      });

      // ------------------- Get previous assignments
      //Find previous assignments from 1 hour before this assignment start to 7 days before
      const previousAssignments = employee.assignments.filter((item) => {
        return new Date(item.endTime).getTime() < assignmentStart;
      });

      if (previousAssignments.length) {
        const lastAssignment =
          previousAssignments[previousAssignments.length - 1];

        if (lastAssignment.destinationIata !== assignment.originIata) {
          warnings.push({
            level: "Soft",
            type: "Route Discontinuity",
            message: `This assignment starts in ${assignment.originIata}, last assignment ended in ${lastAssignment.destinationIata}`,
          });
        }
      }

      //--------------------Training Certificates
      if (employee.trainingRecords?.certificates.length) {
        const trainingWarnings = this.getTrainingWarnings(
          employee.trainingRecords.certificates,
          assignment,
          assignmentEnd,
          aircraftTypes,
          companyCodes
        );

        if (trainingWarnings.length) {
          trainingWarnings.forEach((item) => {
            warnings.push(item);
          });
        }
      } else {
        if (assignment.role === "Jumpseat") {
          warnings.push({
            level: "Soft",
            type: "Training Certificates",
            message: "No Training Records found",
          });
        } else {
          warnings.push({
            level: "Hard",
            type: "Training Certificates",
            message: "No Training Records found",
          });
        }
      }

      //----------------------Bidline Warnings
      if (employee.biddays.length) {
        const daysOff = employee.biddays.filter((item) => {
          return item.code !== "RES";
        });

        let biddayConflicts = [];
        let dayStart;
        let dayEnd;

        daysOff.forEach((item) => {
          dayStart = new Date(
            Date.UTC(item.year, item.month, item.day)
          ).getTime();
          dayEnd =
            dayStart + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;

          if (assignmentStart <= dayEnd && assignmentEnd >= dayStart) {
            biddayConflicts.push(item);
          }
        });

        //Add conflict warning
        if (biddayConflicts.length) {
          biddayConflicts.forEach((item) => {
            warnings.push({
              level: "Soft",
              type: "Bidline Conflict",
              message: `This assignment conflicts with "${
                item.description
              }" on ${this.formatDate(
                new Date(Date.UTC(item.year, item.month, item.day))
              )}`,
            });
          });
        }
      }

      return warnings;
    },

    //#region ------------------------------- FORMATTERS

    formatDateTime(d) {
      if (d) {
        const d1 = new Date(d).toUTCString().toString();

        return `${d1.substring(5, 17)} - ${d1.substring(16, 22)}`;
      } else {
        return "---";
      }
    },

    formatAssignmentSnapshot(assignment) {
      if (assignment.type === "Ground") {
        return this.formatGroundAssignmentSnapshot(assignment);
      } else {
        return this.formatFullFlightSnapshot(assignment.flight);
      }
    },

    formatFlightSnapshot(flight) {
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);

      const snapshot = `${flight.flightNumber} . ${flight.aircraft.registration} . ${flight.originIata}-${flight.destinationIata} . ${timeOut} Z`;
      return snapshot;
    },

    formatGroundAssignmentSnapshot(assignment) {
      //const timeOut = this.formatDateTime(assignment.startTime);
      // const timeIn = this.formatTime(assignment.endTime);
      let snapshot = "??";

      switch (assignment.type) {
        case "Travel":
          snapshot = `${assignment.type} . ${assignment.originIata} - ${assignment.destinationIata}`;
          break;
        default:
          // case "Special Assignment":
          // case "Leave":
          // case "Leave - Bereavement":
          // case "Leave - FMLA":
          // case "Leave - Medical":
          // case "Leave - Military":
          // case "Leave - Non-FMLA":
          // case "Leave - Parental":
          // case "Leave - Personal":
          // case "LOA":
          // case "LOA - Jury Duty":
          // case "LOA - Long Term Disability":
          // case "LOA - Short Term Disability":
          // case "LOA - Workers' Compensation":
          // case "Layover":
          // case "Stand By":
          // case "Quarantine":
          // case "Quarantine - Day OFF":
          // case "Sick":
          // case "Training":
          // case "Training - Ground School":
          // case "Training - SIM":
          // case "Do Not Use":
          snapshot = `${assignment.type} . ${assignment.originIata}`;
          break;
      }

      return snapshot;
    },

    formatFullFlightSnapshot(flight) {
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);
      const timeIn = this.formatTime(flight.effectiveTimeIn);

      const snapshot = `${flight.flightNumber} . ${flight.aircraftRegistration} . ${flight.originIata} - ${flight.destinationIata} . ${timeOut} Z - ${timeIn} Z`;

      return snapshot;
    },

    formatQuantity(number) {
      const formatter = new Intl.NumberFormat("en-US");
      return formatter.format(number);
    },

    formatDate(d) {
      if (d) {
        return new Date(d).toUTCString().substring(5, 16);
      } else {
        return "---";
      }
    },

    formatTime(d) {
      return new Date(d).toUTCString().substring(17, 22);
    },

    formatMinutes(minutes) {
      const _h = Math.floor((minutes % (24 * 60)) / 60);
      const _m = Math.floor((minutes % (24 * 60)) % 60);

      const h = _h < 10 ? `0${_h}` : _h;
      const m = _m < 10 ? `0${_m}` : _m;

      return `${h}+${m}`;
    },

    formatDay(d, scale) {
      if (scale > 8) {
        return new Date(d).toUTCString().toString().substring(5, 8);
      } else {
        return new Date(d).toUTCString().substring(0, 16);
      }
    },

    formatMonth(m) {
      switch (m) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";

        case 10:
          return "October";

        case 11:
          return "November";

        case 12:
          return "December";

        default:
          return "---";
      }
    },

    formatMonthShort(m) {
      switch (m) {
        case 0:
          return "JAN";
        case 1:
          return "FEB";
        case 2:
          return "MAR";
        case 3:
          return "APR";
        case 4:
          return "MAY";
        case 5:
          return "JUN";
        case 6:
          return "JUL";
        case 7:
          return "AUG";
        case 8:
          return "SEP";

        case 9:
          return "OCT";

        case 10:
          return "NOV";

        case 11:
          return "DEC";

        default:
          return "---";
      }
    },
    formatStationAssignmentNotification(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatTime(assignment.endTime);

      return `${assignment.originIata} . ${
        assignment.subCategory
      } . ${timeOut} Z - ${timeIn} Z . ${
        assignment.group.flightIds.length
      } Assignment(s) . ${this.formatMinutes(assignment.duration)}`;
    },

    formatGroundAssignmentNotification(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const updatedAt = this.formatDateTime(assignment.updatedAt);

      switch (assignment.category) {
        case "Special Assignment":
        case "Layover":
        case "Quarantine":
        case "Sick":
          return `${assignment.category} . ${assignment.originIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        case "Travel":
          return `${assignment.category} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        case "Leave":
          return `${assignment.category} . ${assignment.subCategory} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        case "Training":
          return `${assignment.category} . ${assignment.subCategory} . ${assignment.originIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
        default:
          return `${assignment.category} . ${timeOut} . Assigned by: ${assignment.assignedBy} on ${updatedAt} z.`;
      }
    },

    formatFlightAssignmentNotification(assignment) {
      const timeOut = this.formatDateTime(assignment.flight.effectiveTimeOut);
      const updatedAt = this.formatDateTime(assignment.updatedAt);
      const flightRole = assignment.role;

      return `Flight Assignment . ${flightRole} . ${assignment.flight.flightNumber} . ${assignment.flight.aircraftRegistration} . ${assignment.flight.originIata}-${assignment.flight.destinationIata} . ${timeOut} Z . Assigned by: ${assignment.assignedBy} on ${updatedAt} Z.`;
    },

    formatElapsedTime(t) {
      const addLeadingZeros = (number) => {
        return String(number).padStart(2, "0");
      };

      const h = addLeadingZeros(Math.floor(t / 60));
      const m = addLeadingZeros(t % 60);

      return `${h}+${m}`;
    },

    formatStationAssignmentFlight(flight, originIata) {
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);
      const timeIn = this.formatDateTime(flight.effectiveTimeIn);

      let aircraftType = "PAX";

      if (flight.aircraft.cargoOnly) {
        aircraftType = "CARGO";
      }

      let snapshot = "---";

      if (flight.originIata === originIata) {
        snapshot = `${aircraftType} - DEP: ${timeOut}  | ${flight.flightNumber} . ${flight.aircraftRegistration} . ${flight.originIata} - ${flight.destinationIata}`;
      } else {
        snapshot = `${aircraftType} - ARR: ${timeIn}  | ${flight.flightNumber} . ${flight.aircraftRegistration} . ${flight.originIata} - ${flight.destinationIata}`;
      }

      return snapshot;
    },

    formatConflictText(previousFlight, flight, employee) {
      const timeIn = this.formatDateTime(previousFlight.effectiveTimeIn);
      const timeOut = this.formatDateTime(flight.effectiveTimeOut);
      const employeeName = `${employee.surname}, ${employee.givenName}`;

      const snapshot = `${employeeName} . Flight ${previousFlight.flightNumber} arrives at ${previousFlight.destinationIata} on ${timeIn}, next flight ${flight.flightNumber} departs from ${flight.originIata} on ${timeOut}`;
      return snapshot;
    },

    formatName(givenName, surname, format) {
      if (format === "f") {
        return `${givenName} ${surname}`;
      } else if (format === "sub") {
        return `${surname}, ${givenName.substring(0, 1)}`;
      } else {
        return `${surname}, ${givenName}`;
      }
    },

    //#endregion

    overlaps(dayStart, dayEnd, periods) {
      if (periods.length) {
        let periodStart;
        let periodEnd;

        let count = 0;

        periods.forEach((period) => {
          periodStart = new Date(period.start).getTime();
          periodEnd = new Date(period.end).getTime();

          if (dayStart <= periodEnd && dayEnd >= periodStart) {
            count++;
          }
        });

        return count > 0;
      } else {
        return false;
      }
    },

    //#region COMPARES

    compareNames(a, b) {
      if (a < b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    },

    //-----------------------------Special Airports
    compareAirportNameUp(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (b.name < a.name) {
        return 1;
      }
      return 0;
    },

    compareAirportNameDown(a, b) {
      if (a.name < b.name) {
        return 1;
      }
      if (b.name < a.name) {
        return -1;
      }
      return 0;
    },

    compareAirportCountryUp(a, b) {
      if (a.country < b.country) {
        return -1;
      }
      if (b.country < a.country) {
        return 1;
      }
      return 0;
    },

    compareAirportCountryDown(a, b) {
      if (a.country < b.country) {
        return 1;
      }
      if (b.country < a.country) {
        return -1;
      }
      return 0;
    },

    compareAirportIataUp(a, b) {
      if (a.iata < b.iata) {
        return -1;
      }
      if (b.iata < a.iata) {
        return 1;
      }
      return 0;
    },

    compareAirportIataDown(a, b) {
      if (a.iata < b.iata) {
        return 1;
      }
      if (b.iata < a.iata) {
        return -1;
      }
      return 0;
    },

    compareAirportIcaoUp(a, b) {
      if (a.icao < b.icao) {
        return -1;
      }
      if (b.icao < a.icao) {
        return 1;
      }
      return 0;
    },

    compareAirportIcaoDown(a, b) {
      if (a.icao < b.icao) {
        return 1;
      }
      if (b.icao < a.icao) {
        return -1;
      }
      return 0;
    },

    //-------------------------Warnings

    compareWarningTypeUp(a, b) {
      if (a.type < b.type) {
        return -1;
      }
      if (b.type < a.type) {
        return 1;
      }
      return 0;
    },

    compareWarningTypeDown(a, b) {
      if (a.type < b.type) {
        return 1;
      }
      if (b.type < a.type) {
        return -1;
      }
      return 0;
    },

    compareWarningMessageUp(a, b) {
      if (a.message < b.message) {
        return -1;
      }
      if (b.message < a.message) {
        return 1;
      }
      return 0;
    },

    compareWarningMessageDown(a, b) {
      if (a.message < b.message) {
        return 1;
      }
      if (b.message < a.message) {
        return -1;
      }
      return 0;
    },

    //---------------------Flights

    compareFlightAircraftRegistrationUp(a, b) {
      if (a.aircraft.registration < b.aircraft.registration) {
        return -1;
      }
      if (b.aircraft.registration < a.aircraft.registration) {
        return 1;
      }
      return 0;
    },

    compareFlightAircraftRegistrationDown(a, b) {
      if (a.aircraft.registration < b.aircraft.registration) {
        return 1;
      }
      if (b.aircraft.registration < a.aircraft.registration) {
        return -1;
      }
      return 0;
    },

    compareAircrafts(a, b) {
      if (a.registration < b.registration) {
        return -1;
      }
      if (b.registration < a.registration) {
        return 1;
      }
      return 0;
    },

    compareFlights(a, b) {
      if (
        new Date(a.effectiveTimeOut).getTime() <
        new Date(b.effectiveTimeOut).getTime()
      ) {
        return -1;
      }
      if (
        new Date(b.effectiveTimeOut).getTime() <
        new Date(a.effectiveTimeOut).getTime()
      ) {
        return 1;
      }
      return 0;
    },

    compareVaccines(a, b) {
      if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
        return -1;
      }
      if (new Date(b.date).getTime() < new Date(a.date).getTime()) {
        return 1;
      }
      return 0;
    },

    comparePassports(a, b) {
      if (
        new Date(a.expirationDate).getTime() <
        new Date(b.expirationDate).getTime()
      ) {
        return -1;
      }
      if (
        new Date(b.expirationDate).getTime() <
        new Date(a.expirationDate).getTime()
      ) {
        return 1;
      }
      return 0;
    },

    compareExemptions(a, b) {
      if (a.key < b.key) {
        return -1;
      }
      if (b.key < a.key) {
        return 1;
      }
      return 0;
    },

    compareAssignments(a, b) {
      if (new Date(a.startTime).getTime() < new Date(b.startTime).getTime()) {
        return -1;
      }
      if (new Date(b.startTime).getTime() < new Date(a.startTime).getTime()) {
        return 1;
      }
      return 0;
    },

    //#endregion

    getLocationLeft(time, filterStart, scale) {
      // Notes
      // time and filterStart are passed as strings
      // scale is passed as number
      const minutes =
        (new Date(time).getTime() - new Date(filterStart).getTime()) /
        1000 /
        60;
      return minutes / scale;
    },
  },
};
