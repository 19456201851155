<template>
  <div>
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-2"> </i></span
          ></RouterLink>
        </li>
        <li>
          <a href="#">
            <div class="ml-3 text-base lg:text-2xl text-white">
              {{ `Aircraft - ${aircraft.length}` }}
            </div>
          </a>
        </li>

        <!--TODO  <li @click="handleOpenFilters" class="icon">
          <a href="#">
            <span class="tooltip">Aircraft Filters</span>
            <span
              ><i class="fa-solid fa-bars-filter text-yellow-500 ml-3"></i
            ></span>
          </a>
        </li> -->

        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li>
          <RouterLink to="/"></RouterLink>
        </li>
      </ul>
    </nav>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <!-- Data Table -->
    <div class="relative overflow-x-auto">
      <table
        v-if="aircraft.length"
        class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
      >
        <thead
          class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-2 cursor-pointer">#</th>
            <th
              scope="col"
              class="px-6 py-2 cursor-pointer"
              @click="sortArrayByKey('registration')"
            >
              REGISTRATION
            </th>
            <th
              scope="col"
              class="px-6 py-2 cursor-pointer"
              @click="sortArrayByKey('type')"
            >
              TYPE
            </th>
            <th
              scope="col"
              class="px-6 py-2 cursor-pointer"
              @click="sortArrayByKey('cargoOnly')"
            >
              CONFIGURATION
            </th>

            <th
              scope="col"
              class="px-6 py-2 cursor-pointer"
              @click="sortArrayByKey('upperFloorType')"
            >
              Main Deck Floor
            </th>

            <th
              scope="col"
              class="px-6 py-2 cursor-pointer"
              @click="sortArrayByKey('lowerFloorType')"
            >
              Lower Deck Floor
            </th>

            <th
              scope="col"
              class="px-6 py-2 cursor-pointer"
              @click="sortArrayByKey('status')"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(ac, index) in aircraft"
            :key="index"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer"
            @click="handleShowAircraftInformation(ac)"
          >
            <td
              scope="row"
              class="w-24 px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              {{ index + 1 }}
            </td>
            <td
              scope="row"
              class="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              {{ ac.registration }}
            </td>

            <td class="px-6 py-2">{{ ac.typeDescription }}</td>

            <td class="px-6 py-2">
              {{ ac.cargoOnly ? "Cargo" : "Passengers" }}
            </td>

            <td class="px-6 py-2">{{ ac.upperFloorType }}</td>
            <td class="px-6 py-2">{{ ac.lowerFloorType }}</td>
            <td class="px-6 py-2">{{ ac.status }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else class="p-5 text-2xl text-green-600">
        No aircraft found with the selected filters
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import { Switch } from "@headlessui/vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "aircraft",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Switch,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },

  created() {
    this.getData();
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    sortedBy() {
      return this.$store.getters.aircraftSortedBy;
    },
  },

  data() {
    return {
      loading: false,
      aircraft: [],
      error: null,
      typeOptions: [
        "737",
        "738",
        "744",
        "747",
        "748",
        "763",
        "74Y",
        "76F",
        "77F",
        "LCF",
        "PAX",
      ],

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
    };
  },

  methods: {
    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.aircraft, key);

      if (sortedAsc) {
        this.aircraft.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.aircraft.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }

      this.$store.commit("updateAircraftSortedBy", key);
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    async getData() {
      try {
        this.loading = true;

        const token = await aad.getIdTokenSilent();

        const res = await api.get(`/aircrafts`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        this.loading = false;

        this.aircraft = res.data.aircraft;

        this.sortArrayByKey(this.sortedBy);

        this.$store.commit("updateAircraft", res.data.aircraft);

        let aircraftFilter = {
          active: false,
          aircraft: [],
        };
        if (this.user.aircraftFilter.active) {
          const _aircraft = this.aircraft.filter((a) => {
            return this.user.aircraftFilter.registrations.includes(
              a.registration
            );
          });

          aircraftFilter = {
            active: true,
            aircraft: _aircraft,
          };
        }

        this.$store.commit("updateFlightBoardAircraftFilter", aircraftFilter);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleShowAircraftInformation(aircraft) {
      let selectedAircraft = { ...aircraft };

      if (selectedAircraft.cargoOnly) {
        selectedAircraft.deck = "Cargo";
      } else {
        selectedAircraft.deck = "Passengers";
      }

      if (selectedAircraft.cabinConfig) {
        // Cabin Configuration
        let cabin;

        cabin = selectedAircraft.cabinConfig[0];

        if (cabin) {
          selectedAircraft.cabinOne = {
            active: true,
            class: selectedAircraft.cabinConfig[0].class,
            seats: selectedAircraft.cabinConfig[0].seats,
          };
        } else {
          selectedAircraft.cabinOne = {
            active: false,
            class: "A",
            seats: 100,
          };
        }

        cabin = selectedAircraft.cabinConfig[1];

        if (cabin) {
          selectedAircraft.cabinTwo = {
            active: true,
            class: selectedAircraft.cabinConfig[1].class,
            seats: selectedAircraft.cabinConfig[1].seats,
          };
        } else {
          selectedAircraft.cabinTwo = {
            active: false,
            class: "B",
            seats: 100,
          };
        }

        cabin = selectedAircraft.cabinConfig[2];

        if (cabin) {
          selectedAircraft.cabinThree = {
            active: true,
            class: selectedAircraft.cabinConfig[2].class,
            seats: selectedAircraft.cabinConfig[2].seats,
          };
        } else {
          selectedAircraft.cabinThree = {
            active: false,
            class: "C",
            seats: 100,
          };
        }
      }

      if (selectedAircraft.swaCategories) {
        // SWA Categories
        let category;

        category = selectedAircraft.swaCategories[0];

        if (category) {
          selectedAircraft.swaCategoryOne = {
            active: true,
            name: selectedAircraft.swaCategories[0].name,
            value: selectedAircraft.swaCategories[0].value,
          };
        } else {
          selectedAircraft.swaCategoryOne = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[1];

        if (category) {
          selectedAircraft.swaCategoryTwo = {
            active: true,
            name: selectedAircraft.swaCategories[1].name,
            value: selectedAircraft.swaCategories[1].value,
          };
        } else {
          selectedAircraft.swaCategoryTwo = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[2];

        if (category) {
          selectedAircraft.swaCategoryThree = {
            active: true,
            name: selectedAircraft.swaCategories[2].name,
            value: selectedAircraft.swaCategories[2].value,
          };
        } else {
          selectedAircraft.swaCategoryThree = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[3];

        if (category) {
          selectedAircraft.swaCategoryFour = {
            active: true,
            name: selectedAircraft.swaCategories[3].name,
            value: selectedAircraft.swaCategories[3].value,
          };
        } else {
          selectedAircraft.swaCategoryFour = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[4];

        if (category) {
          selectedAircraft.swaCategoryFive = {
            active: true,
            name: selectedAircraft.swaCategories[4].name,
            value: selectedAircraft.swaCategories[4].value,
          };
        } else {
          selectedAircraft.swaCategoryFive = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[5];

        if (category) {
          selectedAircraft.swaCategorySix = {
            active: true,
            name: selectedAircraft.swaCategories[5].name,
            value: selectedAircraft.swaCategories[5].value,
          };
        } else {
          selectedAircraft.swaCategorySix = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[6];

        if (category) {
          selectedAircraft.swaCategorySeven = {
            active: true,
            name: selectedAircraft.swaCategories[6].name,
            value: selectedAircraft.swaCategories[6].value,
          };
        } else {
          selectedAircraft.swaCategorySeven = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[7];

        if (category) {
          selectedAircraft.swaCategoryEight = {
            active: true,
            name: selectedAircraft.swaCategories[7].name,
            value: selectedAircraft.swaCategories[7].value,
          };
        } else {
          selectedAircraft.swaCategoryEight = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[8];

        if (category) {
          selectedAircraft.swaCategoryNine = {
            active: true,
            name: selectedAircraft.swaCategories[8].name,
            value: selectedAircraft.swaCategories[8].value,
          };
        } else {
          selectedAircraft.swaCategoryNine = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[9];

        if (category) {
          selectedAircraft.swaCategoryTen = {
            active: true,
            name: selectedAircraft.swaCategories[9].name,
            value: selectedAircraft.swaCategories[9].value,
          };
        } else {
          selectedAircraft.swaCategoryTen = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[10];

        if (category) {
          selectedAircraft.swaCategoryEleven = {
            active: true,
            name: selectedAircraft.swaCategories[10].name,
            value: selectedAircraft.swaCategories[10].value,
          };
        } else {
          selectedAircraft.swaCategoryEleven = {
            active: false,
            class: "",
            value: 0,
          };
        }

        category = selectedAircraft.swaCategories[11];

        if (category) {
          selectedAircraft.swaCategoryTwelve = {
            active: true,
            name: selectedAircraft.swaCategories[11].name,
            value: selectedAircraft.swaCategories[11].value,
          };
        } else {
          selectedAircraft.swaCategoryTwelve = {
            active: false,
            class: "",
            value: 0,
          };
        }
      }

      this.$store.commit("updateSelectedAircraft", selectedAircraft);
      this.$router.push({ name: "selectedAircraft" });
    },

    goHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style scoped>
.actions-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 3px;
}
</style>
